import {makeAutoObservable} from "mobx";
import {SocketApiAckStatus} from "proto_socket_typescript";
import {InzStore} from "./inz_store";
import {inz_types, inz_zrtve, inz_zrtve_inputs} from "../proto/compiled";
import {proto} from "../proto/messages";
import {toast} from "react-toastify";
import {api} from "../app";
import {debounce} from "lodash";
import {ZrtveEditDialog} from "../pages/zrtve/zrtve_edit_dialog";
import {InzTypes} from "../inz_types";
import {JsonPack} from '@lexriver/json-pack'

export class ZrtveStore {
    isPublic?: boolean;
    private disposeCallbacks: (() => any)[] = [];
    base: InzStore;
    filters: inz_zrtve.ZrtveFilters = inz_zrtve.ZrtveFilters.create();
    zrtve?: inz_zrtve.Zrtve;
    availableFilters: inz_zrtve.IZrtveFilter[] = [];
    loading: number = 0;
    edit: any;
    zrtev?: inz_zrtve_inputs.IZrtevInputs;
    page: number = 0;
    hashState = {
        filters: {},
        zrtevId: undefined as string | undefined,
        filtersExpanded: false,
    };

    constructor(base: InzStore, isPublic?: boolean) {
        this.isPublic = !!isPublic;
        this.base = base;
        makeAutoObservable(this);

        this.onSub(api.getMessageHandler(new proto.RxZrtve()).subscribe((m) => this.onZrtve(m)));
        this.onSub(api.getMessageHandler(new proto.RxAvailableZrtveFilters()).subscribe((m) => this.onZrtveFilters(m)));
        this.onSub(api.getMessageHandler(new proto.RxZrtevInputs()).subscribe((m) => this.onZrtevInputs(m)));
        this.onSub(api.getMessageHandler(new proto.RxExportSlot()).subscribe((m) => this.onExportSlot(m)));

        try {
            this.hashState = JsonPack.unpack('JsonPack:' + decodeURI(window.location.hash.substring(1)));
            if (this.hashState.zrtevId) {
                api.connection.whenConnected.then(() => {
                    this.openEditDialog(this.hashState.zrtevId);
                });
            }
            const state = inz_zrtve.LoadZrtve.fromObject(this.hashState.filters);
            this.page = state.page;
            this.filters = inz_zrtve.ZrtveFilters.create(state.filters!);
            makeAutoObservable(this.filters.filters.map((f) => InzTypes.makeInputObservable(f.input!)));
        } catch (e) {
        }
        makeAutoObservable(this.filters);

        api.connection.whenConnected.then(() => {
            this.load();
            this.base.api.sendMessage(proto.TxLoadAvailableZrtveFilters.create());
        });
    }

    dispose() {
        for (const dc of this.disposeCallbacks) {
            dc();
        }
    }

    quickSearch(value: string) {
        this.filters.quickSearch = value;
        console.log(value)
        this.loadDebounced();
    }

    loadDebounced = debounce(() => this.load(), 500, {leading: true});

    private async load() {
        this.loading++;
        if (this.loading > 1) {
            return;
        }
        const message = proto.TxLoadZrtve.create({
            filters: this.filters,
            page: this.page,
        });
        this.hashState.filters = message.proto.toJSON();
        this.updateHash();

        const response = await this.base.api.sendMessage(message, {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        }
        this.loading--;
        if (this.loading) {
            this.loading = 0;
            this.load();
        }
    }

    updateHash() {
        window.location.hash = JsonPack.pack(this.hashState).substring(9);
    }

    private onZrtve(message: proto.RxZrtve) {
        this.zrtve = message.proto;
    }

    private onSub(subscription: any) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }

    private onZrtveFilters(message: proto.RxAvailableZrtveFilters) {
        this.availableFilters = message.proto.filters;
    }

    addFilter(filter: inz_zrtve.IZrtveFilter) {
        if (this.filters.filters.length && this.filters.filters[this.filters.filters.length - 1].input?.key === filter.input?.key) {
            this.filters.filters[this.filters.filters.length - 1].nextRelation = inz_zrtve.ZrtveFilterRelation.relation_or;
        }

        const newFilter = inz_zrtve.ZrtveFilter.create(filter);
        this.filters.filters.push(newFilter);
        newFilter.input = inz_types.InzInput.create(newFilter.input!);
        newFilter.input!.value = inz_types.InzInputValue.create({
            date: inz_types.Date.create()
        })
        makeAutoObservable(newFilter);
        makeAutoObservable(newFilter.input!.value);
        makeAutoObservable(newFilter.input!.value!.date!);
    }

    removeFilter(index: number) {
        this.filters.filters.splice(index, 1);
        this.loadDebounced();
    }

    async openEditDialog(id?: string) {
        this.hashState.zrtevId = id;
        this.updateHash();
        this.base.dialogs.push(<ZrtveEditDialog key={'zrtve'}/>)
        this.zrtev = undefined;
        const response = await this.base.api.sendMessage(proto.TxLoadZrtevInputs.create({
            id: id
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        }
    }

    private onZrtevInputs(message: proto.RxZrtevInputs) {
        this.zrtev = message.proto;
        makeAutoObservable(this.zrtev);
        this.zrtev.osebniPodatki?.map((o) => InzTypes.makeInputObservable(o));
        this.zrtev.podatkiOSmrti?.map((o) => InzTypes.makeInputObservable(o));
        InzTypes.makeInputObservable(this.zrtev.opombe!);
        InzTypes.makeInputObservable(this.zrtev.viri!);
    }

    setPage(page: number) {
        if (isNaN(page) || page > (this.zrtve?.nPages ?? 0) || page < 0) return false;
        if (this.page !== page) {
            this.page = page;
            this.loadDebounced();
        }
    }

    async save() {
        const response = await this.base.api.sendMessage(proto.TxSaveZrtevInputs.create({
            inputs: this.zrtev
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
            return false;
        }
        return true;
    }

    async export() {
        this.loading++;
        const response = await this.base.api.sendMessage(proto.TxExportZrtveResults.create({
            filters: this.filters,
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        }
        this.loading--;
    }

    private onExportSlot(m: proto.RxExportSlot) {
        const a = document.createElement('a');
        a.href = m.proto.url;
        a.setAttribute("download", m.proto.name!);
        a.click();
    }
}
