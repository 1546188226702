import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";
import {useContext} from "react";
import {LoadingOverlay} from "../components/overlays";
import {Navigate, useSearchParams} from "react-router-dom";
import Logo from "../assets/logo_primary.png";
import {ApiContext, AuthStoreContext} from "../app";
import {InzTextInput} from "../components/text_input";
import {InzInputField} from "../components/input";
import {InzButton} from "../components/buttons";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({theme}: StyledProps) => theme.shade};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 400px;
  padding: 16px;
  background-color: white;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  border-radius: 10px;
`;

export const Login = observer(() => {
    const [params, setParams] = useSearchParams()
    const store = useContext(AuthStoreContext);
    const theme = useTheme() as Theme;

    // disable login for guest site maybe can do guest login or dedicated login button elsewhere
    return store.authenticated || (params.get('from') === '/guest') ? <Navigate to={params.get('from') || '/'}/> : <Container>
        <LoadingOverlay
            overlayStyle={{
                borderRadius: '10px'
            }}
            builder={(action) => <LoginContainer onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (store.password.length === 0 || store.username.length === 0) return;
                action(store.authenticate());
            }}>
                <img src={Logo} style={{height: '60px', margin: '16px 0 16px'}} alt={'logo'}/>
                <div style={{marginTop: 'auto', marginBottom: '24px', width: '100%'}}>
                    <InzInputField label={'UPORABNIŠKO IME'} input={
                        <InzTextInput invalid={!!store.errorText} value={store.username}
                                      onChange={(e) => store.username = e.target.value}/>
                    }/>
                </div>
                <div style={{marginBottom: 'auto', width: '100%'}}>
                    <InzInputField label={'GESLO'} input={
                        <InzTextInput invalid={!!store.errorText} value={store.password}
                                      type={'password'}
                                      onChange={(e) => store.password = e.target.value}/>
                    }/>
                </div>
                <theme.textTheme.error.span>&nbsp;{store.errorText}&nbsp;</theme.textTheme.error.span>
                <InzButton
                    onClick={() => action(store.authenticate())}
                    style={{justifyContent: 'center', marginTop: 'auto'}}
                    disabled={store.password.length === 0 || store.username.length === 0}>
                    <theme.textTheme.headline5.span style={{color: 'white'}}>PRIJAVA</theme.textTheme.headline5.span>
                </InzButton>
                <input type="submit" hidden/>
            </LoginContainer>}/>
    </Container>;
});