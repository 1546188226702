import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {StyledProps} from "../theme";
import Logo from "../assets/logo_white.png";
import {useContext} from "react";
import {ApiContext, AuthStoreContext} from "../app";
import {MdPerson, MdArrowDropDown, MdLogout} from "react-icons/md";
import useWave from "use-wave";
import {useNavigate} from "react-router-dom";
import {InzRoutes} from "../strings";
import {SocketApi} from "proto_socket_typescript";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({theme}: StyledProps) => theme.content};
  ${({theme}: StyledProps) => theme.textTheme.headline5.css};
  color: white;
  height: 56px;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  background-color: ${({theme}: StyledProps) => theme.primary};
  padding: 0 16px 0 16px;
  margin-right: 32px;
  cursor: pointer;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

interface InzAppBarProps {
    onHome?: () => void;
    children: any;
}

export const InzAppBar = observer((props: InzAppBarProps) => {
    const navigate = useNavigate();
    return <Container>
        <LogoContainer onClick={() => props.onHome ? props.onHome() : navigate(InzRoutes.index)}>
            <img src={Logo} style={{height: '36px'}} alt={'logo'}/>
        </LogoContainer>
        {props.children}
        <ProfileIndicator/>
    </Container>
});

const ProfileIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  border-left: solid 1px rgba(255, 255, 255, 0.1);
  margin-left: auto;
  padding: 0 16px 0 16px;
  height: 100%;
  ${({theme}: StyledProps) => theme.textTheme.button.css};
  cursor: pointer;

  :hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ProfileIndicator = observer(() => {
    const store = useContext(AuthStoreContext);
    const api = useContext(ApiContext);
    const wave = useWave();
    return <ProfileIndicatorContainer ref={wave} onClick={() => api.setAuth(null)}>
        <MdPerson style={{marginRight: '8px', fontSize: '23px'}}/>
        {store.profile?.name}
        <MdLogout style={{marginLeft: '8px'}}/>
    </ProfileIndicatorContainer>
});