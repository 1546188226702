import styled, {useTheme} from "styled-components";
import {Theme} from "../theme";
import {CSSProperties} from "react";

interface InzInputProps {
    label?: any,
    input: any,
    error?: any,
    style?: CSSProperties,
    button?: any
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  width: 100%;
`;

export const InzInputField = (props: InzInputProps) => {
    const theme = useTheme() as Theme;
    return <Container style={props.style}>
        {props.label && <theme.textTheme.label.span
            style={{marginBottom: '4px', marginLeft: '4px', display: 'flex', alignItems: 'center'}}>
            {props.label}
        </theme.textTheme.label.span>}
        {props.input}
        <theme.textTheme.error.span style={{
            opacity: !!props.error ? 1 : 0,
            transition: 'opacity 300ms ease',
        }}>{props.error}</theme.textTheme.error.span>
    </Container>
}