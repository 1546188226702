import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ApiContext, InzStoreContext} from "../app";
import {CSSProperties, useContext, useEffect, useState} from "react";
import {OverlayContainer} from "./overlays";
import {DialogContainer} from "./dialogs";
import {Navigate, useLocation} from "react-router-dom";
import {InzRoutes} from "../strings";

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  * {
    flex-shrink: 0;
  }
`;

export const InzScaffold = observer(({
                                         children,
                                         waitConnected,
                                         publicRoute,
                                         style,
                                     }: { children: any, waitConnected?: boolean, publicRoute?: boolean, style?: CSSProperties }) => {
    const api = useContext(ApiContext);
    const [connected, setConnected] = useState(api.connection.connected);
    useEffect(() => {
        if (waitConnected)
            api.connection.whenConnected.then(() => setConnected(true));
    }, [api, waitConnected]);

    const [auth, setAuth] = useState(api.authenticated);

    useEffect(() => {
        const sub = api.authenticatedChanges.subscribe((a) => {
            setAuth(a);
        });
        return () => sub.unsubscribe();
    });

    const location = useLocation();


    if (!publicRoute && !auth) {
        return <Navigate to={InzRoutes.login(location.pathname)}/>;
    }

    const store = useContext(InzStoreContext);

    return <Container style={style}>
        {(!waitConnected || connected) && children}
        <ConnectedOverlay/>
        {store.dialogs.map((e) => e)}
        {/*<ZrtveEditDialog key={'zrtve'}/>*/}
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </Container>
});

export const ConnectedOverlay = () => {
    const api = useContext(ApiContext);
    const theme = useTheme() as Theme;

    // handle connected overlay
    const [connected, setConnected] = useState(true);
    useEffect(() => {
        const sub = api.connection.connectedChanges.subscribe((connected) => setConnected(connected));
        const timeout = new Promise((res) => setTimeout(() => res(null), 1000));
        Promise.race([api.connection.whenConnected, timeout]).then(
            (_) => setConnected(api.connection.connected)
        );
        return () => sub.unsubscribe();
    }, [api.connection]);

    return <OverlayContainer hidden={connected}>
        <DialogContainer style={{padding: '50px'}}>
            <theme.textTheme.headline3.span>
                Povezava s strežnikom ni uspela!
            </theme.textTheme.headline3.span>
            <theme.textTheme.body.span>
                <ol>
                    <li>Preverite ali imate delujočo internetno povezavo.</li>
                    <li>Poskusite malo kasneje. Morda poteka nadgradnja ali vzdrževanje sistema.</li>
                </ol>
            </theme.textTheme.body.span>
        </DialogContainer>
    </OverlayContainer>;
};

export const InzBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0;
  flex-grow: 1;
  padding: 0 146px 0 146px;
  ${({theme}: StyledProps) => theme.textTheme.body.css};
`;