import {observer} from "mobx-react-lite";
import {InzBody, InzScaffold} from "../components/inz_scaffold";
import styled from "styled-components";
import {InzTopBar} from "./guest/top_bar";
import {ZrtveStore} from "../stores/zrtve_store";
import {useCallback, useContext, useEffect} from "react";
import {InzStoreContext} from "../app";
import {ZrtveStoreContext} from "./zrtve";
import {ZrtveGrid} from "./zrtve/zrtve_grid";
import {GuestFilters} from "./guest/filters";
import {InzIframeOverlay} from "./guest/inz_iframe_overlay";

let store: ZrtveStore | undefined;
export const GuestPage = observer(() => {
    if (!store) {
        store = new ZrtveStore(useContext(InzStoreContext), true);
    }

    const onMessageReceivedFromIframe = useCallback(
        event => {
            try {
                if (event.data.includes('https://www.sistory.si/')) {
                    window.location.href = event.data
                    event.preventDefault()
                }
            } catch (e) {

            }

        },
        []
    );

    useEffect(() => {
        window.addEventListener("message", onMessageReceivedFromIframe);
        return () =>
            window.removeEventListener("message", onMessageReceivedFromIframe);
    }, [onMessageReceivedFromIframe]);

    return <ZrtveStoreContext.Provider value={store}>
        <InzScaffold publicRoute={true} style={{overflow: 'auto'}}>
            <Container>
                <InzIframeOverlay/>
                <ContainerContent>
                    <div id={'my-padding'}>
                        <InzTopBar store={store}/>
                        <div>
                            <hr style={{
                                border: 'solid #DDD',
                                borderWidth: '1px 0 0', clear: 'both',
                                height: 0, margin: '1.25rem 0 1.1875rem'
                            }}/>
                        </div>
                        <GuestFilters/>
                        <InzMainResults>
                            <ZrtveGrid/>
                        </InzMainResults>
                    </div>
                </ContainerContent>
            </Container>
        </InzScaffold>
    </ZrtveStoreContext.Provider>
});

const InzMainResults = styled.main`
  margin: 0 auto;
  height: 100%;
  overflow: hidden
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 0 380px 0;

  #my-padding {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
`;

const ContainerContent = styled.div`
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  float: left;
  position: relative;
`;


const LangSelector = styled.div`
  text-align: right;
  padding: 5px 5px 0 0;
  font-size: 12px;
  color: #222;

  a {
    margin: 5px;
    color: #8e130b;
    text-decoration: none;
  }

  a:hover {
    border-bottom: 1px dashed #666;
  }
`