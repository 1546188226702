import React, {FunctionComponentElement} from 'react';

interface MultiProviderProps {
    children: any;
    providers: FunctionComponentElement<any>[];
}

const MultiProvider = (props: MultiProviderProps) => {
    let content = props.children;

    // Turn object into an array
    // const numberOfProviders = props.providers.size;
    const numberOfProviders = props.providers.length;

    if (!numberOfProviders) {
        // Providers prop is empty, r
        return content;
    }

    props.providers.forEach((provider) => {
        content = React.cloneElement(provider, provider.props, content);
    });

    return content;
}

export default MultiProvider;


export function zfill(value: number, nDigits: number) {
    return (1e15 + value + '').slice(-nDigits);
}

export function getAge(birthday: Date) {
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export abstract class DateUtils {
    static setDate(date: Date, newDate: Date): Date {
        date.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
        return date;
    }

    static setTime(date: Date, time: string): Date {
        const [hS, mS] = time.split(':');
        date.setHours(parseInt(hS), parseInt(mS));
        return date;
    }

    static dayIsBefore(dateA: Date, dateB: Date): boolean {
        if (dateA.getFullYear() < dateB.getFullYear()) {
            return true;
        }
        if (dateA.getMonth() < dateB.getMonth()) {
            return true;
        }

        return dateA.getDate() < dateB.getDate();

    }
}

export abstract class StringFormatters {
    static numeric(num: string): string {
        return num.match(/[0-9]*/g)?.join('') ?? '';
    }

    static phone(num: string): string {
        num = num.replaceAll(/[()\-\s]/g, '');
        if (num.startsWith('+') && num.length === 12) {
            num = '0' + num.substring(4);
        }
        num = num.match(/[0-9]*/g)?.join('') ?? '';
        return num;
    }
}

export abstract class DateFormatters {
    static weekdays = ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota'];
    static months = ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'Novermber', 'December'];

    static verbose(date: Date): string {
        let result = DateFormatters.weekdays[date.getDay()] + ', ';
        result += zfill(date.getDate(), 2) + '. ';
        result += zfill(date.getMonth() + 1, 2) + '. ob ';
        result += zfill(date.getHours(), 2) + '.' + zfill(date.getMinutes(), 2);
        return result;
    }

    static dateTime(date: Date) {
        let result = zfill(date.getDate(), 2) + '. ';
        result += zfill(date.getMonth() + 1, 2) + '. ';
        result += DateFormatters.time(date);
        return result;
    }

    static time(date: Date) {
        return zfill(date.getHours(), 2) + ':' + zfill(date.getMinutes(), 2);
    }

    static date(date: Date) {
        let result = zfill(date.getDate(), 2) + '. ';
        result += zfill(date.getMonth() + 1, 2) + '. ';
        result += date.getFullYear();
        return result;
    }

    static isoDate(date: Date) {
        return date.toISOString().split('T')[0];
    }

    static isoTime(date: Date) {
        return zfill(date.getHours(), 2) + ':' + zfill(date.getMinutes(), 2);
    }
}

export abstract class NumberFormatters {
    static eur(value: number): string {
        let result = value.toFixed(2).replace('.', ',');
        return result + ' €';
    }
}

export const isMobile = () => {
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4));
};

export const isIOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
};

function b64EncodeUnicode(str: string) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode(p1);
        }));
}

function b64DecodeUnicode(str: string) {
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}