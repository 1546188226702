import React, {createContext} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {InzScaffold} from "./components/inz_scaffold";
import MultiProvider from "./utils";
import {ThemeProvider} from "styled-components";
import {InzPublicTheme, InzTheme} from "./theme";
import {SocketApi} from "proto_socket_typescript";
import {InzStore} from "./stores/inz_store";
import {observer} from "mobx-react-lite";
import {InzRoutes} from "./strings";
import {AuthStore} from "./stores/auth_store";
import {Login} from "./pages/login";
import {HomePage} from "./pages/home";
import {ZrtvePage} from './pages/zrtve';
import {GuestPage} from "./pages/guest";

const port = window.location.port === '3000' ? '8000' : window.location.port;
export const api = new SocketApi(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.hostname}:${port}/ws/`);
export const ApiContext = createContext<SocketApi>(api);

const inzStore = new InzStore(api);
export const InzStoreContext = createContext<InzStore>(inzStore);

const authStore = new AuthStore(api);
export const AuthStoreContext = createContext<AuthStore>(authStore);


function App() {
    return (
        <MultiProvider providers={[
            <ThemeProvider theme={InzTheme}/>,
            <ApiContext.Provider value={api}/>,
            <InzStoreContext.Provider value={inzStore}/>,
        ]}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path={'/'}
                        element={<ThemeProvider theme={InzPublicTheme}><GuestPage/></ThemeProvider>}
                    />
                    <Route
                        path={InzRoutes.login()}
                        element={<Login/>}
                    />
                    <Route
                        path={InzRoutes.zrtve.index}
                        element={<ZrtvePage/>}
                    />
                    <Route
                        path={InzRoutes.home}
                        element={<HomePage/>}
                    />

                </Routes>
            </BrowserRouter>
        </MultiProvider>
    );
}

export default observer(App);
