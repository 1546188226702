import {observer} from "mobx-react-lite";
import {InzBody, InzScaffold} from "../components/inz_scaffold";
import {InzAppBar} from "../components/app_bar";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";
import {useNavigate} from "react-router-dom";
import {InzRoutes} from "../strings";
import useWave from "use-wave";

export const HomePage = observer(() => {
    const theme = useTheme() as Theme;
    const navigate = useNavigate();
    const wave = useWave();

    return <InzScaffold>
        <InzAppBar>
            Administracijski vmesnik
        </InzAppBar>
        <InzBody>
            <theme.textTheme.headline3.span style={{marginTop: '60px', marginBottom: '16px'}}>
                Aplikacije
            </theme.textTheme.headline3.span>
            <InzAppContainer ref={wave} onClick={() => navigate(InzRoutes.zrtve.index)}>
                Žrtve 2. svetovne vojne
            </InzAppContainer>
        </InzBody>
    </InzScaffold>
});

const InzAppContainer = styled.div`
  display: flex;
  border-left: solid 8px ${({theme}: StyledProps) => theme.primary};
  padding: 16px 8px 16px 16px;
  border-radius: 5px;
  width: 200px;
  background-color: ${({theme}: StyledProps) => theme.shade};
  ${({theme}: StyledProps) => theme.textTheme.button.css};
  color: ${({theme}: StyledProps) => theme.content};
  cursor: pointer;

  :hover {
    background-color: #d4dade;
  }
`;