import {inz_types} from "./proto/compiled";
import {makeAutoObservable} from "mobx";

export abstract class InzTypes {
    static makeInputObservable(input: inz_types.IInzInput) {
        if (input.repeated) {
            if (!input.values) input.values = [];
            input.values.map((v) => {
                makeAutoObservable(v);
                if (input.type === inz_types.InzInputType.date) {
                    if (!v.date) v.date = inz_types.Date.create();
                    makeAutoObservable(v.date);
                }
                return undefined;
            })
        } else {
            if (!input.value) input.value = inz_types.InzInputValue.create();
            makeAutoObservable(input.value);
            if (input.type === inz_types.InzInputType.date) {
                if (!input.value.date) input.value.date = inz_types.Date.create();
                makeAutoObservable(input.value.date);
            }
        }
        makeAutoObservable(input);
    }
}