import {observer} from "mobx-react-lite";
import {InzBody, InzScaffold} from "../components/inz_scaffold";
import {InzAppBar} from "../components/app_bar";
import {SideBar} from "./zrtve/side_bar";
import {createContext, useContext, useEffect} from "react";
import {ZrtveStore} from "../stores/zrtve_store";
import {InzStoreContext} from "../app";
import {ZrtveGrid} from "./zrtve/zrtve_grid";
import {InzLoadingOverlay} from "../components/overlays";

let store: ZrtveStore | undefined;
export const ZrtveStoreContext = createContext<ZrtveStore | undefined>(undefined);

export const ZrtvePage = observer(() => {
    if (!store) {
        store = new ZrtveStore(useContext(InzStoreContext));
    }
    return <ZrtveStoreContext.Provider value={store}>
        <InzScaffold>
            <InzAppBar>
                Žrtve 2. svetovne vojne
            </InzAppBar>
            <InzBody style={{padding: '0', flexDirection: 'row'}}>
                <SideBar/>
                <div style={{overflow: 'auto', width: '0', flexGrow: '1'}}>
                    <ZrtveGrid/>
                </div>
            </InzBody>
        </InzScaffold>
    </ZrtveStoreContext.Provider>
});