import styled, {useTheme} from "styled-components";
import React, {CSSProperties, useState} from "react";
import {observer} from "mobx-react-lite";
import {StyledProps, Theme} from "../theme";
import {HashLoader} from "react-spinners";
import useWave from "use-wave";
import {MdFirstPage, MdLastPage, MdNavigateNext, MdNavigateBefore} from "react-icons/md"

export const OverlayContainer = styled.div<{ hidden?: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  opacity: ${({hidden}) => hidden ? 0 : 1};
  pointer-events: ${({hidden}) => hidden ? 'none' : 'auto'};
  transition: opacity 200ms ease;
`;

export const InzLoadingOverlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: ${({visible}) => visible ? 'all' : 'none'};
  opacity: ${({visible}) => visible ? '1' : '0'};
  transition: opacity 100ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type LoadingOverlayBuilder<T> = (action: (action: Promise<T>) => Promise<T>) => any;
export const LoadingOverlay = observer(({
                                            builder,
                                            style,
                                            overlayStyle
                                        }: { builder: LoadingOverlayBuilder<any>, overlayStyle?: CSSProperties, style?: CSSProperties }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme() as Theme;
    return <div style={{position: 'relative', ...(style ?? {})}}>
        {builder(async (action) => {
            setLoading(true);
            const result = await action;
            setLoading(false);
            return result;
        })}
        <InzLoadingOverlay visible={loading} style={overlayStyle}>
            <div style={{
                borderRadius: '50%',
                backgroundColor: theme.content,
                maxHeight: '50px',
                maxWidth: '50px',
                margin: '16px',
                padding: '4px',
            }}>
                <HashLoader color={theme.primary}
                            size={'100%'}/>
            </div>

        </InzLoadingOverlay>
    </div>
})

export const DropdownContainer = styled.div`
  position: relative;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  padding: 0 8px 0 8px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: solid 1px ${({theme}: StyledProps) => theme.contentShade};

  :hover {
    background-color: ${({theme}: StyledProps) => theme.shade};
  }
`;


const PaginationButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme}: StyledProps) => theme.primary};
  font-size: 20px;
  height: 30px;
  width: 33px;

  :hover {
    background-color: ${({theme}: StyledProps) => theme.content5};
  }
`;

const PaginationContainer = styled.div`
  background-color: white;
  box-shadow: 0 0 0.3em rgba(0, 45, 98, .3);
  height: 30px;
  border-radius: 15px;
  border: solid 1px ${({theme}: StyledProps) => theme.primary};
  display: flex;
  overflow: hidden;
`;

const PaginationInput = styled.input.attrs({type: 'number'})`
  border: none;
  outline: none;
  text-align: center;
  border-radius: 3px;
  background-color: ${({theme}: StyledProps) => theme.shade};
  width: 30px;
  margin: 4px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

interface PaginationProps {
    onNext: () => any;
    onPrev: () => any;
    onLast: () => any;
    onFirst: () => any;
    onChange: (p: number) => any;
    current: number;
    pages: number;
}

export const Pagination = (props: PaginationProps) => {
    const wave = useWave();

    return <PaginationContainer>
        <PaginationButton
            onClick={(e) => props.onFirst()}
            ref={wave}>
            <MdFirstPage/>
        </PaginationButton>
        <PaginationButton
            onClick={(e) => props.onPrev()}
            ref={wave}>
            <MdNavigateBefore/>
        </PaginationButton>
        <PaginationInput
            min={1}
            max={props.pages + 1}
            value={props.current + 1}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
                const page = e.target.valueAsNumber - 1;
                if (isNaN(page) || page > (props.pages) || page < 0) {
                    e.preventDefault();
                    setTimeout(() => e.target.select(), 0);
                } else {
                    props.onChange(page);
                }
            }}/>
        <span style={{lineHeight: '30px', marginRight: '4px'}}>od {(props.pages) + 1}</span>
        <PaginationButton
            onClick={(e) => props.onNext()}
            ref={wave}>
            <MdNavigateNext/>
        </PaginationButton>
        <PaginationButton
            onClick={(e) => props.onLast()}
            ref={wave}>
            <MdLastPage/>
        </PaginationButton>
    </PaginationContainer>
}