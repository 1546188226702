import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {StyledProps} from "../../theme";
import Logo from "../../assets/logo_colored.png";
import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {InzRoutes} from "../../strings";
import {InzInputField} from "../../components/input";
import {InzTextInput} from "../../components/text_input";
import {ZrtveStore} from "../../stores/zrtve_store";


const HeaderContainer = styled.header`
  display: table;
  margin: 0 auto;
  max-width: 75rem;
  width: 100%;
`;

const LogoContainer = styled.div`
  position: relative;
  display: block;
  padding: 0;
  float: left;
  cursor: pointer;
  width: 100%;

  @media only screen and (min-width: 40.0625em) {
    width: 25%;
  }

  @media (max-width: 40.0625em) {
    img {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }
`;

const ListItemContainer = styled.li`
  float: left;
  list-style: none;
  margin: 0;
  position: relative;
  display: block;
  background-color: ${({theme}: StyledProps) => theme.neutral};
  text-transform: uppercase;
  text-align: left;
  padding: .2rem 1.5rem;
  border: 0 solid #CCC;
  border-radius: 2px 2px 0 0;
  width: auto;

  :hover {
    background-color: #e1e1e1;
  }
`

const MenuContainer = styled.div`
  padding: 0;
  width: 100%;
  position: relative;
  float: left;
  height: 7em;

  @media only screen and (min-width: 720px) {
    width: 75%;
  }
`

const TabsContainer = styled.ul`
  line-height: 1.6;
  list-style-position: outside; 
  margin: 0;
  display: table;
  box-sizing: border-box;
  height: auto;
  padding: 0;
`

interface InzTopBarProps {
    store: ZrtveStore
}

export const InzTopBar = observer(({store}: InzTopBarProps) => {
    const navigate = useNavigate();
    return <>
        <HeaderContainer>
                <LogoContainer onClick={() => window.location.href = 'https://www.sistory.si'}>
                    <img src={Logo} alt={'Zgodovina Slovenije - SIStory'} style={{padding: '17px 10px 0 0', height: 'auto', maxWidth: '100%'}}/>
                </LogoContainer>
                <MenuContainer>
                    <div style={{height:'2.1em'}}></div>
                    <TabsContainer className={'hide-for-small-only'}>
                        <TabContainer href={'https://www.sistory.si'}>Publikacije</TabContainer>
                        <TabContainer href={'https://zv1.sistory.si/?lang=sl'}>Žrtve I.sv</TabContainer>
                        <TabContainer href={'/guest'} style={{border: '1px solid #CCC', backgroundColor:'#efefef'}}>Žrtve II.sv</TabContainer>
                        <TabContainer href={'/popis'}>Popisi</TabContainer>
                        <TabContainer href={'https://zic.sistory.si'}>ZIC</TabContainer>
                    </TabsContainer>
                    <SmallDropdown/>
                    <SearchContainer>
                        <InzInputField input={
                            <InzTextInput placeholder={'Hitro iskanje'} value={store.filters.quickSearch}
                                          onChange={(e) => store.quickSearch(e.target.value)}/>
                        }></InzInputField>
                    </SearchContainer>
                    <div style={{height: '1em'}}></div>
                </MenuContainer>
        </HeaderContainer>
    </>
});

interface TabContainerProps {
    href?: string;
    children: any;
    style?: any;
}
const TabContainer = (props : TabContainerProps) => {
    const ItemLink = styled.a`
      ${({theme}: StyledProps) => theme.textTheme.label.css};
      text-decoration: none;
    `
    return <ListItemContainer style={props.style}>
        <ItemLink href={props.href}>{props.children}</ItemLink>
    </ListItemContainer>
}

const SmallDropdown = () => {
    const [expanded, setExpanded] = useState(false);
    return <div className={'show-for-small-only'}>
        <DropButton onClick={() => setExpanded(prevState => !prevState)}>
            Žrtve II.sv
        </DropButton>
        <br/>
        <MobileTabsWrapper expanded={expanded}>
            <li><a href={'/'}>Publikacije</a></li>
            <li><a href={'http://zv1.sistory.si/?lang=sl'}>Žrtve I.sv</a></li>
            <li><a href={'/'}>Žrtve II.sv</a></li>
            <li><a href={'/popis'}>Popisi</a></li>
            <li><a href={'https://zic.sistory.si'}>ZIC</a></li>
        </MobileTabsWrapper>

    </div>
}

const DropButton = styled.button`
  position: relative;
  border-radius: 3px;
  padding: 0.625rem 2.625rem 0.6875rem 0;
  width: 100%;
  font-size: 0.6875rem;
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333333;
  
  border-style: solid;
  border-width: 0;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 300ms ease-out;
  
  :after{
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    top: 50%;
    width: 0;
    border-width: 0.375rem;
    right: 1.125rem;
    margin-top: -0.125rem;
    border-color: #333333 transparent transparent transparent;
  }

`

const MobileTabsWrapper = styled.ul<{expanded:boolean}>`
  position: absolute;
  width: 95%;
  max-width: 80%;
  top: 66.5938px;
  left: 8px;
  display: ${props => props.expanded ? 'block' : 'none'};
  list-style: none;
  margin-left: 0;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  z-index: 89;
  margin-top: 2px;
  
  :before {
    border: inset 6px;
    content: "";
    display: block;
    height: 0;
    width: 0;
    border-color: transparent transparent #FFFFFF transparent;
    border-bottom-style: solid;
    position: absolute;
    top: -12px;
    left: 0px;
    z-index: 89;
  }
  
  li {
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 0;
    
    a {
      display: block;
      padding: 0.5rem;
      color: #555555;
      text-decoration: none;
      border-bottom: 0px;
    }
  }
  
  > *:first-child {
    margin-top: 0;
  }
`

const SearchContainer = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
`