import {inz_types} from "../proto/compiled";
import styled from "styled-components";
import {CSSProperties} from "react";
import {StyledProps} from "../theme";

interface InzGridProps {
    columns: inz_types.IGridCol[];
    rows: inz_types.IGridRow[];
    style?: CSSProperties;
    onClick: (row: inz_types.IGridRow) => any;
}

const Container = styled.div<{ columns: inz_types.IGridCol[] }>`
  display: grid;
  grid-template-columns: ${({columns}: { columns: inz_types.IGridCol[] }) => columns.map((c) => c.span || 'auto').join(' ')};
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  grid-gap: 1px;
`;

const HeadCell = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  margin: -1px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: inherit;
  border: solid 1px ${({theme}: StyledProps) => theme.contentShade};
  background-color: ${({theme}: StyledProps) => theme.shade};
`;

const ContentCell = styled.div`
  display: flex;
  margin: -1px;
  padding: 4px;
  align-items: center;
  justify-content: start;
  font-size: inherit;
  border: solid 1px ${({theme}: StyledProps) => theme.contentShade};
  border-bottom-color: transparent;
  border-top-color: transparent;
  cursor: pointer;
`

const RowContainer = styled.div<{even: boolean}>`
  display: contents;

  & > * {
    background-color: ${({theme, even}: StyledProps) => even ? theme.content5 : 'white'};
  }
`;

const Terminator = styled.div<{ span: number }>`
  height: 50px;
  grid-column: span ${({span}) => span};
  background-color: ${({theme}: StyledProps) => theme.shade};
  border-top: solid 1px ${({theme}: StyledProps) => theme.contentShade};
`;

export const InzGrid = (props: InzGridProps) => {
    return <Container columns={props.columns} style={props.style}>
        {props.columns.map((c, i: number) => <HeadCell key={i}>{c.label}</HeadCell>)}
        {props.rows.map((r, i) =>
            <RowContainer even={!!(i%2)} key={'row-' + i} onClick={() => props.onClick(r)}>{
                r.values!.map((v: inz_types.IGridValue, j: number) =>
                    <ContentCell key={`${i}-${j}`}>{v.text}</ContentCell>
                )
            }</RowContainer>)}
        <Terminator span={props.columns.length}/>
    </Container>
}