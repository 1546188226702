import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import {configure} from "mobx";
import {SocketApi} from "proto_socket_typescript";

configure({
    enforceActions: "never",
});

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);