import styled from "styled-components";
import {useCallback, useEffect, useState} from "react";

export const InzIframeOverlay = ({parentWindow}:any) => {
    return <IFrame
        src={'/inz_site.html'}
        frameBorder={0}
        allowFullScreen={true}
        allowTransparency={true}
    />;
}

const IFrame = styled.iframe`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
