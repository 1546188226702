import styled, {css} from "styled-components";
import {StyledProps} from "../theme";
import {read} from "fs";

export const InzInputContainerCss = css`
  background: ${({theme, readOnly}: StyledProps) => readOnly ? theme.content5 : 'white'};
  pointer-events: ${({readOnly}: any) => readOnly ? 'none' : 'auto'};
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 2.5rem;
  min-height: 2.5rem;
  padding: 0 8px 0 8px;
  ${({theme}: StyledProps) => theme.textTheme.input.css};
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 0.3em transparent;
  box-sizing: border-box;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  border: solid 1px ${({theme, invalid}: StyledProps) => invalid ? theme.error : theme.contentShade};

  :focus {
    box-shadow: 0 0 0.3em ${({
                               theme,
                               invalid,
                               readOnly
                             }: StyledProps) => readOnly ? 'none' : invalid ? 'rgba(255,0,29,0.3)' : 'rgba(0,45,98,.3)'};
  }

  :focus-within {
    box-shadow: 0 0 0.3em ${({
                               theme,
                               invalid,
                               readOnly
                             }: StyledProps) => readOnly ? 'none' : invalid ? 'rgba(255,0,29,0.3)' : 'rgba(0,45,98,.3)'};
  }
`;

export const InzTextInput = styled.input<{ invalid?: boolean, readOnly?: boolean }>`
  ${InzInputContainerCss}
`;
