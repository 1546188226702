import styled from "styled-components";
import {StyledProps} from "../theme";
import useWave from "use-wave";
import {CSSProperties} from "react";

const Container = styled.div`
  display: flex;
  border-radius: 5px;
  background-color: ${({theme}: StyledProps) => theme.shade};
  height: 32px;
  padding: 4px;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

const ButtonContainer = styled.div<{ active: boolean }>`
  display: flex;
  flex-grow: 1;
  transition: all 300ms;
  background-color: ${({theme, active}: StyledProps) => active ? theme.primary : 'transparent'};
  ${({theme}: StyledProps) => theme.textTheme.button.css};
  color: ${({theme, active}: StyledProps) => active ? 'white' : theme.primary};
  border-radius: 5px;
  width: 250px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface TabBarProps {
    items: any[];
    active: number;
    onClick?: (i: number) => any;
    containerStyle?: CSSProperties;
    buttonStyle?: CSSProperties;
}

export const TabBar = (props: TabBarProps) => {
    const wave = useWave();

    return <Container style={props.containerStyle}>
        {props.items.map((e, i) => <ButtonContainer style={props.buttonStyle} ref={wave} key={i}
                                                    active={i === props.active}
                                                    onClick={() => props.onClick && props.onClick(i)}>
            {e}
        </ButtonContainer>)}
    </Container>
}