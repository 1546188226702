import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {InzFAB} from "../../components/buttons";
import {MdAdd, MdDownload} from "react-icons/md";
import useWave from "use-wave";
import React, {useContext} from "react";
import {ZrtveStoreContext} from "../zrtve";
import {ZrtveStore} from "../../stores/zrtve_store";
import {ZrtveEditDialog} from "./zrtve_edit_dialog";
import {InzGrid} from "../../components/grid";
import {InzLoadingOverlay, Pagination} from "../../components/overlays";
import {BarLoader, HashLoader} from "react-spinners";
import {Theme} from "../../theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 1300px;
`;

export const ZrtveGrid = observer(() => {
    const wave = useWave();
    const store = useContext(ZrtveStoreContext) as ZrtveStore;
    const theme = useTheme() as Theme;

    return <Container>
        <div style={{
            position: 'absolute',
            left: store.isPublic ? '16px' : '366px',
            right: store.isPublic ? '16px' : '0',
            bottom: '0',
            zIndex: 999,
            display: "flex",
        }}>
            {!!store.loading && <BarLoader height={'5px'} width={'100%'} color={theme.primary}/>}
        </div>
        {store.zrtve && <InzGrid style={{
            width: store.isPublic ? '100%' : 'auto',
            fontSize: store.isPublic ? '18px' : '20px',
            maxHeight: store.isPublic ? 'none' : '100%',
            overflowY: store.isPublic ? 'visible' : 'auto',
        }} onClick={(r) => store.openEditDialog(r.id!)} columns={store.zrtve.columns!}
                                 rows={store.zrtve.rows!}/>}
        <div style={{
            position: store.isPublic ? 'fixed' : 'absolute',
            bottom: '16px',
            right: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            {!store.isPublic && <InzFAB style={{width: '40px', height: '40px', marginBottom: '8px'}} ref={wave}
                                        onClick={() => store.export()}><MdDownload/></InzFAB>}
            {!store.isPublic && <InzFAB ref={wave} onClick={() => store.openEditDialog()}><MdAdd/></InzFAB>}
        </div>
        <div style={{
            position: 'absolute',
            bottom: '8px',
            right: '0',
            left: store.isPublic ? '0' : '350px',
            display: 'flex',
            justifyContent: 'center'
        }}>
            {store.zrtve && <Pagination
                onChange={(p) => store.setPage(p)}
                onNext={() => {
                    store.setPage(store.page + 1);
                }}
                onPrev={() => {
                    store.setPage(store.page - 1);
                }}
                onLast={() => {
                    store.setPage(store.zrtve!.nPages);
                }}
                onFirst={() => {
                    store.setPage(0);
                }}
                current={store.page}
                pages={store.zrtve!.nPages}
            />
            }
        </div>
        {!!store.zrtve?.hitCount && <div style={{
            position: 'absolute',
            bottom: '8px',
            padding: '7px 8px 7px 8px',
            border: 'solid 1px ' + theme.primary,
            left: store.isPublic ? '32px' : '382px',
            display: 'flex',
            justifyContent: 'start',
            color: theme.primary,
            backgroundColor: 'white',
            borderRadius: '30px',
            fontSize: '12px',
        }}>
            {store.zrtve?.hitCount}
        </div>}
    </Container>
});