import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {inz_types} from "../proto/compiled";
import {InzInputField} from "./input";
import {MdDelete} from "react-icons/md";
import {InzTextInput} from "./text_input";
import {InzDateInput} from "./date_input";
import {InzSelectInput} from "./select_input";
import {InzBoolInput} from "./bool_input";
import {RepeatedInputContainer} from "./repeated_input";
import {makeAutoObservable} from "mobx";


export interface CommonInputProps {
    label?: any;
    input: inz_types.IInzInput
    onChange?: () => any;
    filter?: boolean;
}

const Container = styled.div`
  display: flex;
  height: 69px;
  align-items: center;
  justify-content: center;
`;

export const CommonInput = observer((props: CommonInputProps) => {
    let input;
    switch (props.input.type) {
        case inz_types.InzInputType.date:
            input = <DateInput {...props}/>
            break;
        case inz_types.InzInputType.text:
            input = <TextInput {...props}/>
            break;
        case inz_types.InzInputType.check:
            input = <BoolInput {...props}/>
            break;
        case inz_types.InzInputType.number:
            input = <NumberInput {...props}/>
            break;
        case inz_types.InzInputType.free_select:
        case inz_types.InzInputType.fixed_select:
            input = <SelectInput {...props}/>
            break;
        default:
            input =
                <div style={{
                    border: 'solid 1px gray',
                    borderRadius: '5px',
                    padding: '9px',
                    marginTop: 'auto',
                }}>{props.input.value?.text} - vnos tipa {props.input.type} še ni podprt</div>
            break;
    }
    if (props.input.repeated) {
        return input;
    }

    return <Container>
        <InzInputField
            label={props.input.type === inz_types.InzInputType.check ? undefined : props.label ?? props.input.label}
            style={{flexGrow: '1'}}
            input={input}/>
    </Container>;
});

const SelectInput = observer((props: CommonInputProps) => {
    return <InzSelectInput {...props}/>;
});

const TextInput = observer((props: CommonInputProps) => {
    if (!props.filter && props.input.repeated) {
        if (!props.input.readonly) {
            if (!props.input.values!.length || props.input.values![props.input.values!.length - 1].text?.length) {
                const newValue = inz_types.InzInputValue.create({text: ''});
                makeAutoObservable(newValue);
                props.input.values!.push(newValue);
            }
        }

        return <RepeatedInputContainer>
            {props.input.values
                ?.filter((v) => !props.input.readonly || v.text?.length)
                ?.map((v, i) => <InzTextInput readOnly={!!props.input.readonly && !props.filter}
                                              style={{marginTop: '8px'}} key={i} value={v.text || ''}
                                              onBlur={() => {
                                                  if (!v.text) {
                                                      v.text = 'asd';
                                                      props.input.values?.splice(i, 1);
                                                  }
                                              }}
                                              onChange={props.input.readonly ? undefined : (e) => {
                                                  v.text = e.target.value;
                                                  props.input.values = [...props.input.values!];
                                                  props.onChange && props.onChange();
                                              }}/>)}
        </RepeatedInputContainer>
    }
    return <InzTextInput value={props.input.value!.text || ''}
                         readOnly={!!props.input.readonly && !props.filter}
                         tabIndex={props.input.readonly ? -1 : undefined}
                         onChange={props.input.readonly ? undefined : (e) => {
                             props.input.value!.text = e.target.value;
                             props.onChange && props.onChange();
                         }}/>;
});

const NumberInput = observer((props: CommonInputProps) => {
    return <InzTextInput
        type={'number'}
        value={props.input.value!.number || ''}
        readOnly={!!props.input.readonly && !props.filter}
        tabIndex={props.input.readonly ? -1 : undefined}
        onChange={props.input.readonly ? undefined : (e) => {
            props.input.value!.number = e.target.valueAsNumber;
            props.onChange && props.onChange();
        }}/>;
});

const BoolInput = observer((props: CommonInputProps) => {
    return <div style={{marginTop: props.filter ? '24px' : undefined}}><InzBoolInput
        readonly={!!props.input.readonly && !props.filter}
        value={!!props.input.value!.yesNo}
        onChange={props.input.readonly ? undefined : (e) => {
            props.input.value!.yesNo = e;
            props.onChange && props.onChange();
        }}
        label={props.label ?? props.input.label ?? ''}
    /></div>;
});

const DateInput = observer((props: CommonInputProps) => {
    return <InzDateInput value={props.input.value!.date!}
                         readonly={!!props.input.readonly && !props.filter}
                         filter={props.filter}
                         onChange={(d) => {
                             props.input.value!.date = d;
                             props.onChange && props.onChange();
                         }}/>;
});

