import styled, {css} from "styled-components";
import {StyledProps} from "../theme";
import {InzInputContainerCss, InzTextInput} from "./text_input";
import {CommonInputProps} from "./common_input";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {debounce} from "lodash";
import {inz_types} from "../proto/compiled";
import {DropdownAnything} from "dropdown_anything";
import {DropdownContainer, DropdownItem} from "./overlays";
import {ApiContext, InzStoreContext} from "../app";
import {proto} from "../proto/messages";
import {observer} from "mobx-react-lite";
import {InzLoader} from "./loader";


export const InzSelectInput = observer((props: CommonInputProps) => {
    const [value, setValue] = useState<string>(props.input.value?.text ?? '');
    const [searchString, setSearchString] = useState(value);
    const store = useContext(InzStoreContext);
    const fixed = props.input.type === inz_types.InzInputType.fixed_select;
    const options: string[] = store.selectOptions.get(props.input.key)?.slice();

    useMemo(() => options?.sort((a: string, b: string) => {
        const sl = searchString.toLowerCase();
        const incA = a.toLowerCase().includes(sl);
        const incB = b.toLowerCase().includes(sl);
        if (incA && !incB) return -1;
        if (!incA && incB) return 1;
        return a.localeCompare(b);
    }), [options, searchString]);

    const search = useCallback(debounce((search: string) => {
        if (store.haveAllOptions.has(props.input.key!)) return;
        store.api.sendMessage(proto.TxLoadSelectOptions.create({
            esName: props.input.key,
            search: search,
        }));
    }, 300, {leading: true}), [store.api]);


    return <DropdownAnything
        triggerBuilder={
            (isOpen: boolean, setIsOpen: any) => <InzTextInput value={value}
                                                               readOnly={!!props.input.readonly}
                                                               onBlur={(e) => {
                                                                   setValue(props.input.value?.text ?? '');
                                                                   setIsOpen(false);
                                                               }}
                                                               onKeyDown={() => setIsOpen(true)}
                                                               onFocus={() => {
                                                                   setIsOpen(true);
                                                                   search(searchString);
                                                               }}
                                                               onChange={(e) => {
                                                                   setValue(e.target.value);
                                                                   setSearchString(e.target.value)
                                                                   search(e.target.value);
                                                                   if (fixed) return;
                                                                   props.input.value!.text! = e.target.value;
                                                                   props.onChange && props.onChange();
                                                               }}/>

        }
        builder={(marginTop, close) => <DropdownContainer style={{
            height: '300px',
            marginTop: marginTop + 'px',
        }}>
            {!options && <InzLoader style={{margin: 'auto 0 auto 0'}}/>}
            {options && options.map((o: string) =>
                <DropdownItem
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={() => {
                        setValue(o);
                        setSearchString(o);
                        props.input.value!.text! = o;
                        props.onChange && props.onChange();
                        close();
                    }} key={o}>{o}</DropdownItem>)}
        </DropdownContainer>}
        compute={(rect: DOMRect) => window.innerHeight - rect.bottom < 300 ? -300 - rect.height : 0}
    />
})