import styled from "styled-components";
import {StyledProps} from "../theme";
import {InzInputContainerCss} from "./text_input";
import {inz_types} from "../proto/compiled";
import useWave from "use-wave";
import {useMemo, useRef} from "react";

const InputButton = styled.div`
  font-size: 20px;
  z-index: 990;
  font-weight: bold;
  padding-bottom: 2px;
  width: 20px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}: StyledProps) => theme.primary};
  cursor: pointer;
  border: solid 1px ${({theme}: StyledProps) => theme.primary};
  border-radius: 5px;

  :hover {
    background-color: ${({theme}: StyledProps) => theme.content5};
  }
`;

const InputField = styled.input.attrs({type: 'number'})<{ leftButtons?: boolean }>`
  position: relative;
  border: none;
  background-color: transparent;
  border-bottom: solid 1px ${({theme}: StyledProps) => theme.contentShade};
  margin: 0 8px 0 8px;
  ${({theme}: StyledProps) => theme.textTheme.input.css};
  line-height: 25px;
  padding-right: 4px;
  padding-left: ${({leftButtons}) => leftButtons ? '26px' : '0'};
  margin-left: ${({leftButtons}) => leftButtons ? '-22px' : '0'};
  outline: none;
  flex: 1;
  width: 0;
  transition: all 300ms;
  text-align: center;
  
  :focus {
    border-bottom-color: ${({theme}: StyledProps) => theme.content50};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

const Container = styled.div<{readOnly?: boolean}>`
  ${InzInputContainerCss};
  display: flex;
  align-items: center;
`;


interface InzDateInputProps {
    value: inz_types.IDate;
    readonly?: boolean;
    onChange: (d: inz_types.IDate) => any;
    filter?: boolean;
}

export const InzDateInput = (props: InzDateInputProps) => {
    const value = useMemo(() => inz_types.Date.create(props.value), [props.value]);
    const wave = useWave();
    const refDay = useRef<any>();
    const refMonth = useRef<any>();
    const refYear = useRef<any>();
    const icons = [
        '=',
        '>',
        '<',
    ];
    return <Container readOnly={props.readonly}>
        {props.filter && <InputButton onClick={() => {
            value.relation += 1;
            value.relation %= 3;
            props.onChange(value);
        }} ref={wave}>{icons[props.value?.relation ?? 0]}</InputButton>}
        <InputField
            ref={refDay}
            placeholder={'DD'}
            disabled={props.readonly}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.day ? value.day : ''}
            onChange={(e) => {
                let num = e.target.valueAsNumber;
                if (num > 31) {
                    num = 31;
                }
                if (num > 3) {
                    refMonth.current.focus();
                }
                value.day = num;
                props.onChange(value);
            }} leftButtons={props.filter}/>
        <InputField
            ref={refMonth}
            placeholder={'MM'}
            disabled={props.readonly}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.month ? value.month : ''}
            onChange={(e) => {
                let num = e.target.valueAsNumber;
                if (num > 1) {
                    refYear.current.focus();
                }
                value.month = num;
                props.onChange(value);
            }} leftButtons={props.filter}/>
        <InputField
            ref={refYear}
            placeholder={'YYYY'}
            disabled={props.readonly}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
            value={value.year ? value.year : ''}
            onChange={(e) => {
                let num = e.target.valueAsNumber;
                value.year = num;
                props.onChange(value);
            }} leftButtons={props.filter}/>
    </Container>
};
