import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../../theme";
import {ZrtveFilters} from "./filters";
import {InzTextInput} from "../../components/text_input";
import {InzInputField} from "../../components/input";
import {useContext, useState} from "react";
import {ZrtveStoreContext} from "../zrtve";
import {ZrtveStore} from "../../stores/zrtve_store";

const SideBarContainer = styled.div`
  background-color: ${({theme}: StyledProps) => theme.shade};;
  width: 350px;
  height: 100%;
  padding: 8px 8px 0 8px;
  overflow: auto;
  border-right: solid 1px ${({theme}: StyledProps) => theme.contentShade};
`;

const Divider = styled.div`
  width: calc(100% + 16px);
  height: 1px;
  margin: 12px -8px 12px -8px;
`;

export const SideBar = observer(() => {
    const store = useContext(ZrtveStoreContext) as ZrtveStore;
    const theme = useTheme() as Theme;

    return <SideBarContainer>
        <InzInputField input={
            <InzTextInput placeholder={'Hitro iskanje'} value={store.filters.quickSearch}
                          onChange={(e) => store.quickSearch(e.target.value)}/>
        }/>
        <Divider style={{backgroundColor: theme.contentShade}}/>
        <ZrtveFilters/>
    </SideBarContainer>;
});