import {makeAutoObservable, ObservableMap, ObservableSet} from "mobx";
import {SocketApi} from "proto_socket_typescript";
import {proto} from "../proto/messages";

export class InzStore {
    api: SocketApi;
    dialogs: any[] = [];
    selectOptions: ObservableMap = new ObservableMap<string, string[]>();
    haveAllOptions = new Set<string>();

    constructor(api: SocketApi) {
        this.api = api;
        makeAutoObservable(this);

        api.getMessageHandler(new proto.RxSelectOptions()).subscribe((m) => {
            if (!this.selectOptions.has(m.proto.esName)) {
                this.selectOptions.set(m.proto.esName, []);
            }
            const existing = this.selectOptions.get(m.proto.esName);
            this.selectOptions.set(m.proto.esName, Array.from(new Set([...existing, ...m.proto.options])))
            if (m.proto.isAll) {
                this.haveAllOptions.add(m.proto.esName);
            }
        });
    }

    removeDialog(dialogI: number) {
        this.dialogs.splice(dialogI, 1);
    }
}
