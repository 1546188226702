import {observer} from "mobx-react-lite";
import {InzButton} from "../../components/buttons";
import {useContext, useEffect, useMemo, useState} from "react";
import {ZrtveStoreContext} from "../zrtve";
import {ZrtveStore} from "../../stores/zrtve_store";
import {DialogContainer, DialogFooter, YesNoDialog} from "../../components/dialogs";
import {LoadingOverlay, OverlayContainer} from "../../components/overlays";
import {MdDelete, MdPictureAsPdf} from "react-icons/md";
import styled from "styled-components";
import {TabBar} from "../../components/tab_bar";
import {ZrtveEditDialogPodatki} from "./zrtve_edit_dialog_podatki";
import {InzLoader} from "../../components/loader";
import {CommonInput} from "../../components/common_input";
import {api} from "../../app";
import {isMobile} from "../../utils";
import {proto} from "../../proto/messages";
import {SocketApiAckStatus, SocketApiTxStatus} from "proto_socket_typescript/lib/socket_api";
import {toast} from "react-toastify";

const DialogContent = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  justify-content: space-between;
  padding: 32px;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: auto;

  & > *:not(:last-child) {
    margin-right: 32px;
  }
`;

const PodatkiContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  align-items: center;
`;

export const ZrtveEditDialog = observer(() => {
    const store = useContext(ZrtveStoreContext) as ZrtveStore;
    const [first, setFirst] = useState(true);
    useEffect(() => setFirst(false), []);
    const dialogI = useMemo(() => store.base.dialogs.length - 1, []);

    const close = () => {
        store.hashState.zrtevId = undefined;
        store.updateHash();
        setFirst(true);
        setTimeout(() => store.base.removeDialog(dialogI), 100);
    };

    const [activeTab, setActiveTab] = useState(0);

    return <OverlayContainer hidden={first} style={{
        justifyContent: 'stretch',
        alignItems: 'stretch',
        padding: isMobile() ? '0' : '60px'
    }}>
        <LoadingOverlay
            style={{width: '100%'}}
            builder={(action) => <DialogContainer
                style={{padding: '0', height: '100%'}}>
                {!store.zrtev && <InzLoader/>}
                {store.zrtev && <DialogContent>
                    <PodatkiContainer>
                        <TabBar onClick={(i) => setActiveTab(i)}
                                active={activeTab}
                                items={['OSEBNI PODATKI', 'PODATKI O SMRTI']}/>
                        {activeTab === 0 && <ZrtveEditDialogPodatki podatki={store.zrtev.osebniPodatki!}/>}
                        {activeTab === 1 && <ZrtveEditDialogPodatki podatki={store.zrtev.podatkiOSmrti!}/>}
                    </PodatkiContainer>
                    {api.authenticated && <ViriOpombe/>}
                </DialogContent>}
                <DialogFooter style={{height: '50px', marginTop: 'auto'}}>
                    {api.authenticated && <InzButton
                        onClick={() => action(store.save()).then((success) => {
                            store.loadDebounced();
                            if (success) {
                                close();
                            }
                        })}
                        height={'30px'}
                        width={'300px'}
                        style={{padding: 0, justifyContent: 'center'}}
                    >SHRANI</InzButton>}
                    <InzButton
                        onClick={() => {
                            close();
                        }}
                        height={'30px'}
                        outline={true}
                        width={'300px'}
                        style={{padding: 0, justifyContent: 'center', marginLeft: '16px'}}
                    >{api.authenticated ? 'PREKLIČI' : 'ZAPRI'}</InzButton>
                    <InzButton
                        onClick={async () => {
                            const response = await action(store.base.api.sendMessage(proto.TxExportZrtev.create({id: store.zrtev?.id}), {ack: true})) as SocketApiTxStatus;
                            if (response.status !== SocketApiAckStatus.success) {
                                toast.error(response.errorMessage ?? 'Neznana napaka');
                            }
                        }}
                        height={'30px'}
                        width={'30px'}
                        outline={true}
                        style={{padding: 0, justifyContent: 'center', marginLeft: '16px'}}
                    ><MdPictureAsPdf/></InzButton>
                    {api.authenticated && <InzButton
                        onClick={() => {
                            store.base.dialogs.push(<YesNoDialog result={(result) => {
                                store.base.dialogs.pop();
                                if (result) {
                                    close();
                                }
                            }}>Ste prepričani, da želite izbrisati vnos?</YesNoDialog>);
                        }}
                        height={'30px'}
                        width={'30px'}
                        outline={true}
                        style={{padding: 0, justifyContent: 'center', marginLeft: '16px'}}
                    ><MdDelete/></InzButton>}
                </DialogFooter>
            </DialogContainer>}
        />
    </OverlayContainer>;


});


const ViriOpombeContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0.8em rgba(0, 0, 0, .2);
  border-radius: 5px;
  flex-grow: 2;
  margin-left: 16px;
  min-width: 500px;
`;

const ViriOpombe = observer(() => {
    const store = useContext(ZrtveStoreContext) as ZrtveStore;
    const [activeTab, setActiveTab] = useState(0);

    return <ViriOpombeContainer>
        <TabBar
            containerStyle={{padding: '0', borderBottomLeftRadius: '0', borderBottomRightRadius: '0', height: '35px'}}
            buttonStyle={{borderBottomLeftRadius: '0', borderBottomRightRadius: '0'}}
            onClick={(i) => setActiveTab(i)}
            active={activeTab}
            items={['VIRI', 'OPOMBE']}/>
        {store.zrtev?.viri && activeTab === 0 &&
            <div style={{padding: '0 8px 16px 8px', height: '0', flexGrow: '1', overflow: 'auto'}}><CommonInput
                input={store.zrtev.viri}/></div>}
        {store.zrtev?.opombe && activeTab === 1 &&
            <div style={{padding: '0 8px 16px 8px', height: '0', flexGrow: '1', overflow: 'auto'}}><CommonInput
                input={store.zrtev.opombe}/></div>}
    </ViriOpombeContainer>
});