import styled from "styled-components";
import {observer} from "mobx-react-lite";
import {inz_types} from "../../proto/compiled";
import {CommonInput} from "../../components/common_input";


export const ZrtveEditDialogPodatki = observer(({podatki}: { podatki: inz_types.IInzInput[] }) => {
    const half = Math.round(podatki.length / 2);

    return <div style={{
        display: 'flex',
        flexGrow: '1',
        width: '100%',
        marginTop: '16px',
    }}>
        <Podatki podatki={podatki.slice(0, half)}/>
        <Podatki podatki={podatki.slice(half)}/>
    </div>;
});

const PodatkiContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  flex-basis: 0;
  & > * {
    margin-top: 12px;
  }
`;

const Podatki = observer(({podatki}: { podatki: inz_types.IInzInput[] }) => {
    return <PodatkiContainer style={{marginRight: '32px'}}>
        {podatki.map((p, i) => <CommonInput key={i} input={p}/>)}
    </PodatkiContainer>
});