import styled from "styled-components";
import {InzInputContainerCss, InzTextInput} from "./text_input";
import useWave from "use-wave";
import {MdCheckBox, MdCheckBoxOutlineBlank} from "react-icons/md";
import {StyledProps} from "../theme";


const Container = styled.div.attrs({tabIndex: 0})<{ readOnly: boolean }>`
  ${InzInputContainerCss};
  display: flex;
  height: auto;
  justify-content: start;
  align-items: center;
  padding: 4px 16px 4px 8px;
  cursor: pointer;
  color: ${({theme}: StyledProps) => theme.primary};
  font-size: 22px;
  
  :focus {
    box-shadow: 0 0 0.3em rgba(0, 45, 98, .3);
  }
`;

const Label = styled.div`
  position: relative;
  display: flex;
  margin-left: 8px;
  ${({theme}: StyledProps) => theme.textTheme.input.css};
  font-weight: 500;
  width: 0;
  flex-grow: 1;

  svg {
    position: absolute;
    right: 0;
  }
`;

interface InzBoolInputProps {
    value: boolean;
    label: any;
    readonly: boolean;
    onChange?: (v: boolean) => any;
}

export const InzBoolInput = (props: InzBoolInputProps) => {
    const wave = useWave();
    return <Container
        readOnly={props.readonly}
        onKeyPress={(e) => props.onChange && props.onChange(!props.value)}
        ref={wave}
        onClick={(e) => props.onChange && props.onChange(!props.value)}>
        {props.value ? <MdCheckBox/> : <MdCheckBoxOutlineBlank/>}
        <Label>{props.label}</Label>
    </Container>
};
