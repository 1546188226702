import {authentication, inz_zrtve_inputs, sfiles, uploader, inz_zrtve, socket_api} from "./compiled";
import {SocketRxMessage, SocketRxMessageData, SocketTxMessage} from "proto_socket_typescript";

export namespace proto {

export class TxLogin extends SocketTxMessage<authentication.Login> {
    static type: string = 'login';
    proto: authentication.Login;
    protoClass = authentication.Login;
    

    constructor(proto: authentication.Login) {
        super(TxLogin.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.ILogin = {}) {
        return new TxLogin(authentication.Login.create(properties));
    }
}


export class RxLoginError extends SocketRxMessage<authentication.LoginError> {
    static type: string = 'login-error';
    proto = authentication.LoginError.create({});
    protoClass = authentication.LoginError;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginError.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginError(message);
    };
}


export class RxLoginToken extends SocketRxMessage<authentication.LoginToken> {
    static type: string = 'login-token';
    proto = authentication.LoginToken.create({});
    protoClass = authentication.LoginToken;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginToken.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginToken(message);
    };
}


export class RxTokenInvalid extends SocketRxMessage<authentication.TokenInvalid> {
    static type: string = 'token-invalid';
    proto = authentication.TokenInvalid.create({});
    protoClass = authentication.TokenInvalid;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxTokenInvalid.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxTokenInvalid(message);
    };
}


export class TxVerifyToken extends SocketTxMessage<authentication.VerifyToken> {
    static type: string = 'verify-token';
    proto: authentication.VerifyToken;
    protoClass = authentication.VerifyToken;
    

    constructor(proto: authentication.VerifyToken) {
        super(TxVerifyToken.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.IVerifyToken = {}) {
        return new TxVerifyToken(authentication.VerifyToken.create(properties));
    }
}


export class RxAvailableZrtveFilters extends SocketRxMessage<inz_zrtve.AvailableZrtveFilters> {
    static type: string = 'available-zrtve-filters';
    proto = inz_zrtve.AvailableZrtveFilters.create({});
    protoClass = inz_zrtve.AvailableZrtveFilters;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAvailableZrtveFilters.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAvailableZrtveFilters(message);
    };
}


export class RxExportSlot extends SocketRxMessage<inz_zrtve.ExportSlot> {
    static type: string = 'export-slot';
    proto = inz_zrtve.ExportSlot.create({});
    protoClass = inz_zrtve.ExportSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxExportSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxExportSlot(message);
    };
}


export class TxExportZrtev extends SocketTxMessage<inz_zrtve.ExportZrtev> {
    static type: string = 'export-zrtev';
    proto: inz_zrtve.ExportZrtev;
    protoClass = inz_zrtve.ExportZrtev;
    

    constructor(proto: inz_zrtve.ExportZrtev) {
        super(TxExportZrtev.type, true);
        this.proto = proto;
    }

    static create(properties: inz_zrtve.IExportZrtev = {}) {
        return new TxExportZrtev(inz_zrtve.ExportZrtev.create(properties));
    }
}


export class TxExportZrtveResults extends SocketTxMessage<inz_zrtve.ExportZrtveResults> {
    static type: string = 'export-zrtve-results';
    proto: inz_zrtve.ExportZrtveResults;
    protoClass = inz_zrtve.ExportZrtveResults;
    

    constructor(proto: inz_zrtve.ExportZrtveResults) {
        super(TxExportZrtveResults.type, true);
        this.proto = proto;
    }

    static create(properties: inz_zrtve.IExportZrtveResults = {}) {
        return new TxExportZrtveResults(inz_zrtve.ExportZrtveResults.create(properties));
    }
}


export class TxLoadAvailableZrtveFilters extends SocketTxMessage<inz_zrtve.LoadAvailableZrtveFilters> {
    static type: string = 'load-available-zrtve-filters';
    proto: inz_zrtve.LoadAvailableZrtveFilters;
    protoClass = inz_zrtve.LoadAvailableZrtveFilters;
    

    constructor(proto: inz_zrtve.LoadAvailableZrtveFilters) {
        super(TxLoadAvailableZrtveFilters.type, true);
        this.proto = proto;
    }

    static create(properties: inz_zrtve.ILoadAvailableZrtveFilters = {}) {
        return new TxLoadAvailableZrtveFilters(inz_zrtve.LoadAvailableZrtveFilters.create(properties));
    }
}


export class TxLoadZrtve extends SocketTxMessage<inz_zrtve.LoadZrtve> {
    static type: string = 'load-zrtve';
    proto: inz_zrtve.LoadZrtve;
    protoClass = inz_zrtve.LoadZrtve;
    

    constructor(proto: inz_zrtve.LoadZrtve) {
        super(TxLoadZrtve.type, true);
        this.proto = proto;
    }

    static create(properties: inz_zrtve.ILoadZrtve = {}) {
        return new TxLoadZrtve(inz_zrtve.LoadZrtve.create(properties));
    }
}


export class RxProfileData extends SocketRxMessage<inz_zrtve.ProfileData> {
    static type: string = 'profile-data';
    proto = inz_zrtve.ProfileData.create({});
    protoClass = inz_zrtve.ProfileData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxProfileData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxProfileData(message);
    };
}


export class RxZrtve extends SocketRxMessage<inz_zrtve.Zrtve> {
    static type: string = 'zrtve';
    proto = inz_zrtve.Zrtve.create({});
    protoClass = inz_zrtve.Zrtve;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxZrtve.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxZrtve(message);
    };
}


export class TxLoadSelectOptions extends SocketTxMessage<inz_zrtve_inputs.LoadSelectOptions> {
    static type: string = 'load-select-options';
    proto: inz_zrtve_inputs.LoadSelectOptions;
    protoClass = inz_zrtve_inputs.LoadSelectOptions;
    

    constructor(proto: inz_zrtve_inputs.LoadSelectOptions) {
        super(TxLoadSelectOptions.type, true);
        this.proto = proto;
    }

    static create(properties: inz_zrtve_inputs.ILoadSelectOptions = {}) {
        return new TxLoadSelectOptions(inz_zrtve_inputs.LoadSelectOptions.create(properties));
    }
}


export class TxLoadZrtevInputs extends SocketTxMessage<inz_zrtve_inputs.LoadZrtevInputs> {
    static type: string = 'load-zrtev-inputs';
    proto: inz_zrtve_inputs.LoadZrtevInputs;
    protoClass = inz_zrtve_inputs.LoadZrtevInputs;
    

    constructor(proto: inz_zrtve_inputs.LoadZrtevInputs) {
        super(TxLoadZrtevInputs.type, true);
        this.proto = proto;
    }

    static create(properties: inz_zrtve_inputs.ILoadZrtevInputs = {}) {
        return new TxLoadZrtevInputs(inz_zrtve_inputs.LoadZrtevInputs.create(properties));
    }
}


export class TxSaveZrtevInputs extends SocketTxMessage<inz_zrtve_inputs.SaveZrtevInputs> {
    static type: string = 'save-zrtev-inputs';
    proto: inz_zrtve_inputs.SaveZrtevInputs;
    protoClass = inz_zrtve_inputs.SaveZrtevInputs;
    

    constructor(proto: inz_zrtve_inputs.SaveZrtevInputs) {
        super(TxSaveZrtevInputs.type, true);
        this.proto = proto;
    }

    static create(properties: inz_zrtve_inputs.ISaveZrtevInputs = {}) {
        return new TxSaveZrtevInputs(inz_zrtve_inputs.SaveZrtevInputs.create(properties));
    }
}


export class RxSelectOptions extends SocketRxMessage<inz_zrtve_inputs.SelectOptions> {
    static type: string = 'select-options';
    proto = inz_zrtve_inputs.SelectOptions.create({});
    protoClass = inz_zrtve_inputs.SelectOptions;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxSelectOptions.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxSelectOptions(message);
    };
}


export class RxZrtevInputs extends SocketRxMessage<inz_zrtve_inputs.ZrtevInputs> {
    static type: string = 'zrtev-inputs';
    proto = inz_zrtve_inputs.ZrtevInputs.create({});
    protoClass = inz_zrtve_inputs.ZrtevInputs;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxZrtevInputs.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxZrtevInputs(message);
    };
}


export class TxDeleteFile extends SocketTxMessage<sfiles.DeleteFile> {
    static type: string = 'delete-file';
    proto: sfiles.DeleteFile;
    protoClass = sfiles.DeleteFile;
    

    constructor(proto: sfiles.DeleteFile) {
        super(TxDeleteFile.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IDeleteFile = {}) {
        return new TxDeleteFile(sfiles.DeleteFile.create(properties));
    }
}


export class RxUploadDone extends SocketRxMessage<sfiles.UploadDone> {
    static type: string = 'upload-done';
    proto = sfiles.UploadDone.create({});
    protoClass = sfiles.UploadDone;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadDone.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadDone(message);
    };
}


export class TxUploadEnd extends SocketTxMessage<sfiles.UploadEnd> {
    static type: string = 'upload-end';
    proto: sfiles.UploadEnd;
    protoClass = sfiles.UploadEnd;
    

    constructor(proto: sfiles.UploadEnd) {
        super(TxUploadEnd.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadEnd = {}) {
        return new TxUploadEnd(sfiles.UploadEnd.create(properties));
    }
}


export class RxUploadProgress extends SocketRxMessage<sfiles.UploadProgress> {
    static type: string = 'upload-progress';
    proto = sfiles.UploadProgress.create({});
    protoClass = sfiles.UploadProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadProgress(message);
    };
}


export class TxUploadStart extends SocketTxMessage<sfiles.UploadStart> {
    static type: string = 'upload-start';
    proto: sfiles.UploadStart;
    protoClass = sfiles.UploadStart;
    

    constructor(proto: sfiles.UploadStart) {
        super(TxUploadStart.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadStart = {}) {
        return new TxUploadStart(sfiles.UploadStart.create(properties));
    }
}


export class RxUploadStartSlot extends SocketRxMessage<sfiles.UploadStartSlot> {
    static type: string = 'upload-start';
    proto = sfiles.UploadStartSlot.create({});
    protoClass = sfiles.UploadStartSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadStartSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadStartSlot(message);
    };
}


export class RxAck extends SocketRxMessage<socket_api.Ack> {
    static type: string = 'ack';
    proto = socket_api.Ack.create({});
    protoClass = socket_api.Ack;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAck.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAck(message);
    };
}


export class RxAsyncProgress extends SocketRxMessage<socket_api.AsyncProgress> {
    static type: string = 'async-progress';
    proto = socket_api.AsyncProgress.create({});
    protoClass = socket_api.AsyncProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsyncProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsyncProgress(message);
    };
}


export class RxUpgradeApiVersion extends SocketRxMessage<socket_api.UpgradeApiVersion> {
    static type: string = 'upgrade-api-version';
    proto = socket_api.UpgradeApiVersion.create({});
    protoClass = socket_api.UpgradeApiVersion;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUpgradeApiVersion.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUpgradeApiVersion(message);
    };
}


export class TxDeleteUFile extends SocketTxMessage<uploader.DeleteUFile> {
    static type: string = 'delete-ufile';
    proto: uploader.DeleteUFile;
    protoClass = uploader.DeleteUFile;
    

    constructor(proto: uploader.DeleteUFile) {
        super(TxDeleteUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IDeleteUFile = {}) {
        return new TxDeleteUFile(uploader.DeleteUFile.create(properties));
    }
}


export class RxUploadSlot extends SocketRxMessage<uploader.UploadSlot> {
    static type: string = 'upload-slot';
    proto = uploader.UploadSlot.create({});
    protoClass = uploader.UploadSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadSlot(message);
    };
}


export class RxUploadTask extends SocketRxMessage<uploader.UploadTask> {
    static type: string = 'upload-task';
    proto = uploader.UploadTask.create({});
    protoClass = uploader.UploadTask;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadTask.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadTask(message);
    };
}


export class TxUploadUFile extends SocketTxMessage<uploader.UploadUFile> {
    static type: string = 'upload-ufile';
    proto: uploader.UploadUFile;
    protoClass = uploader.UploadUFile;
    

    constructor(proto: uploader.UploadUFile) {
        super(TxUploadUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IUploadUFile = {}) {
        return new TxUploadUFile(uploader.UploadUFile.create(properties));
    }
}


        export const rxMessages: SocketRxMessage<any>[] = [
            new RxLoginError(),
    new RxLoginToken(),
    new RxTokenInvalid(),
    new RxAvailableZrtveFilters(),
    new RxExportSlot(),
    new RxProfileData(),
    new RxZrtve(),
    new RxSelectOptions(),
    new RxZrtevInputs(),
    new RxUploadDone(),
    new RxUploadProgress(),
    new RxUploadStartSlot(),
    new RxAck(),
    new RxAsyncProgress(),
    new RxUpgradeApiVersion(),
    new RxUploadSlot(),
    new RxUploadTask()
        ];
}