/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const sfiles = $root.sfiles = (() => {

    /**
     * Namespace sfiles.
     * @exports sfiles
     * @namespace
     */
    const sfiles = {};

    sfiles.UploadStartSlot = (function() {

        /**
         * Properties of an UploadStartSlot.
         * @memberof sfiles
         * @interface IUploadStartSlot
         * @property {string|null} [key] UploadStartSlot key
         * @property {string|null} [localKey] UploadStartSlot localKey
         */

        /**
         * Constructs a new UploadStartSlot.
         * @memberof sfiles
         * @classdesc Represents an UploadStartSlot.
         * @implements IUploadStartSlot
         * @constructor
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         */
        function UploadStartSlot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStartSlot key.
         * @member {string} key
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.key = "";

        /**
         * UploadStartSlot localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.localKey = "";

        /**
         * Creates a new UploadStartSlot instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         * @returns {sfiles.UploadStartSlot} UploadStartSlot instance
         */
        UploadStartSlot.create = function create(properties) {
            return new UploadStartSlot(properties);
        };

        /**
         * Encodes the specified UploadStartSlot message. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadStartSlot message, length delimited. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStartSlot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.localKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStartSlot message.
         * @function verify
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStartSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadStartSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         */
        UploadStartSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStartSlot)
                return object;
            let message = new $root.sfiles.UploadStartSlot();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadStartSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.UploadStartSlot} message UploadStartSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStartSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.localKey = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadStartSlot to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStartSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStartSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadStartSlot;
    })();

    sfiles.UploadProgress = (function() {

        /**
         * Properties of an UploadProgress.
         * @memberof sfiles
         * @interface IUploadProgress
         * @property {number|Long|null} [nBytes] UploadProgress nBytes
         * @property {string|null} [key] UploadProgress key
         * @property {string|null} [localKey] UploadProgress localKey
         */

        /**
         * Constructs a new UploadProgress.
         * @memberof sfiles
         * @classdesc Represents an UploadProgress.
         * @implements IUploadProgress
         * @constructor
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         */
        function UploadProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadProgress nBytes.
         * @member {number|Long} nBytes
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.nBytes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadProgress key.
         * @member {string} key
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.key = "";

        /**
         * UploadProgress localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.localKey = "";

        /**
         * Creates a new UploadProgress instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         * @returns {sfiles.UploadProgress} UploadProgress instance
         */
        UploadProgress.create = function create(properties) {
            return new UploadProgress(properties);
        };

        /**
         * Encodes the specified UploadProgress message. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nBytes != null && Object.hasOwnProperty.call(message, "nBytes"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.nBytes);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadProgress message, length delimited. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.nBytes = reader.int64();
                    break;
                case 2:
                    message.key = reader.string();
                    break;
                case 3:
                    message.localKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadProgress message.
         * @function verify
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (!$util.isInteger(message.nBytes) && !(message.nBytes && $util.isInteger(message.nBytes.low) && $util.isInteger(message.nBytes.high)))
                    return "nBytes: integer|Long expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadProgress} UploadProgress
         */
        UploadProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadProgress)
                return object;
            let message = new $root.sfiles.UploadProgress();
            if (object.nBytes != null)
                if ($util.Long)
                    (message.nBytes = $util.Long.fromValue(object.nBytes)).unsigned = false;
                else if (typeof object.nBytes === "string")
                    message.nBytes = parseInt(object.nBytes, 10);
                else if (typeof object.nBytes === "number")
                    message.nBytes = object.nBytes;
                else if (typeof object.nBytes === "object")
                    message.nBytes = new $util.LongBits(object.nBytes.low >>> 0, object.nBytes.high >>> 0).toNumber();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.UploadProgress} message UploadProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.nBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nBytes = options.longs === String ? "0" : 0;
                object.key = "";
                object.localKey = "";
            }
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (typeof message.nBytes === "number")
                    object.nBytes = options.longs === String ? String(message.nBytes) : message.nBytes;
                else
                    object.nBytes = options.longs === String ? $util.Long.prototype.toString.call(message.nBytes) : options.longs === Number ? new $util.LongBits(message.nBytes.low >>> 0, message.nBytes.high >>> 0).toNumber() : message.nBytes;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadProgress to JSON.
         * @function toJSON
         * @memberof sfiles.UploadProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadProgress;
    })();

    sfiles.UploadDone = (function() {

        /**
         * Properties of an UploadDone.
         * @memberof sfiles
         * @interface IUploadDone
         * @property {string|null} [key] UploadDone key
         * @property {sfiles.IUploadedFile|null} [file] UploadDone file
         */

        /**
         * Constructs a new UploadDone.
         * @memberof sfiles
         * @classdesc Represents an UploadDone.
         * @implements IUploadDone
         * @constructor
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         */
        function UploadDone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadDone key.
         * @member {string} key
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.key = "";

        /**
         * UploadDone file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.file = null;

        /**
         * Creates a new UploadDone instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         * @returns {sfiles.UploadDone} UploadDone instance
         */
        UploadDone.create = function create(properties) {
            return new UploadDone(properties);
        };

        /**
         * Encodes the specified UploadDone message. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadDone message, length delimited. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadDone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadDone message.
         * @function verify
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadDone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates an UploadDone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadDone} UploadDone
         */
        UploadDone.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadDone)
                return object;
            let message = new $root.sfiles.UploadDone();
            if (object.key != null)
                message.key = String(object.key);
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.UploadDone.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadDone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.UploadDone} message UploadDone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadDone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.file = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this UploadDone to JSON.
         * @function toJSON
         * @memberof sfiles.UploadDone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadDone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadDone;
    })();

    sfiles.UploadedFile = (function() {

        /**
         * Properties of an UploadedFile.
         * @memberof sfiles
         * @interface IUploadedFile
         * @property {string|null} [localKey] UploadedFile localKey
         * @property {string|null} [url] UploadedFile url
         * @property {string|null} [id] UploadedFile id
         * @property {string|null} [mime] UploadedFile mime
         * @property {string|null} [thumbUrl] UploadedFile thumbUrl
         * @property {string|null} [name] UploadedFile name
         */

        /**
         * Constructs a new UploadedFile.
         * @memberof sfiles
         * @classdesc Represents an UploadedFile.
         * @implements IUploadedFile
         * @constructor
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         */
        function UploadedFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadedFile localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.localKey = "";

        /**
         * UploadedFile url.
         * @member {string} url
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.url = "";

        /**
         * UploadedFile id.
         * @member {string} id
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.id = "";

        /**
         * UploadedFile mime.
         * @member {string} mime
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.mime = "";

        /**
         * UploadedFile thumbUrl.
         * @member {string} thumbUrl
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.thumbUrl = "";

        /**
         * UploadedFile name.
         * @member {string} name
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.name = "";

        /**
         * Creates a new UploadedFile instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         * @returns {sfiles.UploadedFile} UploadedFile instance
         */
        UploadedFile.create = function create(properties) {
            return new UploadedFile(properties);
        };

        /**
         * Encodes the specified UploadedFile message. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.thumbUrl != null && Object.hasOwnProperty.call(message, "thumbUrl"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.thumbUrl);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified UploadedFile message, length delimited. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadedFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.localKey = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    message.id = reader.string();
                    break;
                case 4:
                    message.mime = reader.string();
                    break;
                case 5:
                    message.thumbUrl = reader.string();
                    break;
                case 6:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadedFile message.
         * @function verify
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadedFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                if (!$util.isString(message.thumbUrl))
                    return "thumbUrl: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates an UploadedFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadedFile} UploadedFile
         */
        UploadedFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadedFile)
                return object;
            let message = new $root.sfiles.UploadedFile();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.url != null)
                message.url = String(object.url);
            if (object.id != null)
                message.id = String(object.id);
            if (object.mime != null)
                message.mime = String(object.mime);
            if (object.thumbUrl != null)
                message.thumbUrl = String(object.thumbUrl);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an UploadedFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.UploadedFile} message UploadedFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadedFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.url = "";
                object.id = "";
                object.mime = "";
                object.thumbUrl = "";
                object.name = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                object.thumbUrl = message.thumbUrl;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this UploadedFile to JSON.
         * @function toJSON
         * @memberof sfiles.UploadedFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadedFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadedFile;
    })();

    sfiles.UploadStart = (function() {

        /**
         * Properties of an UploadStart.
         * @memberof sfiles
         * @interface IUploadStart
         * @property {string|null} [localKey] UploadStart localKey
         * @property {string|null} [extension] UploadStart extension
         * @property {string|null} [name] UploadStart name
         * @property {string|null} [mime] UploadStart mime
         */

        /**
         * Constructs a new UploadStart.
         * @memberof sfiles
         * @classdesc Represents an UploadStart.
         * @implements IUploadStart
         * @constructor
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         */
        function UploadStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStart localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.localKey = "";

        /**
         * UploadStart extension.
         * @member {string} extension
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.extension = "";

        /**
         * UploadStart name.
         * @member {string} name
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.name = "";

        /**
         * UploadStart mime.
         * @member {string} mime
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.mime = "";

        /**
         * Creates a new UploadStart instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         * @returns {sfiles.UploadStart} UploadStart instance
         */
        UploadStart.create = function create(properties) {
            return new UploadStart(properties);
        };

        /**
         * Encodes the specified UploadStart message. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.extension != null && Object.hasOwnProperty.call(message, "extension"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.extension);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            return writer;
        };

        /**
         * Encodes the specified UploadStart message, length delimited. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.localKey = reader.string();
                    break;
                case 2:
                    message.extension = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.mime = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStart message.
         * @function verify
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.extension != null && message.hasOwnProperty("extension"))
                if (!$util.isString(message.extension))
                    return "extension: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            return null;
        };

        /**
         * Creates an UploadStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStart} UploadStart
         */
        UploadStart.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStart)
                return object;
            let message = new $root.sfiles.UploadStart();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.extension != null)
                message.extension = String(object.extension);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            return message;
        };

        /**
         * Creates a plain object from an UploadStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.UploadStart} message UploadStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.extension = "";
                object.name = "";
                object.mime = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.extension != null && message.hasOwnProperty("extension"))
                object.extension = message.extension;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            return object;
        };

        /**
         * Converts this UploadStart to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadStart;
    })();

    sfiles.DeleteFile = (function() {

        /**
         * Properties of a DeleteFile.
         * @memberof sfiles
         * @interface IDeleteFile
         * @property {sfiles.IUploadedFile|null} [file] DeleteFile file
         */

        /**
         * Constructs a new DeleteFile.
         * @memberof sfiles
         * @classdesc Represents a DeleteFile.
         * @implements IDeleteFile
         * @constructor
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         */
        function DeleteFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteFile file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.DeleteFile
         * @instance
         */
        DeleteFile.prototype.file = null;

        /**
         * Creates a new DeleteFile instance using the specified properties.
         * @function create
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         * @returns {sfiles.DeleteFile} DeleteFile instance
         */
        DeleteFile.create = function create(properties) {
            return new DeleteFile(properties);
        };

        /**
         * Encodes the specified DeleteFile message. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DeleteFile message, length delimited. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.DeleteFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteFile message.
         * @function verify
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates a DeleteFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.DeleteFile} DeleteFile
         */
        DeleteFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.DeleteFile)
                return object;
            let message = new $root.sfiles.DeleteFile();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.DeleteFile.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.DeleteFile} message DeleteFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this DeleteFile to JSON.
         * @function toJSON
         * @memberof sfiles.DeleteFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteFile;
    })();

    sfiles.UploadEnd = (function() {

        /**
         * Properties of an UploadEnd.
         * @memberof sfiles
         * @interface IUploadEnd
         */

        /**
         * Constructs a new UploadEnd.
         * @memberof sfiles
         * @classdesc Represents an UploadEnd.
         * @implements IUploadEnd
         * @constructor
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         */
        function UploadEnd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UploadEnd instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         * @returns {sfiles.UploadEnd} UploadEnd instance
         */
        UploadEnd.create = function create(properties) {
            return new UploadEnd(properties);
        };

        /**
         * Encodes the specified UploadEnd message. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UploadEnd message, length delimited. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadEnd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadEnd message.
         * @function verify
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadEnd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UploadEnd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadEnd} UploadEnd
         */
        UploadEnd.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadEnd)
                return object;
            return new $root.sfiles.UploadEnd();
        };

        /**
         * Creates a plain object from an UploadEnd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.UploadEnd} message UploadEnd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadEnd.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UploadEnd to JSON.
         * @function toJSON
         * @memberof sfiles.UploadEnd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadEnd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadEnd;
    })();

    return sfiles;
})();

export const socket_api = $root.socket_api = (() => {

    /**
     * Namespace socket_api.
     * @exports socket_api
     * @namespace
     */
    const socket_api = {};

    socket_api.Ack = (function() {

        /**
         * Properties of an Ack.
         * @memberof socket_api
         * @interface IAck
         * @property {string|null} [uuid] Ack uuid
         * @property {string|null} [errorMessage] Ack errorMessage
         * @property {string|null} [asyncProgressKey] Ack asyncProgressKey
         * @property {number|null} [errorCode] Ack errorCode
         */

        /**
         * Constructs a new Ack.
         * @memberof socket_api
         * @classdesc Represents an Ack.
         * @implements IAck
         * @constructor
         * @param {socket_api.IAck=} [properties] Properties to set
         */
        function Ack(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ack uuid.
         * @member {string} uuid
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.uuid = "";

        /**
         * Ack errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorMessage = "";

        /**
         * Ack asyncProgressKey.
         * @member {string} asyncProgressKey
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.asyncProgressKey = "";

        /**
         * Ack errorCode.
         * @member {number} errorCode
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorCode = 0;

        /**
         * Creates a new Ack instance using the specified properties.
         * @function create
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck=} [properties] Properties to set
         * @returns {socket_api.Ack} Ack instance
         */
        Ack.create = function create(properties) {
            return new Ack(properties);
        };

        /**
         * Encodes the specified Ack message. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encode
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
            if (message.asyncProgressKey != null && Object.hasOwnProperty.call(message, "asyncProgressKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.asyncProgressKey);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified Ack message, length delimited. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Ack message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.Ack();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uuid = reader.string();
                    break;
                case 2:
                    message.errorMessage = reader.string();
                    break;
                case 3:
                    message.asyncProgressKey = reader.string();
                    break;
                case 4:
                    message.errorCode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Ack message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Ack message.
         * @function verify
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ack.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.asyncProgressKey != null && message.hasOwnProperty("asyncProgressKey"))
                if (!$util.isString(message.asyncProgressKey))
                    return "asyncProgressKey: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isInteger(message.errorCode))
                    return "errorCode: integer expected";
            return null;
        };

        /**
         * Creates an Ack message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.Ack} Ack
         */
        Ack.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.Ack)
                return object;
            let message = new $root.socket_api.Ack();
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.asyncProgressKey != null)
                message.asyncProgressKey = String(object.asyncProgressKey);
            if (object.errorCode != null)
                message.errorCode = object.errorCode | 0;
            return message;
        };

        /**
         * Creates a plain object from an Ack message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.Ack} message Ack
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ack.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.uuid = "";
                object.errorMessage = "";
                object.asyncProgressKey = "";
                object.errorCode = 0;
            }
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.asyncProgressKey != null && message.hasOwnProperty("asyncProgressKey"))
                object.asyncProgressKey = message.asyncProgressKey;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this Ack to JSON.
         * @function toJSON
         * @memberof socket_api.Ack
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ack.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Ack;
    })();

    socket_api.AsyncProgress = (function() {

        /**
         * Properties of an AsyncProgress.
         * @memberof socket_api
         * @interface IAsyncProgress
         * @property {string|null} [key] AsyncProgress key
         * @property {number|null} [progress] AsyncProgress progress
         * @property {string|null} [info] AsyncProgress info
         * @property {boolean|null} [done] AsyncProgress done
         * @property {string|null} [errorMessage] AsyncProgress errorMessage
         */

        /**
         * Constructs a new AsyncProgress.
         * @memberof socket_api
         * @classdesc Represents an AsyncProgress.
         * @implements IAsyncProgress
         * @constructor
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         */
        function AsyncProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsyncProgress key.
         * @member {string} key
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.key = "";

        /**
         * AsyncProgress progress.
         * @member {number} progress
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.progress = 0;

        /**
         * AsyncProgress info.
         * @member {string} info
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.info = "";

        /**
         * AsyncProgress done.
         * @member {boolean} done
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.done = false;

        /**
         * AsyncProgress errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.errorMessage = "";

        /**
         * Creates a new AsyncProgress instance using the specified properties.
         * @function create
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         * @returns {socket_api.AsyncProgress} AsyncProgress instance
         */
        AsyncProgress.create = function create(properties) {
            return new AsyncProgress(properties);
        };

        /**
         * Encodes the specified AsyncProgress message. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.progress);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.info);
            if (message.done != null && Object.hasOwnProperty.call(message, "done"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.done);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.errorMessage);
            return writer;
        };

        /**
         * Encodes the specified AsyncProgress message, length delimited. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.AsyncProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.progress = reader.double();
                    break;
                case 3:
                    message.info = reader.string();
                    break;
                case 4:
                    message.done = reader.bool();
                    break;
                case 5:
                    message.errorMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsyncProgress message.
         * @function verify
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsyncProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.progress != null && message.hasOwnProperty("progress"))
                if (typeof message.progress !== "number")
                    return "progress: number expected";
            if (message.info != null && message.hasOwnProperty("info"))
                if (!$util.isString(message.info))
                    return "info: string expected";
            if (message.done != null && message.hasOwnProperty("done"))
                if (typeof message.done !== "boolean")
                    return "done: boolean expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            return null;
        };

        /**
         * Creates an AsyncProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.AsyncProgress} AsyncProgress
         */
        AsyncProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.AsyncProgress)
                return object;
            let message = new $root.socket_api.AsyncProgress();
            if (object.key != null)
                message.key = String(object.key);
            if (object.progress != null)
                message.progress = Number(object.progress);
            if (object.info != null)
                message.info = String(object.info);
            if (object.done != null)
                message.done = Boolean(object.done);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            return message;
        };

        /**
         * Creates a plain object from an AsyncProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.AsyncProgress} message AsyncProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsyncProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.progress = 0;
                object.info = "";
                object.done = false;
                object.errorMessage = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = options.json && !isFinite(message.progress) ? String(message.progress) : message.progress;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = message.info;
            if (message.done != null && message.hasOwnProperty("done"))
                object.done = message.done;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            return object;
        };

        /**
         * Converts this AsyncProgress to JSON.
         * @function toJSON
         * @memberof socket_api.AsyncProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsyncProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AsyncProgress;
    })();

    socket_api.UpgradeApiVersion = (function() {

        /**
         * Properties of an UpgradeApiVersion.
         * @memberof socket_api
         * @interface IUpgradeApiVersion
         * @property {number|null} [latest] UpgradeApiVersion latest
         */

        /**
         * Constructs a new UpgradeApiVersion.
         * @memberof socket_api
         * @classdesc Represents an UpgradeApiVersion.
         * @implements IUpgradeApiVersion
         * @constructor
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         */
        function UpgradeApiVersion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpgradeApiVersion latest.
         * @member {number} latest
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         */
        UpgradeApiVersion.prototype.latest = 0;

        /**
         * Creates a new UpgradeApiVersion instance using the specified properties.
         * @function create
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion instance
         */
        UpgradeApiVersion.create = function create(properties) {
            return new UpgradeApiVersion(properties);
        };

        /**
         * Encodes the specified UpgradeApiVersion message. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.latest != null && Object.hasOwnProperty.call(message, "latest"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.latest);
            return writer;
        };

        /**
         * Encodes the specified UpgradeApiVersion message, length delimited. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.UpgradeApiVersion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.latest = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpgradeApiVersion message.
         * @function verify
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpgradeApiVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.latest != null && message.hasOwnProperty("latest"))
                if (!$util.isInteger(message.latest))
                    return "latest: integer expected";
            return null;
        };

        /**
         * Creates an UpgradeApiVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         */
        UpgradeApiVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.UpgradeApiVersion)
                return object;
            let message = new $root.socket_api.UpgradeApiVersion();
            if (object.latest != null)
                message.latest = object.latest | 0;
            return message;
        };

        /**
         * Creates a plain object from an UpgradeApiVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.UpgradeApiVersion} message UpgradeApiVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpgradeApiVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.latest = 0;
            if (message.latest != null && message.hasOwnProperty("latest"))
                object.latest = message.latest;
            return object;
        };

        /**
         * Converts this UpgradeApiVersion to JSON.
         * @function toJSON
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpgradeApiVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpgradeApiVersion;
    })();

    return socket_api;
})();

export const authentication = $root.authentication = (() => {

    /**
     * Namespace authentication.
     * @exports authentication
     * @namespace
     */
    const authentication = {};

    authentication.LoginToken = (function() {

        /**
         * Properties of a LoginToken.
         * @memberof authentication
         * @interface ILoginToken
         * @property {string|null} [token] LoginToken token
         */

        /**
         * Constructs a new LoginToken.
         * @memberof authentication
         * @classdesc Represents a LoginToken.
         * @implements ILoginToken
         * @constructor
         * @param {authentication.ILoginToken=} [properties] Properties to set
         */
        function LoginToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginToken token.
         * @member {string} token
         * @memberof authentication.LoginToken
         * @instance
         */
        LoginToken.prototype.token = "";

        /**
         * Creates a new LoginToken instance using the specified properties.
         * @function create
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken=} [properties] Properties to set
         * @returns {authentication.LoginToken} LoginToken instance
         */
        LoginToken.create = function create(properties) {
            return new LoginToken(properties);
        };

        /**
         * Encodes the specified LoginToken message. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified LoginToken message, length delimited. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginToken message.
         * @function verify
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a LoginToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginToken} LoginToken
         */
        LoginToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginToken)
                return object;
            let message = new $root.authentication.LoginToken();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a LoginToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.LoginToken} message LoginToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this LoginToken to JSON.
         * @function toJSON
         * @memberof authentication.LoginToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginToken;
    })();

    authentication.LoginError = (function() {

        /**
         * Properties of a LoginError.
         * @memberof authentication
         * @interface ILoginError
         * @property {string|null} [errorText] LoginError errorText
         * @property {string|null} [errorCode] LoginError errorCode
         */

        /**
         * Constructs a new LoginError.
         * @memberof authentication
         * @classdesc Represents a LoginError.
         * @implements ILoginError
         * @constructor
         * @param {authentication.ILoginError=} [properties] Properties to set
         */
        function LoginError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginError errorText.
         * @member {string} errorText
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorText = "";

        /**
         * LoginError errorCode.
         * @member {string} errorCode
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorCode = "";

        /**
         * Creates a new LoginError instance using the specified properties.
         * @function create
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError=} [properties] Properties to set
         * @returns {authentication.LoginError} LoginError instance
         */
        LoginError.create = function create(properties) {
            return new LoginError(properties);
        };

        /**
         * Encodes the specified LoginError message. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errorText != null && Object.hasOwnProperty.call(message, "errorText"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.errorText);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified LoginError message, length delimited. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.errorText = reader.string();
                    break;
                case 2:
                    message.errorCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginError message.
         * @function verify
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                if (!$util.isString(message.errorText))
                    return "errorText: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            return null;
        };

        /**
         * Creates a LoginError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginError} LoginError
         */
        LoginError.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginError)
                return object;
            let message = new $root.authentication.LoginError();
            if (object.errorText != null)
                message.errorText = String(object.errorText);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            return message;
        };

        /**
         * Creates a plain object from a LoginError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.LoginError} message LoginError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.errorText = "";
                object.errorCode = "";
            }
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                object.errorText = message.errorText;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this LoginError to JSON.
         * @function toJSON
         * @memberof authentication.LoginError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginError;
    })();

    authentication.Login = (function() {

        /**
         * Properties of a Login.
         * @memberof authentication
         * @interface ILogin
         * @property {string|null} [username] Login username
         * @property {string|null} [password] Login password
         */

        /**
         * Constructs a new Login.
         * @memberof authentication
         * @classdesc Represents a Login.
         * @implements ILogin
         * @constructor
         * @param {authentication.ILogin=} [properties] Properties to set
         */
        function Login(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Login username.
         * @member {string} username
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.username = "";

        /**
         * Login password.
         * @member {string} password
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.password = "";

        /**
         * Creates a new Login instance using the specified properties.
         * @function create
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin=} [properties] Properties to set
         * @returns {authentication.Login} Login instance
         */
        Login.create = function create(properties) {
            return new Login(properties);
        };

        /**
         * Encodes the specified Login message. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encode
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified Login message, length delimited. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Login message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.Login();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Login message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Login message.
         * @function verify
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Login.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a Login message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.Login} Login
         */
        Login.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.Login)
                return object;
            let message = new $root.authentication.Login();
            if (object.username != null)
                message.username = String(object.username);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a Login message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.Login
         * @static
         * @param {authentication.Login} message Login
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Login.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.username = "";
                object.password = "";
            }
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this Login to JSON.
         * @function toJSON
         * @memberof authentication.Login
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Login.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Login;
    })();

    authentication.VerifyToken = (function() {

        /**
         * Properties of a VerifyToken.
         * @memberof authentication
         * @interface IVerifyToken
         */

        /**
         * Constructs a new VerifyToken.
         * @memberof authentication
         * @classdesc Represents a VerifyToken.
         * @implements IVerifyToken
         * @constructor
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         */
        function VerifyToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new VerifyToken instance using the specified properties.
         * @function create
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         * @returns {authentication.VerifyToken} VerifyToken instance
         */
        VerifyToken.create = function create(properties) {
            return new VerifyToken(properties);
        };

        /**
         * Encodes the specified VerifyToken message. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified VerifyToken message, length delimited. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.VerifyToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyToken message.
         * @function verify
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a VerifyToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.VerifyToken} VerifyToken
         */
        VerifyToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.VerifyToken)
                return object;
            return new $root.authentication.VerifyToken();
        };

        /**
         * Creates a plain object from a VerifyToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.VerifyToken} message VerifyToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyToken.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this VerifyToken to JSON.
         * @function toJSON
         * @memberof authentication.VerifyToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VerifyToken;
    })();

    authentication.TokenInvalid = (function() {

        /**
         * Properties of a TokenInvalid.
         * @memberof authentication
         * @interface ITokenInvalid
         */

        /**
         * Constructs a new TokenInvalid.
         * @memberof authentication
         * @classdesc Represents a TokenInvalid.
         * @implements ITokenInvalid
         * @constructor
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         */
        function TokenInvalid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TokenInvalid instance using the specified properties.
         * @function create
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         * @returns {authentication.TokenInvalid} TokenInvalid instance
         */
        TokenInvalid.create = function create(properties) {
            return new TokenInvalid(properties);
        };

        /**
         * Encodes the specified TokenInvalid message. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TokenInvalid message, length delimited. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.TokenInvalid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TokenInvalid message.
         * @function verify
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TokenInvalid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TokenInvalid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.TokenInvalid} TokenInvalid
         */
        TokenInvalid.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.TokenInvalid)
                return object;
            return new $root.authentication.TokenInvalid();
        };

        /**
         * Creates a plain object from a TokenInvalid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.TokenInvalid} message TokenInvalid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TokenInvalid.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TokenInvalid to JSON.
         * @function toJSON
         * @memberof authentication.TokenInvalid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TokenInvalid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TokenInvalid;
    })();

    return authentication;
})();

export const uploader = $root.uploader = (() => {

    /**
     * Namespace uploader.
     * @exports uploader
     * @namespace
     */
    const uploader = {};

    uploader.UploadTask = (function() {

        /**
         * Properties of an UploadTask.
         * @memberof uploader
         * @interface IUploadTask
         * @property {number|Long|null} [created] UploadTask created
         * @property {string|null} [path] UploadTask path
         * @property {string|null} [name] UploadTask name
         * @property {string|null} [mime] UploadTask mime
         * @property {uploader.UploadStatus|null} [status] UploadTask status
         * @property {number|null} [retryCounter] UploadTask retryCounter
         * @property {string|null} [error] UploadTask error
         * @property {string|null} [fingerprint] UploadTask fingerprint
         * @property {string|null} [url] UploadTask url
         * @property {Object.<string,string>|null} [metadata] UploadTask metadata
         */

        /**
         * Constructs a new UploadTask.
         * @memberof uploader
         * @classdesc Represents an UploadTask.
         * @implements IUploadTask
         * @constructor
         * @param {uploader.IUploadTask=} [properties] Properties to set
         */
        function UploadTask(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadTask created.
         * @member {number|Long} created
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UploadTask path.
         * @member {string} path
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.path = "";

        /**
         * UploadTask name.
         * @member {string} name
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.name = "";

        /**
         * UploadTask mime.
         * @member {string} mime
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.mime = "";

        /**
         * UploadTask status.
         * @member {uploader.UploadStatus} status
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.status = 0;

        /**
         * UploadTask retryCounter.
         * @member {number} retryCounter
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.retryCounter = 0;

        /**
         * UploadTask error.
         * @member {string} error
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.error = "";

        /**
         * UploadTask fingerprint.
         * @member {string} fingerprint
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.fingerprint = "";

        /**
         * UploadTask url.
         * @member {string} url
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.url = "";

        /**
         * UploadTask metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadTask instance using the specified properties.
         * @function create
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask=} [properties] Properties to set
         * @returns {uploader.UploadTask} UploadTask instance
         */
        UploadTask.create = function create(properties) {
            return new UploadTask(properties);
        };

        /**
         * Encodes the specified UploadTask message. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.created);
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            if (message.retryCounter != null && Object.hasOwnProperty.call(message, "retryCounter"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.retryCounter);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.error);
            if (message.fingerprint != null && Object.hasOwnProperty.call(message, "fingerprint"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fingerprint);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.url);
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadTask message, length delimited. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadTask(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.created = reader.uint64();
                    break;
                case 2:
                    message.path = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.mime = reader.string();
                    break;
                case 5:
                    message.status = reader.int32();
                    break;
                case 6:
                    message.retryCounter = reader.int32();
                    break;
                case 7:
                    message.error = reader.string();
                    break;
                case 8:
                    message.fingerprint = reader.string();
                    break;
                case 9:
                    message.url = reader.string();
                    break;
                case 10:
                    if (message.metadata === $util.emptyObject)
                        message.metadata = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.metadata[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadTask message.
         * @function verify
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.created != null && message.hasOwnProperty("created"))
                if (!$util.isInteger(message.created) && !(message.created && $util.isInteger(message.created.low) && $util.isInteger(message.created.high)))
                    return "created: integer|Long expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                if (!$util.isInteger(message.retryCounter))
                    return "retryCounter: integer expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                if (!$util.isString(message.fingerprint))
                    return "fingerprint: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadTask} UploadTask
         */
        UploadTask.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadTask)
                return object;
            let message = new $root.uploader.UploadTask();
            if (object.created != null)
                if ($util.Long)
                    (message.created = $util.Long.fromValue(object.created)).unsigned = true;
                else if (typeof object.created === "string")
                    message.created = parseInt(object.created, 10);
                else if (typeof object.created === "number")
                    message.created = object.created;
                else if (typeof object.created === "object")
                    message.created = new $util.LongBits(object.created.low >>> 0, object.created.high >>> 0).toNumber(true);
            if (object.path != null)
                message.path = String(object.path);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            switch (object.status) {
            case "scheduled":
            case 0:
                message.status = 0;
                break;
            case "uploading":
            case 1:
                message.status = 1;
                break;
            case "paused":
            case 3:
                message.status = 3;
                break;
            case "done":
            case 4:
                message.status = 4;
                break;
            case "error":
            case 5:
                message.status = 5;
                break;
            case "restored":
            case 6:
                message.status = 6;
                break;
            }
            if (object.retryCounter != null)
                message.retryCounter = object.retryCounter | 0;
            if (object.error != null)
                message.error = String(object.error);
            if (object.fingerprint != null)
                message.fingerprint = String(object.fingerprint);
            if (object.url != null)
                message.url = String(object.url);
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadTask.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.UploadTask} message UploadTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.created = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.created = options.longs === String ? "0" : 0;
                object.path = "";
                object.name = "";
                object.mime = "";
                object.status = options.enums === String ? "scheduled" : 0;
                object.retryCounter = 0;
                object.error = "";
                object.fingerprint = "";
                object.url = "";
            }
            if (message.created != null && message.hasOwnProperty("created"))
                if (typeof message.created === "number")
                    object.created = options.longs === String ? String(message.created) : message.created;
                else
                    object.created = options.longs === String ? $util.Long.prototype.toString.call(message.created) : options.longs === Number ? new $util.LongBits(message.created.low >>> 0, message.created.high >>> 0).toNumber(true) : message.created;
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.uploader.UploadStatus[message.status] : message.status;
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                object.retryCounter = message.retryCounter;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                object.fingerprint = message.fingerprint;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadTask to JSON.
         * @function toJSON
         * @memberof uploader.UploadTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadTask;
    })();

    /**
     * UploadStatus enum.
     * @name uploader.UploadStatus
     * @enum {number}
     * @property {number} scheduled=0 scheduled value
     * @property {number} uploading=1 uploading value
     * @property {number} paused=3 paused value
     * @property {number} done=4 done value
     * @property {number} error=5 error value
     * @property {number} restored=6 restored value
     */
    uploader.UploadStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "scheduled"] = 0;
        values[valuesById[1] = "uploading"] = 1;
        values[valuesById[3] = "paused"] = 3;
        values[valuesById[4] = "done"] = 4;
        values[valuesById[5] = "error"] = 5;
        values[valuesById[6] = "restored"] = 6;
        return values;
    })();

    uploader.UploadUFile = (function() {

        /**
         * Properties of an UploadUFile.
         * @memberof uploader
         * @interface IUploadUFile
         * @property {string|null} [fileId] UploadUFile fileId
         * @property {uploader.IUploadTask|null} [task] UploadUFile task
         */

        /**
         * Constructs a new UploadUFile.
         * @memberof uploader
         * @classdesc Represents an UploadUFile.
         * @implements IUploadUFile
         * @constructor
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         */
        function UploadUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadUFile fileId.
         * @member {string} fileId
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.fileId = "";

        /**
         * UploadUFile task.
         * @member {uploader.IUploadTask|null|undefined} task
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.task = null;

        /**
         * Creates a new UploadUFile instance using the specified properties.
         * @function create
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         * @returns {uploader.UploadUFile} UploadUFile instance
         */
        UploadUFile.create = function create(properties) {
            return new UploadUFile(properties);
        };

        /**
         * Encodes the specified UploadUFile message. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                $root.uploader.UploadTask.encode(message.task, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadUFile message, length delimited. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                case 2:
                    message.task = $root.uploader.UploadTask.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadUFile message.
         * @function verify
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.task != null && message.hasOwnProperty("task")) {
                let error = $root.uploader.UploadTask.verify(message.task);
                if (error)
                    return "task." + error;
            }
            return null;
        };

        /**
         * Creates an UploadUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadUFile} UploadUFile
         */
        UploadUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadUFile)
                return object;
            let message = new $root.uploader.UploadUFile();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.task != null) {
                if (typeof object.task !== "object")
                    throw TypeError(".uploader.UploadUFile.task: object expected");
                message.task = $root.uploader.UploadTask.fromObject(object.task);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.UploadUFile} message UploadUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.task = null;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.task != null && message.hasOwnProperty("task"))
                object.task = $root.uploader.UploadTask.toObject(message.task, options);
            return object;
        };

        /**
         * Converts this UploadUFile to JSON.
         * @function toJSON
         * @memberof uploader.UploadUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadUFile;
    })();

    uploader.DeleteUFile = (function() {

        /**
         * Properties of a DeleteUFile.
         * @memberof uploader
         * @interface IDeleteUFile
         * @property {string|null} [id] DeleteUFile id
         */

        /**
         * Constructs a new DeleteUFile.
         * @memberof uploader
         * @classdesc Represents a DeleteUFile.
         * @implements IDeleteUFile
         * @constructor
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         */
        function DeleteUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteUFile id.
         * @member {string} id
         * @memberof uploader.DeleteUFile
         * @instance
         */
        DeleteUFile.prototype.id = "";

        /**
         * Creates a new DeleteUFile instance using the specified properties.
         * @function create
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         * @returns {uploader.DeleteUFile} DeleteUFile instance
         */
        DeleteUFile.create = function create(properties) {
            return new DeleteUFile(properties);
        };

        /**
         * Encodes the specified DeleteUFile message. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified DeleteUFile message, length delimited. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.DeleteUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUFile message.
         * @function verify
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a DeleteUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.DeleteUFile} DeleteUFile
         */
        DeleteUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.DeleteUFile)
                return object;
            let message = new $root.uploader.DeleteUFile();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a DeleteUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.DeleteUFile} message DeleteUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this DeleteUFile to JSON.
         * @function toJSON
         * @memberof uploader.DeleteUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteUFile;
    })();

    uploader.UploadSlot = (function() {

        /**
         * Properties of an UploadSlot.
         * @memberof uploader
         * @interface IUploadSlot
         * @property {uploader.IUFile|null} [file] UploadSlot file
         * @property {Object.<string,string>|null} [metadata] UploadSlot metadata
         */

        /**
         * Constructs a new UploadSlot.
         * @memberof uploader
         * @classdesc Represents an UploadSlot.
         * @implements IUploadSlot
         * @constructor
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         */
        function UploadSlot(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadSlot file.
         * @member {uploader.IUFile|null|undefined} file
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.file = null;

        /**
         * UploadSlot metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadSlot instance using the specified properties.
         * @function create
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         * @returns {uploader.UploadSlot} UploadSlot instance
         */
        UploadSlot.create = function create(properties) {
            return new UploadSlot(properties);
        };

        /**
         * Encodes the specified UploadSlot message. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.uploader.UFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadSlot message, length delimited. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadSlot(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.file = $root.uploader.UFile.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (message.metadata === $util.emptyObject)
                        message.metadata = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.metadata[key] = value;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadSlot message.
         * @function verify
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.uploader.UFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadSlot} UploadSlot
         */
        UploadSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadSlot)
                return object;
            let message = new $root.uploader.UploadSlot();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".uploader.UploadSlot.file: object expected");
                message.file = $root.uploader.UFile.fromObject(object.file);
            }
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadSlot.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.UploadSlot} message UploadSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.uploader.UFile.toObject(message.file, options);
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadSlot to JSON.
         * @function toJSON
         * @memberof uploader.UploadSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadSlot;
    })();

    uploader.UFile = (function() {

        /**
         * Properties of a UFile.
         * @memberof uploader
         * @interface IUFile
         * @property {string|null} [id] UFile id
         * @property {string|null} [src] UFile src
         * @property {string|null} [preview] UFile preview
         * @property {string|null} [previewLarge] UFile previewLarge
         * @property {string|null} [name] UFile name
         * @property {string|null} [uploadTask] UFile uploadTask
         * @property {string|null} [localPath] UFile localPath
         */

        /**
         * Constructs a new UFile.
         * @memberof uploader
         * @classdesc Represents a UFile.
         * @implements IUFile
         * @constructor
         * @param {uploader.IUFile=} [properties] Properties to set
         */
        function UFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UFile id.
         * @member {string} id
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.id = "";

        /**
         * UFile src.
         * @member {string} src
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.src = "";

        /**
         * UFile preview.
         * @member {string} preview
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.preview = "";

        /**
         * UFile previewLarge.
         * @member {string} previewLarge
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.previewLarge = "";

        /**
         * UFile name.
         * @member {string} name
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.name = "";

        /**
         * UFile uploadTask.
         * @member {string} uploadTask
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.uploadTask = "";

        /**
         * UFile localPath.
         * @member {string} localPath
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.localPath = "";

        /**
         * Creates a new UFile instance using the specified properties.
         * @function create
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile=} [properties] Properties to set
         * @returns {uploader.UFile} UFile instance
         */
        UFile.create = function create(properties) {
            return new UFile(properties);
        };

        /**
         * Encodes the specified UFile message. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.src);
            if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.preview);
            if (message.previewLarge != null && Object.hasOwnProperty.call(message, "previewLarge"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.previewLarge);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.uploadTask != null && Object.hasOwnProperty.call(message, "uploadTask"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.uploadTask);
            if (message.localPath != null && Object.hasOwnProperty.call(message, "localPath"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.localPath);
            return writer;
        };

        /**
         * Encodes the specified UFile message, length delimited. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.src = reader.string();
                    break;
                case 3:
                    message.preview = reader.string();
                    break;
                case 4:
                    message.previewLarge = reader.string();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.uploadTask = reader.string();
                    break;
                case 7:
                    message.localPath = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UFile message.
         * @function verify
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            if (message.preview != null && message.hasOwnProperty("preview"))
                if (!$util.isString(message.preview))
                    return "preview: string expected";
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                if (!$util.isString(message.previewLarge))
                    return "previewLarge: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                if (!$util.isString(message.uploadTask))
                    return "uploadTask: string expected";
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                if (!$util.isString(message.localPath))
                    return "localPath: string expected";
            return null;
        };

        /**
         * Creates a UFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UFile} UFile
         */
        UFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UFile)
                return object;
            let message = new $root.uploader.UFile();
            if (object.id != null)
                message.id = String(object.id);
            if (object.src != null)
                message.src = String(object.src);
            if (object.preview != null)
                message.preview = String(object.preview);
            if (object.previewLarge != null)
                message.previewLarge = String(object.previewLarge);
            if (object.name != null)
                message.name = String(object.name);
            if (object.uploadTask != null)
                message.uploadTask = String(object.uploadTask);
            if (object.localPath != null)
                message.localPath = String(object.localPath);
            return message;
        };

        /**
         * Creates a plain object from a UFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UFile
         * @static
         * @param {uploader.UFile} message UFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.src = "";
                object.preview = "";
                object.previewLarge = "";
                object.name = "";
                object.uploadTask = "";
                object.localPath = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            if (message.preview != null && message.hasOwnProperty("preview"))
                object.preview = message.preview;
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                object.previewLarge = message.previewLarge;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                object.uploadTask = message.uploadTask;
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                object.localPath = message.localPath;
            return object;
        };

        /**
         * Converts this UFile to JSON.
         * @function toJSON
         * @memberof uploader.UFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UFile;
    })();

    return uploader;
})();

export const inz_types = $root.inz_types = (() => {

    /**
     * Namespace inz_types.
     * @exports inz_types
     * @namespace
     */
    const inz_types = {};

    inz_types.Date = (function() {

        /**
         * Properties of a Date.
         * @memberof inz_types
         * @interface IDate
         * @property {number|Long|null} [ts] Date ts
         * @property {number|null} [day] Date day
         * @property {number|null} [month] Date month
         * @property {number|null} [year] Date year
         * @property {inz_types.GTERelation|null} [relation] Date relation
         */

        /**
         * Constructs a new Date.
         * @memberof inz_types
         * @classdesc Represents a Date.
         * @implements IDate
         * @constructor
         * @param {inz_types.IDate=} [properties] Properties to set
         */
        function Date(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Date ts.
         * @member {number|Long} ts
         * @memberof inz_types.Date
         * @instance
         */
        Date.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Date day.
         * @member {number} day
         * @memberof inz_types.Date
         * @instance
         */
        Date.prototype.day = 0;

        /**
         * Date month.
         * @member {number} month
         * @memberof inz_types.Date
         * @instance
         */
        Date.prototype.month = 0;

        /**
         * Date year.
         * @member {number} year
         * @memberof inz_types.Date
         * @instance
         */
        Date.prototype.year = 0;

        /**
         * Date relation.
         * @member {inz_types.GTERelation} relation
         * @memberof inz_types.Date
         * @instance
         */
        Date.prototype.relation = 0;

        /**
         * Creates a new Date instance using the specified properties.
         * @function create
         * @memberof inz_types.Date
         * @static
         * @param {inz_types.IDate=} [properties] Properties to set
         * @returns {inz_types.Date} Date instance
         */
        Date.create = function create(properties) {
            return new Date(properties);
        };

        /**
         * Encodes the specified Date message. Does not implicitly {@link inz_types.Date.verify|verify} messages.
         * @function encode
         * @memberof inz_types.Date
         * @static
         * @param {inz_types.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ts != null && Object.hasOwnProperty.call(message, "ts"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ts);
            if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.day);
            if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.month);
            if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.year);
            if (message.relation != null && Object.hasOwnProperty.call(message, "relation"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.relation);
            return writer;
        };

        /**
         * Encodes the specified Date message, length delimited. Does not implicitly {@link inz_types.Date.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_types.Date
         * @static
         * @param {inz_types.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Date message from the specified reader or buffer.
         * @function decode
         * @memberof inz_types.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_types.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_types.Date();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ts = reader.uint64();
                    break;
                case 2:
                    message.day = reader.int32();
                    break;
                case 3:
                    message.month = reader.int32();
                    break;
                case 4:
                    message.year = reader.int32();
                    break;
                case 5:
                    message.relation = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Date message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_types.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_types.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Date message.
         * @function verify
         * @memberof inz_types.Date
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Date.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (!$util.isInteger(message.ts) && !(message.ts && $util.isInteger(message.ts.low) && $util.isInteger(message.ts.high)))
                    return "ts: integer|Long expected";
            if (message.day != null && message.hasOwnProperty("day"))
                if (!$util.isInteger(message.day))
                    return "day: integer expected";
            if (message.month != null && message.hasOwnProperty("month"))
                if (!$util.isInteger(message.month))
                    return "month: integer expected";
            if (message.year != null && message.hasOwnProperty("year"))
                if (!$util.isInteger(message.year))
                    return "year: integer expected";
            if (message.relation != null && message.hasOwnProperty("relation"))
                switch (message.relation) {
                default:
                    return "relation: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a Date message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_types.Date
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_types.Date} Date
         */
        Date.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_types.Date)
                return object;
            let message = new $root.inz_types.Date();
            if (object.ts != null)
                if ($util.Long)
                    (message.ts = $util.Long.fromValue(object.ts)).unsigned = true;
                else if (typeof object.ts === "string")
                    message.ts = parseInt(object.ts, 10);
                else if (typeof object.ts === "number")
                    message.ts = object.ts;
                else if (typeof object.ts === "object")
                    message.ts = new $util.LongBits(object.ts.low >>> 0, object.ts.high >>> 0).toNumber(true);
            if (object.day != null)
                message.day = object.day | 0;
            if (object.month != null)
                message.month = object.month | 0;
            if (object.year != null)
                message.year = object.year | 0;
            switch (object.relation) {
            case "eq":
            case 0:
                message.relation = 0;
                break;
            case "gt":
            case 1:
                message.relation = 1;
                break;
            case "lt":
            case 2:
                message.relation = 2;
                break;
            case "gte":
            case 3:
                message.relation = 3;
                break;
            case "lte":
            case 4:
                message.relation = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Date message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_types.Date
         * @static
         * @param {inz_types.Date} message Date
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Date.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ts = options.longs === String ? "0" : 0;
                object.day = 0;
                object.month = 0;
                object.year = 0;
                object.relation = options.enums === String ? "eq" : 0;
            }
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (typeof message.ts === "number")
                    object.ts = options.longs === String ? String(message.ts) : message.ts;
                else
                    object.ts = options.longs === String ? $util.Long.prototype.toString.call(message.ts) : options.longs === Number ? new $util.LongBits(message.ts.low >>> 0, message.ts.high >>> 0).toNumber(true) : message.ts;
            if (message.day != null && message.hasOwnProperty("day"))
                object.day = message.day;
            if (message.month != null && message.hasOwnProperty("month"))
                object.month = message.month;
            if (message.year != null && message.hasOwnProperty("year"))
                object.year = message.year;
            if (message.relation != null && message.hasOwnProperty("relation"))
                object.relation = options.enums === String ? $root.inz_types.GTERelation[message.relation] : message.relation;
            return object;
        };

        /**
         * Converts this Date to JSON.
         * @function toJSON
         * @memberof inz_types.Date
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Date.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Date;
    })();

    inz_types.InzInputValue = (function() {

        /**
         * Properties of an InzInputValue.
         * @memberof inz_types
         * @interface IInzInputValue
         * @property {number|null} [number] InzInputValue number
         * @property {string|null} [text] InzInputValue text
         * @property {inz_types.IDate|null} [date] InzInputValue date
         * @property {boolean|null} [yesNo] InzInputValue yesNo
         */

        /**
         * Constructs a new InzInputValue.
         * @memberof inz_types
         * @classdesc Represents an InzInputValue.
         * @implements IInzInputValue
         * @constructor
         * @param {inz_types.IInzInputValue=} [properties] Properties to set
         */
        function InzInputValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InzInputValue number.
         * @member {number} number
         * @memberof inz_types.InzInputValue
         * @instance
         */
        InzInputValue.prototype.number = 0;

        /**
         * InzInputValue text.
         * @member {string} text
         * @memberof inz_types.InzInputValue
         * @instance
         */
        InzInputValue.prototype.text = "";

        /**
         * InzInputValue date.
         * @member {inz_types.IDate|null|undefined} date
         * @memberof inz_types.InzInputValue
         * @instance
         */
        InzInputValue.prototype.date = null;

        /**
         * InzInputValue yesNo.
         * @member {boolean} yesNo
         * @memberof inz_types.InzInputValue
         * @instance
         */
        InzInputValue.prototype.yesNo = false;

        /**
         * Creates a new InzInputValue instance using the specified properties.
         * @function create
         * @memberof inz_types.InzInputValue
         * @static
         * @param {inz_types.IInzInputValue=} [properties] Properties to set
         * @returns {inz_types.InzInputValue} InzInputValue instance
         */
        InzInputValue.create = function create(properties) {
            return new InzInputValue(properties);
        };

        /**
         * Encodes the specified InzInputValue message. Does not implicitly {@link inz_types.InzInputValue.verify|verify} messages.
         * @function encode
         * @memberof inz_types.InzInputValue
         * @static
         * @param {inz_types.IInzInputValue} message InzInputValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InzInputValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.number);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                $root.inz_types.Date.encode(message.date, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.yesNo != null && Object.hasOwnProperty.call(message, "yesNo"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.yesNo);
            return writer;
        };

        /**
         * Encodes the specified InzInputValue message, length delimited. Does not implicitly {@link inz_types.InzInputValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_types.InzInputValue
         * @static
         * @param {inz_types.IInzInputValue} message InzInputValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InzInputValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InzInputValue message from the specified reader or buffer.
         * @function decode
         * @memberof inz_types.InzInputValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_types.InzInputValue} InzInputValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InzInputValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_types.InzInputValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.number = reader.double();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.date = $root.inz_types.Date.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.yesNo = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InzInputValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_types.InzInputValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_types.InzInputValue} InzInputValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InzInputValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InzInputValue message.
         * @function verify
         * @memberof inz_types.InzInputValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InzInputValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.number != null && message.hasOwnProperty("number"))
                if (typeof message.number !== "number")
                    return "number: number expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.date != null && message.hasOwnProperty("date")) {
                let error = $root.inz_types.Date.verify(message.date);
                if (error)
                    return "date." + error;
            }
            if (message.yesNo != null && message.hasOwnProperty("yesNo"))
                if (typeof message.yesNo !== "boolean")
                    return "yesNo: boolean expected";
            return null;
        };

        /**
         * Creates an InzInputValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_types.InzInputValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_types.InzInputValue} InzInputValue
         */
        InzInputValue.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_types.InzInputValue)
                return object;
            let message = new $root.inz_types.InzInputValue();
            if (object.number != null)
                message.number = Number(object.number);
            if (object.text != null)
                message.text = String(object.text);
            if (object.date != null) {
                if (typeof object.date !== "object")
                    throw TypeError(".inz_types.InzInputValue.date: object expected");
                message.date = $root.inz_types.Date.fromObject(object.date);
            }
            if (object.yesNo != null)
                message.yesNo = Boolean(object.yesNo);
            return message;
        };

        /**
         * Creates a plain object from an InzInputValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_types.InzInputValue
         * @static
         * @param {inz_types.InzInputValue} message InzInputValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InzInputValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.number = 0;
                object.text = "";
                object.date = null;
                object.yesNo = false;
            }
            if (message.number != null && message.hasOwnProperty("number"))
                object.number = options.json && !isFinite(message.number) ? String(message.number) : message.number;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.date != null && message.hasOwnProperty("date"))
                object.date = $root.inz_types.Date.toObject(message.date, options);
            if (message.yesNo != null && message.hasOwnProperty("yesNo"))
                object.yesNo = message.yesNo;
            return object;
        };

        /**
         * Converts this InzInputValue to JSON.
         * @function toJSON
         * @memberof inz_types.InzInputValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InzInputValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InzInputValue;
    })();

    inz_types.InzInput = (function() {

        /**
         * Properties of an InzInput.
         * @memberof inz_types
         * @interface IInzInput
         * @property {string|null} [key] InzInput key
         * @property {inz_types.InzInputType|null} [type] InzInput type
         * @property {string|null} [label] InzInput label
         * @property {inz_types.IInzInputValue|null} [value] InzInput value
         * @property {Array.<inz_types.IInzInputValue>|null} [values] InzInput values
         * @property {boolean|null} [readonly] InzInput readonly
         * @property {boolean|null} [repeated] InzInput repeated
         */

        /**
         * Constructs a new InzInput.
         * @memberof inz_types
         * @classdesc Represents an InzInput.
         * @implements IInzInput
         * @constructor
         * @param {inz_types.IInzInput=} [properties] Properties to set
         */
        function InzInput(properties) {
            this.values = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InzInput key.
         * @member {string} key
         * @memberof inz_types.InzInput
         * @instance
         */
        InzInput.prototype.key = "";

        /**
         * InzInput type.
         * @member {inz_types.InzInputType} type
         * @memberof inz_types.InzInput
         * @instance
         */
        InzInput.prototype.type = 0;

        /**
         * InzInput label.
         * @member {string} label
         * @memberof inz_types.InzInput
         * @instance
         */
        InzInput.prototype.label = "";

        /**
         * InzInput value.
         * @member {inz_types.IInzInputValue|null|undefined} value
         * @memberof inz_types.InzInput
         * @instance
         */
        InzInput.prototype.value = null;

        /**
         * InzInput values.
         * @member {Array.<inz_types.IInzInputValue>} values
         * @memberof inz_types.InzInput
         * @instance
         */
        InzInput.prototype.values = $util.emptyArray;

        /**
         * InzInput readonly.
         * @member {boolean} readonly
         * @memberof inz_types.InzInput
         * @instance
         */
        InzInput.prototype.readonly = false;

        /**
         * InzInput repeated.
         * @member {boolean} repeated
         * @memberof inz_types.InzInput
         * @instance
         */
        InzInput.prototype.repeated = false;

        /**
         * Creates a new InzInput instance using the specified properties.
         * @function create
         * @memberof inz_types.InzInput
         * @static
         * @param {inz_types.IInzInput=} [properties] Properties to set
         * @returns {inz_types.InzInput} InzInput instance
         */
        InzInput.create = function create(properties) {
            return new InzInput(properties);
        };

        /**
         * Encodes the specified InzInput message. Does not implicitly {@link inz_types.InzInput.verify|verify} messages.
         * @function encode
         * @memberof inz_types.InzInput
         * @static
         * @param {inz_types.IInzInput} message InzInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InzInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.inz_types.InzInputValue.encode(message.value, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.values != null && message.values.length)
                for (let i = 0; i < message.values.length; ++i)
                    $root.inz_types.InzInputValue.encode(message.values[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.readonly != null && Object.hasOwnProperty.call(message, "readonly"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.readonly);
            if (message.repeated != null && Object.hasOwnProperty.call(message, "repeated"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.repeated);
            return writer;
        };

        /**
         * Encodes the specified InzInput message, length delimited. Does not implicitly {@link inz_types.InzInput.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_types.InzInput
         * @static
         * @param {inz_types.IInzInput} message InzInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InzInput.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InzInput message from the specified reader or buffer.
         * @function decode
         * @memberof inz_types.InzInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_types.InzInput} InzInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InzInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_types.InzInput();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.label = reader.string();
                    break;
                case 4:
                    message.value = $root.inz_types.InzInputValue.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push($root.inz_types.InzInputValue.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.readonly = reader.bool();
                    break;
                case 7:
                    message.repeated = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InzInput message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_types.InzInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_types.InzInput} InzInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InzInput.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InzInput message.
         * @function verify
         * @memberof inz_types.InzInput
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InzInput.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.inz_types.InzInputValue.verify(message.value);
                if (error)
                    return "value." + error;
            }
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (let i = 0; i < message.values.length; ++i) {
                    let error = $root.inz_types.InzInputValue.verify(message.values[i]);
                    if (error)
                        return "values." + error;
                }
            }
            if (message.readonly != null && message.hasOwnProperty("readonly"))
                if (typeof message.readonly !== "boolean")
                    return "readonly: boolean expected";
            if (message.repeated != null && message.hasOwnProperty("repeated"))
                if (typeof message.repeated !== "boolean")
                    return "repeated: boolean expected";
            return null;
        };

        /**
         * Creates an InzInput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_types.InzInput
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_types.InzInput} InzInput
         */
        InzInput.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_types.InzInput)
                return object;
            let message = new $root.inz_types.InzInput();
            if (object.key != null)
                message.key = String(object.key);
            switch (object.type) {
            case "text":
            case 0:
                message.type = 0;
                break;
            case "number":
            case 1:
                message.type = 1;
                break;
            case "date":
            case 2:
                message.type = 2;
                break;
            case "free_select":
            case 3:
                message.type = 3;
                break;
            case "fixed_select":
            case 4:
                message.type = 4;
                break;
            case "check":
            case 5:
                message.type = 5;
                break;
            }
            if (object.label != null)
                message.label = String(object.label);
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".inz_types.InzInput.value: object expected");
                message.value = $root.inz_types.InzInputValue.fromObject(object.value);
            }
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".inz_types.InzInput.values: array expected");
                message.values = [];
                for (let i = 0; i < object.values.length; ++i) {
                    if (typeof object.values[i] !== "object")
                        throw TypeError(".inz_types.InzInput.values: object expected");
                    message.values[i] = $root.inz_types.InzInputValue.fromObject(object.values[i]);
                }
            }
            if (object.readonly != null)
                message.readonly = Boolean(object.readonly);
            if (object.repeated != null)
                message.repeated = Boolean(object.repeated);
            return message;
        };

        /**
         * Creates a plain object from an InzInput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_types.InzInput
         * @static
         * @param {inz_types.InzInput} message InzInput
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InzInput.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (options.defaults) {
                object.key = "";
                object.type = options.enums === String ? "text" : 0;
                object.label = "";
                object.value = null;
                object.readonly = false;
                object.repeated = false;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.inz_types.InzInputType[message.type] : message.type;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.inz_types.InzInputValue.toObject(message.value, options);
            if (message.values && message.values.length) {
                object.values = [];
                for (let j = 0; j < message.values.length; ++j)
                    object.values[j] = $root.inz_types.InzInputValue.toObject(message.values[j], options);
            }
            if (message.readonly != null && message.hasOwnProperty("readonly"))
                object.readonly = message.readonly;
            if (message.repeated != null && message.hasOwnProperty("repeated"))
                object.repeated = message.repeated;
            return object;
        };

        /**
         * Converts this InzInput to JSON.
         * @function toJSON
         * @memberof inz_types.InzInput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InzInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InzInput;
    })();

    inz_types.GridRow = (function() {

        /**
         * Properties of a GridRow.
         * @memberof inz_types
         * @interface IGridRow
         * @property {string|null} [id] GridRow id
         * @property {Array.<inz_types.IGridValue>|null} [values] GridRow values
         */

        /**
         * Constructs a new GridRow.
         * @memberof inz_types
         * @classdesc Represents a GridRow.
         * @implements IGridRow
         * @constructor
         * @param {inz_types.IGridRow=} [properties] Properties to set
         */
        function GridRow(properties) {
            this.values = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GridRow id.
         * @member {string} id
         * @memberof inz_types.GridRow
         * @instance
         */
        GridRow.prototype.id = "";

        /**
         * GridRow values.
         * @member {Array.<inz_types.IGridValue>} values
         * @memberof inz_types.GridRow
         * @instance
         */
        GridRow.prototype.values = $util.emptyArray;

        /**
         * Creates a new GridRow instance using the specified properties.
         * @function create
         * @memberof inz_types.GridRow
         * @static
         * @param {inz_types.IGridRow=} [properties] Properties to set
         * @returns {inz_types.GridRow} GridRow instance
         */
        GridRow.create = function create(properties) {
            return new GridRow(properties);
        };

        /**
         * Encodes the specified GridRow message. Does not implicitly {@link inz_types.GridRow.verify|verify} messages.
         * @function encode
         * @memberof inz_types.GridRow
         * @static
         * @param {inz_types.IGridRow} message GridRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.values != null && message.values.length)
                for (let i = 0; i < message.values.length; ++i)
                    $root.inz_types.GridValue.encode(message.values[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GridRow message, length delimited. Does not implicitly {@link inz_types.GridRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_types.GridRow
         * @static
         * @param {inz_types.IGridRow} message GridRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridRow message from the specified reader or buffer.
         * @function decode
         * @memberof inz_types.GridRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_types.GridRow} GridRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_types.GridRow();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push($root.inz_types.GridValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_types.GridRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_types.GridRow} GridRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridRow message.
         * @function verify
         * @memberof inz_types.GridRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.values != null && message.hasOwnProperty("values")) {
                if (!Array.isArray(message.values))
                    return "values: array expected";
                for (let i = 0; i < message.values.length; ++i) {
                    let error = $root.inz_types.GridValue.verify(message.values[i]);
                    if (error)
                        return "values." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GridRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_types.GridRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_types.GridRow} GridRow
         */
        GridRow.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_types.GridRow)
                return object;
            let message = new $root.inz_types.GridRow();
            if (object.id != null)
                message.id = String(object.id);
            if (object.values) {
                if (!Array.isArray(object.values))
                    throw TypeError(".inz_types.GridRow.values: array expected");
                message.values = [];
                for (let i = 0; i < object.values.length; ++i) {
                    if (typeof object.values[i] !== "object")
                        throw TypeError(".inz_types.GridRow.values: object expected");
                    message.values[i] = $root.inz_types.GridValue.fromObject(object.values[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GridRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_types.GridRow
         * @static
         * @param {inz_types.GridRow} message GridRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.values = [];
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.values && message.values.length) {
                object.values = [];
                for (let j = 0; j < message.values.length; ++j)
                    object.values[j] = $root.inz_types.GridValue.toObject(message.values[j], options);
            }
            return object;
        };

        /**
         * Converts this GridRow to JSON.
         * @function toJSON
         * @memberof inz_types.GridRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GridRow;
    })();

    inz_types.GridValue = (function() {

        /**
         * Properties of a GridValue.
         * @memberof inz_types
         * @interface IGridValue
         * @property {string|null} [text] GridValue text
         */

        /**
         * Constructs a new GridValue.
         * @memberof inz_types
         * @classdesc Represents a GridValue.
         * @implements IGridValue
         * @constructor
         * @param {inz_types.IGridValue=} [properties] Properties to set
         */
        function GridValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GridValue text.
         * @member {string} text
         * @memberof inz_types.GridValue
         * @instance
         */
        GridValue.prototype.text = "";

        /**
         * Creates a new GridValue instance using the specified properties.
         * @function create
         * @memberof inz_types.GridValue
         * @static
         * @param {inz_types.IGridValue=} [properties] Properties to set
         * @returns {inz_types.GridValue} GridValue instance
         */
        GridValue.create = function create(properties) {
            return new GridValue(properties);
        };

        /**
         * Encodes the specified GridValue message. Does not implicitly {@link inz_types.GridValue.verify|verify} messages.
         * @function encode
         * @memberof inz_types.GridValue
         * @static
         * @param {inz_types.IGridValue} message GridValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
            return writer;
        };

        /**
         * Encodes the specified GridValue message, length delimited. Does not implicitly {@link inz_types.GridValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_types.GridValue
         * @static
         * @param {inz_types.IGridValue} message GridValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridValue message from the specified reader or buffer.
         * @function decode
         * @memberof inz_types.GridValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_types.GridValue} GridValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_types.GridValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_types.GridValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_types.GridValue} GridValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridValue message.
         * @function verify
         * @memberof inz_types.GridValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            return null;
        };

        /**
         * Creates a GridValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_types.GridValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_types.GridValue} GridValue
         */
        GridValue.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_types.GridValue)
                return object;
            let message = new $root.inz_types.GridValue();
            if (object.text != null)
                message.text = String(object.text);
            return message;
        };

        /**
         * Creates a plain object from a GridValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_types.GridValue
         * @static
         * @param {inz_types.GridValue} message GridValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.text = "";
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            return object;
        };

        /**
         * Converts this GridValue to JSON.
         * @function toJSON
         * @memberof inz_types.GridValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GridValue;
    })();

    inz_types.GridCol = (function() {

        /**
         * Properties of a GridCol.
         * @memberof inz_types
         * @interface IGridCol
         * @property {string|null} [label] GridCol label
         * @property {inz_types.Ordering|null} [ordering] GridCol ordering
         * @property {string|null} [span] GridCol span
         */

        /**
         * Constructs a new GridCol.
         * @memberof inz_types
         * @classdesc Represents a GridCol.
         * @implements IGridCol
         * @constructor
         * @param {inz_types.IGridCol=} [properties] Properties to set
         */
        function GridCol(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GridCol label.
         * @member {string} label
         * @memberof inz_types.GridCol
         * @instance
         */
        GridCol.prototype.label = "";

        /**
         * GridCol ordering.
         * @member {inz_types.Ordering} ordering
         * @memberof inz_types.GridCol
         * @instance
         */
        GridCol.prototype.ordering = 0;

        /**
         * GridCol span.
         * @member {string} span
         * @memberof inz_types.GridCol
         * @instance
         */
        GridCol.prototype.span = "";

        /**
         * Creates a new GridCol instance using the specified properties.
         * @function create
         * @memberof inz_types.GridCol
         * @static
         * @param {inz_types.IGridCol=} [properties] Properties to set
         * @returns {inz_types.GridCol} GridCol instance
         */
        GridCol.create = function create(properties) {
            return new GridCol(properties);
        };

        /**
         * Encodes the specified GridCol message. Does not implicitly {@link inz_types.GridCol.verify|verify} messages.
         * @function encode
         * @memberof inz_types.GridCol
         * @static
         * @param {inz_types.IGridCol} message GridCol message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridCol.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.ordering != null && Object.hasOwnProperty.call(message, "ordering"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ordering);
            if (message.span != null && Object.hasOwnProperty.call(message, "span"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.span);
            return writer;
        };

        /**
         * Encodes the specified GridCol message, length delimited. Does not implicitly {@link inz_types.GridCol.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_types.GridCol
         * @static
         * @param {inz_types.IGridCol} message GridCol message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridCol.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridCol message from the specified reader or buffer.
         * @function decode
         * @memberof inz_types.GridCol
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_types.GridCol} GridCol
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridCol.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_types.GridCol();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.label = reader.string();
                    break;
                case 2:
                    message.ordering = reader.int32();
                    break;
                case 3:
                    message.span = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridCol message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_types.GridCol
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_types.GridCol} GridCol
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridCol.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridCol message.
         * @function verify
         * @memberof inz_types.GridCol
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridCol.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.ordering != null && message.hasOwnProperty("ordering"))
                switch (message.ordering) {
                default:
                    return "ordering: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.span != null && message.hasOwnProperty("span"))
                if (!$util.isString(message.span))
                    return "span: string expected";
            return null;
        };

        /**
         * Creates a GridCol message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_types.GridCol
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_types.GridCol} GridCol
         */
        GridCol.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_types.GridCol)
                return object;
            let message = new $root.inz_types.GridCol();
            if (object.label != null)
                message.label = String(object.label);
            switch (object.ordering) {
            case "none":
            case 0:
                message.ordering = 0;
                break;
            case "ascending":
            case 1:
                message.ordering = 1;
                break;
            case "descending":
            case 2:
                message.ordering = 2;
                break;
            }
            if (object.span != null)
                message.span = String(object.span);
            return message;
        };

        /**
         * Creates a plain object from a GridCol message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_types.GridCol
         * @static
         * @param {inz_types.GridCol} message GridCol
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridCol.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.label = "";
                object.ordering = options.enums === String ? "none" : 0;
                object.span = "";
            }
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.ordering != null && message.hasOwnProperty("ordering"))
                object.ordering = options.enums === String ? $root.inz_types.Ordering[message.ordering] : message.ordering;
            if (message.span != null && message.hasOwnProperty("span"))
                object.span = message.span;
            return object;
        };

        /**
         * Converts this GridCol to JSON.
         * @function toJSON
         * @memberof inz_types.GridCol
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridCol.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GridCol;
    })();

    /**
     * InzInputType enum.
     * @name inz_types.InzInputType
     * @enum {number}
     * @property {number} text=0 text value
     * @property {number} number=1 number value
     * @property {number} date=2 date value
     * @property {number} free_select=3 free_select value
     * @property {number} fixed_select=4 fixed_select value
     * @property {number} check=5 check value
     */
    inz_types.InzInputType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "text"] = 0;
        values[valuesById[1] = "number"] = 1;
        values[valuesById[2] = "date"] = 2;
        values[valuesById[3] = "free_select"] = 3;
        values[valuesById[4] = "fixed_select"] = 4;
        values[valuesById[5] = "check"] = 5;
        return values;
    })();

    /**
     * Ordering enum.
     * @name inz_types.Ordering
     * @enum {number}
     * @property {number} none=0 none value
     * @property {number} ascending=1 ascending value
     * @property {number} descending=2 descending value
     */
    inz_types.Ordering = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "none"] = 0;
        values[valuesById[1] = "ascending"] = 1;
        values[valuesById[2] = "descending"] = 2;
        return values;
    })();

    /**
     * GTERelation enum.
     * @name inz_types.GTERelation
     * @enum {number}
     * @property {number} eq=0 eq value
     * @property {number} gt=1 gt value
     * @property {number} lt=2 lt value
     * @property {number} gte=3 gte value
     * @property {number} lte=4 lte value
     */
    inz_types.GTERelation = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "eq"] = 0;
        values[valuesById[1] = "gt"] = 1;
        values[valuesById[2] = "lt"] = 2;
        values[valuesById[3] = "gte"] = 3;
        values[valuesById[4] = "lte"] = 4;
        return values;
    })();

    return inz_types;
})();

export const inz_zrtve_inputs = $root.inz_zrtve_inputs = (() => {

    /**
     * Namespace inz_zrtve_inputs.
     * @exports inz_zrtve_inputs
     * @namespace
     */
    const inz_zrtve_inputs = {};

    inz_zrtve_inputs.LoadZrtevInputs = (function() {

        /**
         * Properties of a LoadZrtevInputs.
         * @memberof inz_zrtve_inputs
         * @interface ILoadZrtevInputs
         * @property {string|null} [id] LoadZrtevInputs id
         */

        /**
         * Constructs a new LoadZrtevInputs.
         * @memberof inz_zrtve_inputs
         * @classdesc Represents a LoadZrtevInputs.
         * @implements ILoadZrtevInputs
         * @constructor
         * @param {inz_zrtve_inputs.ILoadZrtevInputs=} [properties] Properties to set
         */
        function LoadZrtevInputs(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadZrtevInputs id.
         * @member {string} id
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @instance
         */
        LoadZrtevInputs.prototype.id = "";

        /**
         * Creates a new LoadZrtevInputs instance using the specified properties.
         * @function create
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.ILoadZrtevInputs=} [properties] Properties to set
         * @returns {inz_zrtve_inputs.LoadZrtevInputs} LoadZrtevInputs instance
         */
        LoadZrtevInputs.create = function create(properties) {
            return new LoadZrtevInputs(properties);
        };

        /**
         * Encodes the specified LoadZrtevInputs message. Does not implicitly {@link inz_zrtve_inputs.LoadZrtevInputs.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.ILoadZrtevInputs} message LoadZrtevInputs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadZrtevInputs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified LoadZrtevInputs message, length delimited. Does not implicitly {@link inz_zrtve_inputs.LoadZrtevInputs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.ILoadZrtevInputs} message LoadZrtevInputs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadZrtevInputs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadZrtevInputs message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve_inputs.LoadZrtevInputs} LoadZrtevInputs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadZrtevInputs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve_inputs.LoadZrtevInputs();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadZrtevInputs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve_inputs.LoadZrtevInputs} LoadZrtevInputs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadZrtevInputs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadZrtevInputs message.
         * @function verify
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadZrtevInputs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a LoadZrtevInputs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve_inputs.LoadZrtevInputs} LoadZrtevInputs
         */
        LoadZrtevInputs.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve_inputs.LoadZrtevInputs)
                return object;
            let message = new $root.inz_zrtve_inputs.LoadZrtevInputs();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a LoadZrtevInputs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.LoadZrtevInputs} message LoadZrtevInputs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadZrtevInputs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this LoadZrtevInputs to JSON.
         * @function toJSON
         * @memberof inz_zrtve_inputs.LoadZrtevInputs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadZrtevInputs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadZrtevInputs;
    })();

    inz_zrtve_inputs.SaveZrtevInputs = (function() {

        /**
         * Properties of a SaveZrtevInputs.
         * @memberof inz_zrtve_inputs
         * @interface ISaveZrtevInputs
         * @property {inz_zrtve_inputs.IZrtevInputs|null} [inputs] SaveZrtevInputs inputs
         */

        /**
         * Constructs a new SaveZrtevInputs.
         * @memberof inz_zrtve_inputs
         * @classdesc Represents a SaveZrtevInputs.
         * @implements ISaveZrtevInputs
         * @constructor
         * @param {inz_zrtve_inputs.ISaveZrtevInputs=} [properties] Properties to set
         */
        function SaveZrtevInputs(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveZrtevInputs inputs.
         * @member {inz_zrtve_inputs.IZrtevInputs|null|undefined} inputs
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @instance
         */
        SaveZrtevInputs.prototype.inputs = null;

        /**
         * Creates a new SaveZrtevInputs instance using the specified properties.
         * @function create
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.ISaveZrtevInputs=} [properties] Properties to set
         * @returns {inz_zrtve_inputs.SaveZrtevInputs} SaveZrtevInputs instance
         */
        SaveZrtevInputs.create = function create(properties) {
            return new SaveZrtevInputs(properties);
        };

        /**
         * Encodes the specified SaveZrtevInputs message. Does not implicitly {@link inz_zrtve_inputs.SaveZrtevInputs.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.ISaveZrtevInputs} message SaveZrtevInputs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveZrtevInputs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.inputs != null && Object.hasOwnProperty.call(message, "inputs"))
                $root.inz_zrtve_inputs.ZrtevInputs.encode(message.inputs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SaveZrtevInputs message, length delimited. Does not implicitly {@link inz_zrtve_inputs.SaveZrtevInputs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.ISaveZrtevInputs} message SaveZrtevInputs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveZrtevInputs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveZrtevInputs message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve_inputs.SaveZrtevInputs} SaveZrtevInputs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveZrtevInputs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve_inputs.SaveZrtevInputs();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.inputs = $root.inz_zrtve_inputs.ZrtevInputs.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveZrtevInputs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve_inputs.SaveZrtevInputs} SaveZrtevInputs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveZrtevInputs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveZrtevInputs message.
         * @function verify
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveZrtevInputs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.inputs != null && message.hasOwnProperty("inputs")) {
                let error = $root.inz_zrtve_inputs.ZrtevInputs.verify(message.inputs);
                if (error)
                    return "inputs." + error;
            }
            return null;
        };

        /**
         * Creates a SaveZrtevInputs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve_inputs.SaveZrtevInputs} SaveZrtevInputs
         */
        SaveZrtevInputs.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve_inputs.SaveZrtevInputs)
                return object;
            let message = new $root.inz_zrtve_inputs.SaveZrtevInputs();
            if (object.inputs != null) {
                if (typeof object.inputs !== "object")
                    throw TypeError(".inz_zrtve_inputs.SaveZrtevInputs.inputs: object expected");
                message.inputs = $root.inz_zrtve_inputs.ZrtevInputs.fromObject(object.inputs);
            }
            return message;
        };

        /**
         * Creates a plain object from a SaveZrtevInputs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.SaveZrtevInputs} message SaveZrtevInputs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveZrtevInputs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.inputs = null;
            if (message.inputs != null && message.hasOwnProperty("inputs"))
                object.inputs = $root.inz_zrtve_inputs.ZrtevInputs.toObject(message.inputs, options);
            return object;
        };

        /**
         * Converts this SaveZrtevInputs to JSON.
         * @function toJSON
         * @memberof inz_zrtve_inputs.SaveZrtevInputs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveZrtevInputs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SaveZrtevInputs;
    })();

    inz_zrtve_inputs.ZrtevInputs = (function() {

        /**
         * Properties of a ZrtevInputs.
         * @memberof inz_zrtve_inputs
         * @interface IZrtevInputs
         * @property {string|null} [id] ZrtevInputs id
         * @property {Array.<inz_types.IInzInput>|null} [osebniPodatki] ZrtevInputs osebniPodatki
         * @property {Array.<inz_types.IInzInput>|null} [podatkiOSmrti] ZrtevInputs podatkiOSmrti
         * @property {inz_types.IInzInput|null} [opombe] ZrtevInputs opombe
         * @property {inz_types.IInzInput|null} [viri] ZrtevInputs viri
         */

        /**
         * Constructs a new ZrtevInputs.
         * @memberof inz_zrtve_inputs
         * @classdesc Represents a ZrtevInputs.
         * @implements IZrtevInputs
         * @constructor
         * @param {inz_zrtve_inputs.IZrtevInputs=} [properties] Properties to set
         */
        function ZrtevInputs(properties) {
            this.osebniPodatki = [];
            this.podatkiOSmrti = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZrtevInputs id.
         * @member {string} id
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @instance
         */
        ZrtevInputs.prototype.id = "";

        /**
         * ZrtevInputs osebniPodatki.
         * @member {Array.<inz_types.IInzInput>} osebniPodatki
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @instance
         */
        ZrtevInputs.prototype.osebniPodatki = $util.emptyArray;

        /**
         * ZrtevInputs podatkiOSmrti.
         * @member {Array.<inz_types.IInzInput>} podatkiOSmrti
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @instance
         */
        ZrtevInputs.prototype.podatkiOSmrti = $util.emptyArray;

        /**
         * ZrtevInputs opombe.
         * @member {inz_types.IInzInput|null|undefined} opombe
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @instance
         */
        ZrtevInputs.prototype.opombe = null;

        /**
         * ZrtevInputs viri.
         * @member {inz_types.IInzInput|null|undefined} viri
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @instance
         */
        ZrtevInputs.prototype.viri = null;

        /**
         * Creates a new ZrtevInputs instance using the specified properties.
         * @function create
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.IZrtevInputs=} [properties] Properties to set
         * @returns {inz_zrtve_inputs.ZrtevInputs} ZrtevInputs instance
         */
        ZrtevInputs.create = function create(properties) {
            return new ZrtevInputs(properties);
        };

        /**
         * Encodes the specified ZrtevInputs message. Does not implicitly {@link inz_zrtve_inputs.ZrtevInputs.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.IZrtevInputs} message ZrtevInputs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZrtevInputs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.osebniPodatki != null && message.osebniPodatki.length)
                for (let i = 0; i < message.osebniPodatki.length; ++i)
                    $root.inz_types.InzInput.encode(message.osebniPodatki[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.podatkiOSmrti != null && message.podatkiOSmrti.length)
                for (let i = 0; i < message.podatkiOSmrti.length; ++i)
                    $root.inz_types.InzInput.encode(message.podatkiOSmrti[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.opombe != null && Object.hasOwnProperty.call(message, "opombe"))
                $root.inz_types.InzInput.encode(message.opombe, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.viri != null && Object.hasOwnProperty.call(message, "viri"))
                $root.inz_types.InzInput.encode(message.viri, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZrtevInputs message, length delimited. Does not implicitly {@link inz_zrtve_inputs.ZrtevInputs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.IZrtevInputs} message ZrtevInputs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZrtevInputs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZrtevInputs message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve_inputs.ZrtevInputs} ZrtevInputs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZrtevInputs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve_inputs.ZrtevInputs();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    if (!(message.osebniPodatki && message.osebniPodatki.length))
                        message.osebniPodatki = [];
                    message.osebniPodatki.push($root.inz_types.InzInput.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if (!(message.podatkiOSmrti && message.podatkiOSmrti.length))
                        message.podatkiOSmrti = [];
                    message.podatkiOSmrti.push($root.inz_types.InzInput.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.opombe = $root.inz_types.InzInput.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.viri = $root.inz_types.InzInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZrtevInputs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve_inputs.ZrtevInputs} ZrtevInputs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZrtevInputs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZrtevInputs message.
         * @function verify
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZrtevInputs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.osebniPodatki != null && message.hasOwnProperty("osebniPodatki")) {
                if (!Array.isArray(message.osebniPodatki))
                    return "osebniPodatki: array expected";
                for (let i = 0; i < message.osebniPodatki.length; ++i) {
                    let error = $root.inz_types.InzInput.verify(message.osebniPodatki[i]);
                    if (error)
                        return "osebniPodatki." + error;
                }
            }
            if (message.podatkiOSmrti != null && message.hasOwnProperty("podatkiOSmrti")) {
                if (!Array.isArray(message.podatkiOSmrti))
                    return "podatkiOSmrti: array expected";
                for (let i = 0; i < message.podatkiOSmrti.length; ++i) {
                    let error = $root.inz_types.InzInput.verify(message.podatkiOSmrti[i]);
                    if (error)
                        return "podatkiOSmrti." + error;
                }
            }
            if (message.opombe != null && message.hasOwnProperty("opombe")) {
                let error = $root.inz_types.InzInput.verify(message.opombe);
                if (error)
                    return "opombe." + error;
            }
            if (message.viri != null && message.hasOwnProperty("viri")) {
                let error = $root.inz_types.InzInput.verify(message.viri);
                if (error)
                    return "viri." + error;
            }
            return null;
        };

        /**
         * Creates a ZrtevInputs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve_inputs.ZrtevInputs} ZrtevInputs
         */
        ZrtevInputs.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve_inputs.ZrtevInputs)
                return object;
            let message = new $root.inz_zrtve_inputs.ZrtevInputs();
            if (object.id != null)
                message.id = String(object.id);
            if (object.osebniPodatki) {
                if (!Array.isArray(object.osebniPodatki))
                    throw TypeError(".inz_zrtve_inputs.ZrtevInputs.osebniPodatki: array expected");
                message.osebniPodatki = [];
                for (let i = 0; i < object.osebniPodatki.length; ++i) {
                    if (typeof object.osebniPodatki[i] !== "object")
                        throw TypeError(".inz_zrtve_inputs.ZrtevInputs.osebniPodatki: object expected");
                    message.osebniPodatki[i] = $root.inz_types.InzInput.fromObject(object.osebniPodatki[i]);
                }
            }
            if (object.podatkiOSmrti) {
                if (!Array.isArray(object.podatkiOSmrti))
                    throw TypeError(".inz_zrtve_inputs.ZrtevInputs.podatkiOSmrti: array expected");
                message.podatkiOSmrti = [];
                for (let i = 0; i < object.podatkiOSmrti.length; ++i) {
                    if (typeof object.podatkiOSmrti[i] !== "object")
                        throw TypeError(".inz_zrtve_inputs.ZrtevInputs.podatkiOSmrti: object expected");
                    message.podatkiOSmrti[i] = $root.inz_types.InzInput.fromObject(object.podatkiOSmrti[i]);
                }
            }
            if (object.opombe != null) {
                if (typeof object.opombe !== "object")
                    throw TypeError(".inz_zrtve_inputs.ZrtevInputs.opombe: object expected");
                message.opombe = $root.inz_types.InzInput.fromObject(object.opombe);
            }
            if (object.viri != null) {
                if (typeof object.viri !== "object")
                    throw TypeError(".inz_zrtve_inputs.ZrtevInputs.viri: object expected");
                message.viri = $root.inz_types.InzInput.fromObject(object.viri);
            }
            return message;
        };

        /**
         * Creates a plain object from a ZrtevInputs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @static
         * @param {inz_zrtve_inputs.ZrtevInputs} message ZrtevInputs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZrtevInputs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.osebniPodatki = [];
                object.podatkiOSmrti = [];
            }
            if (options.defaults) {
                object.id = "";
                object.opombe = null;
                object.viri = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.osebniPodatki && message.osebniPodatki.length) {
                object.osebniPodatki = [];
                for (let j = 0; j < message.osebniPodatki.length; ++j)
                    object.osebniPodatki[j] = $root.inz_types.InzInput.toObject(message.osebniPodatki[j], options);
            }
            if (message.podatkiOSmrti && message.podatkiOSmrti.length) {
                object.podatkiOSmrti = [];
                for (let j = 0; j < message.podatkiOSmrti.length; ++j)
                    object.podatkiOSmrti[j] = $root.inz_types.InzInput.toObject(message.podatkiOSmrti[j], options);
            }
            if (message.opombe != null && message.hasOwnProperty("opombe"))
                object.opombe = $root.inz_types.InzInput.toObject(message.opombe, options);
            if (message.viri != null && message.hasOwnProperty("viri"))
                object.viri = $root.inz_types.InzInput.toObject(message.viri, options);
            return object;
        };

        /**
         * Converts this ZrtevInputs to JSON.
         * @function toJSON
         * @memberof inz_zrtve_inputs.ZrtevInputs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZrtevInputs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ZrtevInputs;
    })();

    inz_zrtve_inputs.LoadSelectOptions = (function() {

        /**
         * Properties of a LoadSelectOptions.
         * @memberof inz_zrtve_inputs
         * @interface ILoadSelectOptions
         * @property {string|null} [esName] LoadSelectOptions esName
         * @property {string|null} [search] LoadSelectOptions search
         */

        /**
         * Constructs a new LoadSelectOptions.
         * @memberof inz_zrtve_inputs
         * @classdesc Represents a LoadSelectOptions.
         * @implements ILoadSelectOptions
         * @constructor
         * @param {inz_zrtve_inputs.ILoadSelectOptions=} [properties] Properties to set
         */
        function LoadSelectOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadSelectOptions esName.
         * @member {string} esName
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @instance
         */
        LoadSelectOptions.prototype.esName = "";

        /**
         * LoadSelectOptions search.
         * @member {string} search
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @instance
         */
        LoadSelectOptions.prototype.search = "";

        /**
         * Creates a new LoadSelectOptions instance using the specified properties.
         * @function create
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @static
         * @param {inz_zrtve_inputs.ILoadSelectOptions=} [properties] Properties to set
         * @returns {inz_zrtve_inputs.LoadSelectOptions} LoadSelectOptions instance
         */
        LoadSelectOptions.create = function create(properties) {
            return new LoadSelectOptions(properties);
        };

        /**
         * Encodes the specified LoadSelectOptions message. Does not implicitly {@link inz_zrtve_inputs.LoadSelectOptions.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @static
         * @param {inz_zrtve_inputs.ILoadSelectOptions} message LoadSelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadSelectOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.esName != null && Object.hasOwnProperty.call(message, "esName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.esName);
            if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.search);
            return writer;
        };

        /**
         * Encodes the specified LoadSelectOptions message, length delimited. Does not implicitly {@link inz_zrtve_inputs.LoadSelectOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @static
         * @param {inz_zrtve_inputs.ILoadSelectOptions} message LoadSelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadSelectOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadSelectOptions message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve_inputs.LoadSelectOptions} LoadSelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadSelectOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve_inputs.LoadSelectOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.esName = reader.string();
                    break;
                case 2:
                    message.search = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadSelectOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve_inputs.LoadSelectOptions} LoadSelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadSelectOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadSelectOptions message.
         * @function verify
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadSelectOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.esName != null && message.hasOwnProperty("esName"))
                if (!$util.isString(message.esName))
                    return "esName: string expected";
            if (message.search != null && message.hasOwnProperty("search"))
                if (!$util.isString(message.search))
                    return "search: string expected";
            return null;
        };

        /**
         * Creates a LoadSelectOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve_inputs.LoadSelectOptions} LoadSelectOptions
         */
        LoadSelectOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve_inputs.LoadSelectOptions)
                return object;
            let message = new $root.inz_zrtve_inputs.LoadSelectOptions();
            if (object.esName != null)
                message.esName = String(object.esName);
            if (object.search != null)
                message.search = String(object.search);
            return message;
        };

        /**
         * Creates a plain object from a LoadSelectOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @static
         * @param {inz_zrtve_inputs.LoadSelectOptions} message LoadSelectOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadSelectOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.esName = "";
                object.search = "";
            }
            if (message.esName != null && message.hasOwnProperty("esName"))
                object.esName = message.esName;
            if (message.search != null && message.hasOwnProperty("search"))
                object.search = message.search;
            return object;
        };

        /**
         * Converts this LoadSelectOptions to JSON.
         * @function toJSON
         * @memberof inz_zrtve_inputs.LoadSelectOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadSelectOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadSelectOptions;
    })();

    inz_zrtve_inputs.SelectOptions = (function() {

        /**
         * Properties of a SelectOptions.
         * @memberof inz_zrtve_inputs
         * @interface ISelectOptions
         * @property {string|null} [esName] SelectOptions esName
         * @property {string|null} [search] SelectOptions search
         * @property {Array.<string>|null} [options] SelectOptions options
         * @property {boolean|null} [isAll] SelectOptions isAll
         */

        /**
         * Constructs a new SelectOptions.
         * @memberof inz_zrtve_inputs
         * @classdesc Represents a SelectOptions.
         * @implements ISelectOptions
         * @constructor
         * @param {inz_zrtve_inputs.ISelectOptions=} [properties] Properties to set
         */
        function SelectOptions(properties) {
            this.options = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SelectOptions esName.
         * @member {string} esName
         * @memberof inz_zrtve_inputs.SelectOptions
         * @instance
         */
        SelectOptions.prototype.esName = "";

        /**
         * SelectOptions search.
         * @member {string} search
         * @memberof inz_zrtve_inputs.SelectOptions
         * @instance
         */
        SelectOptions.prototype.search = "";

        /**
         * SelectOptions options.
         * @member {Array.<string>} options
         * @memberof inz_zrtve_inputs.SelectOptions
         * @instance
         */
        SelectOptions.prototype.options = $util.emptyArray;

        /**
         * SelectOptions isAll.
         * @member {boolean} isAll
         * @memberof inz_zrtve_inputs.SelectOptions
         * @instance
         */
        SelectOptions.prototype.isAll = false;

        /**
         * Creates a new SelectOptions instance using the specified properties.
         * @function create
         * @memberof inz_zrtve_inputs.SelectOptions
         * @static
         * @param {inz_zrtve_inputs.ISelectOptions=} [properties] Properties to set
         * @returns {inz_zrtve_inputs.SelectOptions} SelectOptions instance
         */
        SelectOptions.create = function create(properties) {
            return new SelectOptions(properties);
        };

        /**
         * Encodes the specified SelectOptions message. Does not implicitly {@link inz_zrtve_inputs.SelectOptions.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve_inputs.SelectOptions
         * @static
         * @param {inz_zrtve_inputs.ISelectOptions} message SelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.esName != null && Object.hasOwnProperty.call(message, "esName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.esName);
            if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.search);
            if (message.options != null && message.options.length)
                for (let i = 0; i < message.options.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.options[i]);
            if (message.isAll != null && Object.hasOwnProperty.call(message, "isAll"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isAll);
            return writer;
        };

        /**
         * Encodes the specified SelectOptions message, length delimited. Does not implicitly {@link inz_zrtve_inputs.SelectOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve_inputs.SelectOptions
         * @static
         * @param {inz_zrtve_inputs.ISelectOptions} message SelectOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SelectOptions message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve_inputs.SelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve_inputs.SelectOptions} SelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve_inputs.SelectOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.esName = reader.string();
                    break;
                case 2:
                    message.search = reader.string();
                    break;
                case 3:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push(reader.string());
                    break;
                case 4:
                    message.isAll = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SelectOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve_inputs.SelectOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve_inputs.SelectOptions} SelectOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SelectOptions message.
         * @function verify
         * @memberof inz_zrtve_inputs.SelectOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SelectOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.esName != null && message.hasOwnProperty("esName"))
                if (!$util.isString(message.esName))
                    return "esName: string expected";
            if (message.search != null && message.hasOwnProperty("search"))
                if (!$util.isString(message.search))
                    return "search: string expected";
            if (message.options != null && message.hasOwnProperty("options")) {
                if (!Array.isArray(message.options))
                    return "options: array expected";
                for (let i = 0; i < message.options.length; ++i)
                    if (!$util.isString(message.options[i]))
                        return "options: string[] expected";
            }
            if (message.isAll != null && message.hasOwnProperty("isAll"))
                if (typeof message.isAll !== "boolean")
                    return "isAll: boolean expected";
            return null;
        };

        /**
         * Creates a SelectOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve_inputs.SelectOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve_inputs.SelectOptions} SelectOptions
         */
        SelectOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve_inputs.SelectOptions)
                return object;
            let message = new $root.inz_zrtve_inputs.SelectOptions();
            if (object.esName != null)
                message.esName = String(object.esName);
            if (object.search != null)
                message.search = String(object.search);
            if (object.options) {
                if (!Array.isArray(object.options))
                    throw TypeError(".inz_zrtve_inputs.SelectOptions.options: array expected");
                message.options = [];
                for (let i = 0; i < object.options.length; ++i)
                    message.options[i] = String(object.options[i]);
            }
            if (object.isAll != null)
                message.isAll = Boolean(object.isAll);
            return message;
        };

        /**
         * Creates a plain object from a SelectOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve_inputs.SelectOptions
         * @static
         * @param {inz_zrtve_inputs.SelectOptions} message SelectOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SelectOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.options = [];
            if (options.defaults) {
                object.esName = "";
                object.search = "";
                object.isAll = false;
            }
            if (message.esName != null && message.hasOwnProperty("esName"))
                object.esName = message.esName;
            if (message.search != null && message.hasOwnProperty("search"))
                object.search = message.search;
            if (message.options && message.options.length) {
                object.options = [];
                for (let j = 0; j < message.options.length; ++j)
                    object.options[j] = message.options[j];
            }
            if (message.isAll != null && message.hasOwnProperty("isAll"))
                object.isAll = message.isAll;
            return object;
        };

        /**
         * Converts this SelectOptions to JSON.
         * @function toJSON
         * @memberof inz_zrtve_inputs.SelectOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SelectOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SelectOptions;
    })();

    return inz_zrtve_inputs;
})();

export const inz_zrtve = $root.inz_zrtve = (() => {

    /**
     * Namespace inz_zrtve.
     * @exports inz_zrtve
     * @namespace
     */
    const inz_zrtve = {};

    inz_zrtve.ProfileData = (function() {

        /**
         * Properties of a ProfileData.
         * @memberof inz_zrtve
         * @interface IProfileData
         * @property {string|null} [name] ProfileData name
         */

        /**
         * Constructs a new ProfileData.
         * @memberof inz_zrtve
         * @classdesc Represents a ProfileData.
         * @implements IProfileData
         * @constructor
         * @param {inz_zrtve.IProfileData=} [properties] Properties to set
         */
        function ProfileData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProfileData name.
         * @member {string} name
         * @memberof inz_zrtve.ProfileData
         * @instance
         */
        ProfileData.prototype.name = "";

        /**
         * Creates a new ProfileData instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.ProfileData
         * @static
         * @param {inz_zrtve.IProfileData=} [properties] Properties to set
         * @returns {inz_zrtve.ProfileData} ProfileData instance
         */
        ProfileData.create = function create(properties) {
            return new ProfileData(properties);
        };

        /**
         * Encodes the specified ProfileData message. Does not implicitly {@link inz_zrtve.ProfileData.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.ProfileData
         * @static
         * @param {inz_zrtve.IProfileData} message ProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified ProfileData message, length delimited. Does not implicitly {@link inz_zrtve.ProfileData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.ProfileData
         * @static
         * @param {inz_zrtve.IProfileData} message ProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProfileData message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.ProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.ProfileData} ProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.ProfileData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProfileData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.ProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.ProfileData} ProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProfileData message.
         * @function verify
         * @memberof inz_zrtve.ProfileData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProfileData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a ProfileData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.ProfileData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.ProfileData} ProfileData
         */
        ProfileData.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.ProfileData)
                return object;
            let message = new $root.inz_zrtve.ProfileData();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a ProfileData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.ProfileData
         * @static
         * @param {inz_zrtve.ProfileData} message ProfileData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfileData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this ProfileData to JSON.
         * @function toJSON
         * @memberof inz_zrtve.ProfileData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfileData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProfileData;
    })();

    inz_zrtve.LoadZrtve = (function() {

        /**
         * Properties of a LoadZrtve.
         * @memberof inz_zrtve
         * @interface ILoadZrtve
         * @property {number|null} [page] LoadZrtve page
         * @property {inz_zrtve.IZrtveFilters|null} [filters] LoadZrtve filters
         */

        /**
         * Constructs a new LoadZrtve.
         * @memberof inz_zrtve
         * @classdesc Represents a LoadZrtve.
         * @implements ILoadZrtve
         * @constructor
         * @param {inz_zrtve.ILoadZrtve=} [properties] Properties to set
         */
        function LoadZrtve(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadZrtve page.
         * @member {number} page
         * @memberof inz_zrtve.LoadZrtve
         * @instance
         */
        LoadZrtve.prototype.page = 0;

        /**
         * LoadZrtve filters.
         * @member {inz_zrtve.IZrtveFilters|null|undefined} filters
         * @memberof inz_zrtve.LoadZrtve
         * @instance
         */
        LoadZrtve.prototype.filters = null;

        /**
         * Creates a new LoadZrtve instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.LoadZrtve
         * @static
         * @param {inz_zrtve.ILoadZrtve=} [properties] Properties to set
         * @returns {inz_zrtve.LoadZrtve} LoadZrtve instance
         */
        LoadZrtve.create = function create(properties) {
            return new LoadZrtve(properties);
        };

        /**
         * Encodes the specified LoadZrtve message. Does not implicitly {@link inz_zrtve.LoadZrtve.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.LoadZrtve
         * @static
         * @param {inz_zrtve.ILoadZrtve} message LoadZrtve message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadZrtve.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.filters != null && Object.hasOwnProperty.call(message, "filters"))
                $root.inz_zrtve.ZrtveFilters.encode(message.filters, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LoadZrtve message, length delimited. Does not implicitly {@link inz_zrtve.LoadZrtve.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.LoadZrtve
         * @static
         * @param {inz_zrtve.ILoadZrtve} message LoadZrtve message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadZrtve.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadZrtve message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.LoadZrtve
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.LoadZrtve} LoadZrtve
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadZrtve.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.LoadZrtve();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.filters = $root.inz_zrtve.ZrtveFilters.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadZrtve message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.LoadZrtve
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.LoadZrtve} LoadZrtve
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadZrtve.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadZrtve message.
         * @function verify
         * @memberof inz_zrtve.LoadZrtve
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadZrtve.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.page != null && message.hasOwnProperty("page"))
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            if (message.filters != null && message.hasOwnProperty("filters")) {
                let error = $root.inz_zrtve.ZrtveFilters.verify(message.filters);
                if (error)
                    return "filters." + error;
            }
            return null;
        };

        /**
         * Creates a LoadZrtve message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.LoadZrtve
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.LoadZrtve} LoadZrtve
         */
        LoadZrtve.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.LoadZrtve)
                return object;
            let message = new $root.inz_zrtve.LoadZrtve();
            if (object.page != null)
                message.page = object.page | 0;
            if (object.filters != null) {
                if (typeof object.filters !== "object")
                    throw TypeError(".inz_zrtve.LoadZrtve.filters: object expected");
                message.filters = $root.inz_zrtve.ZrtveFilters.fromObject(object.filters);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoadZrtve message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.LoadZrtve
         * @static
         * @param {inz_zrtve.LoadZrtve} message LoadZrtve
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadZrtve.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.page = 0;
                object.filters = null;
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = message.page;
            if (message.filters != null && message.hasOwnProperty("filters"))
                object.filters = $root.inz_zrtve.ZrtveFilters.toObject(message.filters, options);
            return object;
        };

        /**
         * Converts this LoadZrtve to JSON.
         * @function toJSON
         * @memberof inz_zrtve.LoadZrtve
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadZrtve.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadZrtve;
    })();

    inz_zrtve.Zrtve = (function() {

        /**
         * Properties of a Zrtve.
         * @memberof inz_zrtve
         * @interface IZrtve
         * @property {Array.<inz_types.IGridCol>|null} [columns] Zrtve columns
         * @property {Array.<inz_types.IGridRow>|null} [rows] Zrtve rows
         * @property {number|null} [nPages] Zrtve nPages
         * @property {number|null} [page] Zrtve page
         * @property {number|null} [hitCount] Zrtve hitCount
         */

        /**
         * Constructs a new Zrtve.
         * @memberof inz_zrtve
         * @classdesc Represents a Zrtve.
         * @implements IZrtve
         * @constructor
         * @param {inz_zrtve.IZrtve=} [properties] Properties to set
         */
        function Zrtve(properties) {
            this.columns = [];
            this.rows = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Zrtve columns.
         * @member {Array.<inz_types.IGridCol>} columns
         * @memberof inz_zrtve.Zrtve
         * @instance
         */
        Zrtve.prototype.columns = $util.emptyArray;

        /**
         * Zrtve rows.
         * @member {Array.<inz_types.IGridRow>} rows
         * @memberof inz_zrtve.Zrtve
         * @instance
         */
        Zrtve.prototype.rows = $util.emptyArray;

        /**
         * Zrtve nPages.
         * @member {number} nPages
         * @memberof inz_zrtve.Zrtve
         * @instance
         */
        Zrtve.prototype.nPages = 0;

        /**
         * Zrtve page.
         * @member {number} page
         * @memberof inz_zrtve.Zrtve
         * @instance
         */
        Zrtve.prototype.page = 0;

        /**
         * Zrtve hitCount.
         * @member {number} hitCount
         * @memberof inz_zrtve.Zrtve
         * @instance
         */
        Zrtve.prototype.hitCount = 0;

        /**
         * Creates a new Zrtve instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.Zrtve
         * @static
         * @param {inz_zrtve.IZrtve=} [properties] Properties to set
         * @returns {inz_zrtve.Zrtve} Zrtve instance
         */
        Zrtve.create = function create(properties) {
            return new Zrtve(properties);
        };

        /**
         * Encodes the specified Zrtve message. Does not implicitly {@link inz_zrtve.Zrtve.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.Zrtve
         * @static
         * @param {inz_zrtve.IZrtve} message Zrtve message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Zrtve.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.columns != null && message.columns.length)
                for (let i = 0; i < message.columns.length; ++i)
                    $root.inz_types.GridCol.encode(message.columns[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.rows != null && message.rows.length)
                for (let i = 0; i < message.rows.length; ++i)
                    $root.inz_types.GridRow.encode(message.rows[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.nPages != null && Object.hasOwnProperty.call(message, "nPages"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.nPages);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.page);
            if (message.hitCount != null && Object.hasOwnProperty.call(message, "hitCount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.hitCount);
            return writer;
        };

        /**
         * Encodes the specified Zrtve message, length delimited. Does not implicitly {@link inz_zrtve.Zrtve.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.Zrtve
         * @static
         * @param {inz_zrtve.IZrtve} message Zrtve message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Zrtve.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Zrtve message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.Zrtve
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.Zrtve} Zrtve
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Zrtve.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.Zrtve();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.columns && message.columns.length))
                        message.columns = [];
                    message.columns.push($root.inz_types.GridCol.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.rows && message.rows.length))
                        message.rows = [];
                    message.rows.push($root.inz_types.GridRow.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.nPages = reader.int32();
                    break;
                case 4:
                    message.page = reader.int32();
                    break;
                case 5:
                    message.hitCount = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Zrtve message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.Zrtve
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.Zrtve} Zrtve
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Zrtve.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Zrtve message.
         * @function verify
         * @memberof inz_zrtve.Zrtve
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Zrtve.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.columns != null && message.hasOwnProperty("columns")) {
                if (!Array.isArray(message.columns))
                    return "columns: array expected";
                for (let i = 0; i < message.columns.length; ++i) {
                    let error = $root.inz_types.GridCol.verify(message.columns[i]);
                    if (error)
                        return "columns." + error;
                }
            }
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (let i = 0; i < message.rows.length; ++i) {
                    let error = $root.inz_types.GridRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            if (message.nPages != null && message.hasOwnProperty("nPages"))
                if (!$util.isInteger(message.nPages))
                    return "nPages: integer expected";
            if (message.page != null && message.hasOwnProperty("page"))
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            if (message.hitCount != null && message.hasOwnProperty("hitCount"))
                if (!$util.isInteger(message.hitCount))
                    return "hitCount: integer expected";
            return null;
        };

        /**
         * Creates a Zrtve message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.Zrtve
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.Zrtve} Zrtve
         */
        Zrtve.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.Zrtve)
                return object;
            let message = new $root.inz_zrtve.Zrtve();
            if (object.columns) {
                if (!Array.isArray(object.columns))
                    throw TypeError(".inz_zrtve.Zrtve.columns: array expected");
                message.columns = [];
                for (let i = 0; i < object.columns.length; ++i) {
                    if (typeof object.columns[i] !== "object")
                        throw TypeError(".inz_zrtve.Zrtve.columns: object expected");
                    message.columns[i] = $root.inz_types.GridCol.fromObject(object.columns[i]);
                }
            }
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".inz_zrtve.Zrtve.rows: array expected");
                message.rows = [];
                for (let i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".inz_zrtve.Zrtve.rows: object expected");
                    message.rows[i] = $root.inz_types.GridRow.fromObject(object.rows[i]);
                }
            }
            if (object.nPages != null)
                message.nPages = object.nPages | 0;
            if (object.page != null)
                message.page = object.page | 0;
            if (object.hitCount != null)
                message.hitCount = object.hitCount | 0;
            return message;
        };

        /**
         * Creates a plain object from a Zrtve message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.Zrtve
         * @static
         * @param {inz_zrtve.Zrtve} message Zrtve
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Zrtve.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.columns = [];
                object.rows = [];
            }
            if (options.defaults) {
                object.nPages = 0;
                object.page = 0;
                object.hitCount = 0;
            }
            if (message.columns && message.columns.length) {
                object.columns = [];
                for (let j = 0; j < message.columns.length; ++j)
                    object.columns[j] = $root.inz_types.GridCol.toObject(message.columns[j], options);
            }
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (let j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.inz_types.GridRow.toObject(message.rows[j], options);
            }
            if (message.nPages != null && message.hasOwnProperty("nPages"))
                object.nPages = message.nPages;
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = message.page;
            if (message.hitCount != null && message.hasOwnProperty("hitCount"))
                object.hitCount = message.hitCount;
            return object;
        };

        /**
         * Converts this Zrtve to JSON.
         * @function toJSON
         * @memberof inz_zrtve.Zrtve
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Zrtve.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Zrtve;
    })();

    inz_zrtve.LoadAvailableZrtveFilters = (function() {

        /**
         * Properties of a LoadAvailableZrtveFilters.
         * @memberof inz_zrtve
         * @interface ILoadAvailableZrtveFilters
         */

        /**
         * Constructs a new LoadAvailableZrtveFilters.
         * @memberof inz_zrtve
         * @classdesc Represents a LoadAvailableZrtveFilters.
         * @implements ILoadAvailableZrtveFilters
         * @constructor
         * @param {inz_zrtve.ILoadAvailableZrtveFilters=} [properties] Properties to set
         */
        function LoadAvailableZrtveFilters(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LoadAvailableZrtveFilters instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.LoadAvailableZrtveFilters
         * @static
         * @param {inz_zrtve.ILoadAvailableZrtveFilters=} [properties] Properties to set
         * @returns {inz_zrtve.LoadAvailableZrtveFilters} LoadAvailableZrtveFilters instance
         */
        LoadAvailableZrtveFilters.create = function create(properties) {
            return new LoadAvailableZrtveFilters(properties);
        };

        /**
         * Encodes the specified LoadAvailableZrtveFilters message. Does not implicitly {@link inz_zrtve.LoadAvailableZrtveFilters.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.LoadAvailableZrtveFilters
         * @static
         * @param {inz_zrtve.ILoadAvailableZrtveFilters} message LoadAvailableZrtveFilters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAvailableZrtveFilters.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LoadAvailableZrtveFilters message, length delimited. Does not implicitly {@link inz_zrtve.LoadAvailableZrtveFilters.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.LoadAvailableZrtveFilters
         * @static
         * @param {inz_zrtve.ILoadAvailableZrtveFilters} message LoadAvailableZrtveFilters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAvailableZrtveFilters.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadAvailableZrtveFilters message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.LoadAvailableZrtveFilters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.LoadAvailableZrtveFilters} LoadAvailableZrtveFilters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAvailableZrtveFilters.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.LoadAvailableZrtveFilters();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadAvailableZrtveFilters message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.LoadAvailableZrtveFilters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.LoadAvailableZrtveFilters} LoadAvailableZrtveFilters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAvailableZrtveFilters.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadAvailableZrtveFilters message.
         * @function verify
         * @memberof inz_zrtve.LoadAvailableZrtveFilters
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadAvailableZrtveFilters.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LoadAvailableZrtveFilters message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.LoadAvailableZrtveFilters
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.LoadAvailableZrtveFilters} LoadAvailableZrtveFilters
         */
        LoadAvailableZrtveFilters.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.LoadAvailableZrtveFilters)
                return object;
            return new $root.inz_zrtve.LoadAvailableZrtveFilters();
        };

        /**
         * Creates a plain object from a LoadAvailableZrtveFilters message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.LoadAvailableZrtveFilters
         * @static
         * @param {inz_zrtve.LoadAvailableZrtveFilters} message LoadAvailableZrtveFilters
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadAvailableZrtveFilters.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LoadAvailableZrtveFilters to JSON.
         * @function toJSON
         * @memberof inz_zrtve.LoadAvailableZrtveFilters
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadAvailableZrtveFilters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadAvailableZrtveFilters;
    })();

    inz_zrtve.AvailableZrtveFilters = (function() {

        /**
         * Properties of an AvailableZrtveFilters.
         * @memberof inz_zrtve
         * @interface IAvailableZrtveFilters
         * @property {Array.<inz_zrtve.IZrtveFilter>|null} [filters] AvailableZrtveFilters filters
         */

        /**
         * Constructs a new AvailableZrtveFilters.
         * @memberof inz_zrtve
         * @classdesc Represents an AvailableZrtveFilters.
         * @implements IAvailableZrtveFilters
         * @constructor
         * @param {inz_zrtve.IAvailableZrtveFilters=} [properties] Properties to set
         */
        function AvailableZrtveFilters(properties) {
            this.filters = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AvailableZrtveFilters filters.
         * @member {Array.<inz_zrtve.IZrtveFilter>} filters
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @instance
         */
        AvailableZrtveFilters.prototype.filters = $util.emptyArray;

        /**
         * Creates a new AvailableZrtveFilters instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @static
         * @param {inz_zrtve.IAvailableZrtveFilters=} [properties] Properties to set
         * @returns {inz_zrtve.AvailableZrtveFilters} AvailableZrtveFilters instance
         */
        AvailableZrtveFilters.create = function create(properties) {
            return new AvailableZrtveFilters(properties);
        };

        /**
         * Encodes the specified AvailableZrtveFilters message. Does not implicitly {@link inz_zrtve.AvailableZrtveFilters.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @static
         * @param {inz_zrtve.IAvailableZrtveFilters} message AvailableZrtveFilters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableZrtveFilters.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filters != null && message.filters.length)
                for (let i = 0; i < message.filters.length; ++i)
                    $root.inz_zrtve.ZrtveFilter.encode(message.filters[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AvailableZrtveFilters message, length delimited. Does not implicitly {@link inz_zrtve.AvailableZrtveFilters.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @static
         * @param {inz_zrtve.IAvailableZrtveFilters} message AvailableZrtveFilters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableZrtveFilters.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AvailableZrtveFilters message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.AvailableZrtveFilters} AvailableZrtveFilters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableZrtveFilters.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.AvailableZrtveFilters();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.filters && message.filters.length))
                        message.filters = [];
                    message.filters.push($root.inz_zrtve.ZrtveFilter.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AvailableZrtveFilters message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.AvailableZrtveFilters} AvailableZrtveFilters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableZrtveFilters.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AvailableZrtveFilters message.
         * @function verify
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AvailableZrtveFilters.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.filters != null && message.hasOwnProperty("filters")) {
                if (!Array.isArray(message.filters))
                    return "filters: array expected";
                for (let i = 0; i < message.filters.length; ++i) {
                    let error = $root.inz_zrtve.ZrtveFilter.verify(message.filters[i]);
                    if (error)
                        return "filters." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AvailableZrtveFilters message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.AvailableZrtveFilters} AvailableZrtveFilters
         */
        AvailableZrtveFilters.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.AvailableZrtveFilters)
                return object;
            let message = new $root.inz_zrtve.AvailableZrtveFilters();
            if (object.filters) {
                if (!Array.isArray(object.filters))
                    throw TypeError(".inz_zrtve.AvailableZrtveFilters.filters: array expected");
                message.filters = [];
                for (let i = 0; i < object.filters.length; ++i) {
                    if (typeof object.filters[i] !== "object")
                        throw TypeError(".inz_zrtve.AvailableZrtveFilters.filters: object expected");
                    message.filters[i] = $root.inz_zrtve.ZrtveFilter.fromObject(object.filters[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AvailableZrtveFilters message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @static
         * @param {inz_zrtve.AvailableZrtveFilters} message AvailableZrtveFilters
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AvailableZrtveFilters.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.filters = [];
            if (message.filters && message.filters.length) {
                object.filters = [];
                for (let j = 0; j < message.filters.length; ++j)
                    object.filters[j] = $root.inz_zrtve.ZrtveFilter.toObject(message.filters[j], options);
            }
            return object;
        };

        /**
         * Converts this AvailableZrtveFilters to JSON.
         * @function toJSON
         * @memberof inz_zrtve.AvailableZrtveFilters
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AvailableZrtveFilters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AvailableZrtveFilters;
    })();

    inz_zrtve.ExportZrtveResults = (function() {

        /**
         * Properties of an ExportZrtveResults.
         * @memberof inz_zrtve
         * @interface IExportZrtveResults
         * @property {inz_zrtve.IZrtveFilters|null} [filters] ExportZrtveResults filters
         */

        /**
         * Constructs a new ExportZrtveResults.
         * @memberof inz_zrtve
         * @classdesc Represents an ExportZrtveResults.
         * @implements IExportZrtveResults
         * @constructor
         * @param {inz_zrtve.IExportZrtveResults=} [properties] Properties to set
         */
        function ExportZrtveResults(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportZrtveResults filters.
         * @member {inz_zrtve.IZrtveFilters|null|undefined} filters
         * @memberof inz_zrtve.ExportZrtveResults
         * @instance
         */
        ExportZrtveResults.prototype.filters = null;

        /**
         * Creates a new ExportZrtveResults instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.ExportZrtveResults
         * @static
         * @param {inz_zrtve.IExportZrtveResults=} [properties] Properties to set
         * @returns {inz_zrtve.ExportZrtveResults} ExportZrtveResults instance
         */
        ExportZrtveResults.create = function create(properties) {
            return new ExportZrtveResults(properties);
        };

        /**
         * Encodes the specified ExportZrtveResults message. Does not implicitly {@link inz_zrtve.ExportZrtveResults.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.ExportZrtveResults
         * @static
         * @param {inz_zrtve.IExportZrtveResults} message ExportZrtveResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportZrtveResults.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filters != null && Object.hasOwnProperty.call(message, "filters"))
                $root.inz_zrtve.ZrtveFilters.encode(message.filters, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExportZrtveResults message, length delimited. Does not implicitly {@link inz_zrtve.ExportZrtveResults.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.ExportZrtveResults
         * @static
         * @param {inz_zrtve.IExportZrtveResults} message ExportZrtveResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportZrtveResults.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExportZrtveResults message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.ExportZrtveResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.ExportZrtveResults} ExportZrtveResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportZrtveResults.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.ExportZrtveResults();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.filters = $root.inz_zrtve.ZrtveFilters.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExportZrtveResults message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.ExportZrtveResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.ExportZrtveResults} ExportZrtveResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportZrtveResults.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExportZrtveResults message.
         * @function verify
         * @memberof inz_zrtve.ExportZrtveResults
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportZrtveResults.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.filters != null && message.hasOwnProperty("filters")) {
                let error = $root.inz_zrtve.ZrtveFilters.verify(message.filters);
                if (error)
                    return "filters." + error;
            }
            return null;
        };

        /**
         * Creates an ExportZrtveResults message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.ExportZrtveResults
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.ExportZrtveResults} ExportZrtveResults
         */
        ExportZrtveResults.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.ExportZrtveResults)
                return object;
            let message = new $root.inz_zrtve.ExportZrtveResults();
            if (object.filters != null) {
                if (typeof object.filters !== "object")
                    throw TypeError(".inz_zrtve.ExportZrtveResults.filters: object expected");
                message.filters = $root.inz_zrtve.ZrtveFilters.fromObject(object.filters);
            }
            return message;
        };

        /**
         * Creates a plain object from an ExportZrtveResults message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.ExportZrtveResults
         * @static
         * @param {inz_zrtve.ExportZrtveResults} message ExportZrtveResults
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportZrtveResults.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.filters = null;
            if (message.filters != null && message.hasOwnProperty("filters"))
                object.filters = $root.inz_zrtve.ZrtveFilters.toObject(message.filters, options);
            return object;
        };

        /**
         * Converts this ExportZrtveResults to JSON.
         * @function toJSON
         * @memberof inz_zrtve.ExportZrtveResults
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportZrtveResults.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExportZrtveResults;
    })();

    inz_zrtve.ExportZrtev = (function() {

        /**
         * Properties of an ExportZrtev.
         * @memberof inz_zrtve
         * @interface IExportZrtev
         * @property {string|null} [id] ExportZrtev id
         */

        /**
         * Constructs a new ExportZrtev.
         * @memberof inz_zrtve
         * @classdesc Represents an ExportZrtev.
         * @implements IExportZrtev
         * @constructor
         * @param {inz_zrtve.IExportZrtev=} [properties] Properties to set
         */
        function ExportZrtev(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportZrtev id.
         * @member {string} id
         * @memberof inz_zrtve.ExportZrtev
         * @instance
         */
        ExportZrtev.prototype.id = "";

        /**
         * Creates a new ExportZrtev instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.ExportZrtev
         * @static
         * @param {inz_zrtve.IExportZrtev=} [properties] Properties to set
         * @returns {inz_zrtve.ExportZrtev} ExportZrtev instance
         */
        ExportZrtev.create = function create(properties) {
            return new ExportZrtev(properties);
        };

        /**
         * Encodes the specified ExportZrtev message. Does not implicitly {@link inz_zrtve.ExportZrtev.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.ExportZrtev
         * @static
         * @param {inz_zrtve.IExportZrtev} message ExportZrtev message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportZrtev.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified ExportZrtev message, length delimited. Does not implicitly {@link inz_zrtve.ExportZrtev.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.ExportZrtev
         * @static
         * @param {inz_zrtve.IExportZrtev} message ExportZrtev message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportZrtev.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExportZrtev message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.ExportZrtev
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.ExportZrtev} ExportZrtev
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportZrtev.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.ExportZrtev();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExportZrtev message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.ExportZrtev
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.ExportZrtev} ExportZrtev
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportZrtev.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExportZrtev message.
         * @function verify
         * @memberof inz_zrtve.ExportZrtev
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportZrtev.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates an ExportZrtev message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.ExportZrtev
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.ExportZrtev} ExportZrtev
         */
        ExportZrtev.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.ExportZrtev)
                return object;
            let message = new $root.inz_zrtve.ExportZrtev();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from an ExportZrtev message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.ExportZrtev
         * @static
         * @param {inz_zrtve.ExportZrtev} message ExportZrtev
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportZrtev.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this ExportZrtev to JSON.
         * @function toJSON
         * @memberof inz_zrtve.ExportZrtev
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportZrtev.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExportZrtev;
    })();

    inz_zrtve.ExportSlot = (function() {

        /**
         * Properties of an ExportSlot.
         * @memberof inz_zrtve
         * @interface IExportSlot
         * @property {string|null} [url] ExportSlot url
         * @property {string|null} [name] ExportSlot name
         */

        /**
         * Constructs a new ExportSlot.
         * @memberof inz_zrtve
         * @classdesc Represents an ExportSlot.
         * @implements IExportSlot
         * @constructor
         * @param {inz_zrtve.IExportSlot=} [properties] Properties to set
         */
        function ExportSlot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportSlot url.
         * @member {string} url
         * @memberof inz_zrtve.ExportSlot
         * @instance
         */
        ExportSlot.prototype.url = "";

        /**
         * ExportSlot name.
         * @member {string} name
         * @memberof inz_zrtve.ExportSlot
         * @instance
         */
        ExportSlot.prototype.name = "";

        /**
         * Creates a new ExportSlot instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.ExportSlot
         * @static
         * @param {inz_zrtve.IExportSlot=} [properties] Properties to set
         * @returns {inz_zrtve.ExportSlot} ExportSlot instance
         */
        ExportSlot.create = function create(properties) {
            return new ExportSlot(properties);
        };

        /**
         * Encodes the specified ExportSlot message. Does not implicitly {@link inz_zrtve.ExportSlot.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.ExportSlot
         * @static
         * @param {inz_zrtve.IExportSlot} message ExportSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified ExportSlot message, length delimited. Does not implicitly {@link inz_zrtve.ExportSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.ExportSlot
         * @static
         * @param {inz_zrtve.IExportSlot} message ExportSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExportSlot message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.ExportSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.ExportSlot} ExportSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.ExportSlot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExportSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.ExportSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.ExportSlot} ExportSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExportSlot message.
         * @function verify
         * @memberof inz_zrtve.ExportSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates an ExportSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.ExportSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.ExportSlot} ExportSlot
         */
        ExportSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.ExportSlot)
                return object;
            let message = new $root.inz_zrtve.ExportSlot();
            if (object.url != null)
                message.url = String(object.url);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an ExportSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.ExportSlot
         * @static
         * @param {inz_zrtve.ExportSlot} message ExportSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                object.name = "";
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this ExportSlot to JSON.
         * @function toJSON
         * @memberof inz_zrtve.ExportSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExportSlot;
    })();

    inz_zrtve.ZrtveFilters = (function() {

        /**
         * Properties of a ZrtveFilters.
         * @memberof inz_zrtve
         * @interface IZrtveFilters
         * @property {string|null} [quickSearch] ZrtveFilters quickSearch
         * @property {Array.<inz_zrtve.IZrtveFilter>|null} [filters] ZrtveFilters filters
         */

        /**
         * Constructs a new ZrtveFilters.
         * @memberof inz_zrtve
         * @classdesc Represents a ZrtveFilters.
         * @implements IZrtveFilters
         * @constructor
         * @param {inz_zrtve.IZrtveFilters=} [properties] Properties to set
         */
        function ZrtveFilters(properties) {
            this.filters = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZrtveFilters quickSearch.
         * @member {string} quickSearch
         * @memberof inz_zrtve.ZrtveFilters
         * @instance
         */
        ZrtveFilters.prototype.quickSearch = "";

        /**
         * ZrtveFilters filters.
         * @member {Array.<inz_zrtve.IZrtveFilter>} filters
         * @memberof inz_zrtve.ZrtveFilters
         * @instance
         */
        ZrtveFilters.prototype.filters = $util.emptyArray;

        /**
         * Creates a new ZrtveFilters instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.ZrtveFilters
         * @static
         * @param {inz_zrtve.IZrtveFilters=} [properties] Properties to set
         * @returns {inz_zrtve.ZrtveFilters} ZrtveFilters instance
         */
        ZrtveFilters.create = function create(properties) {
            return new ZrtveFilters(properties);
        };

        /**
         * Encodes the specified ZrtveFilters message. Does not implicitly {@link inz_zrtve.ZrtveFilters.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.ZrtveFilters
         * @static
         * @param {inz_zrtve.IZrtveFilters} message ZrtveFilters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZrtveFilters.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.quickSearch != null && Object.hasOwnProperty.call(message, "quickSearch"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.quickSearch);
            if (message.filters != null && message.filters.length)
                for (let i = 0; i < message.filters.length; ++i)
                    $root.inz_zrtve.ZrtveFilter.encode(message.filters[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ZrtveFilters message, length delimited. Does not implicitly {@link inz_zrtve.ZrtveFilters.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.ZrtveFilters
         * @static
         * @param {inz_zrtve.IZrtveFilters} message ZrtveFilters message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZrtveFilters.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZrtveFilters message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.ZrtveFilters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.ZrtveFilters} ZrtveFilters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZrtveFilters.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.ZrtveFilters();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.quickSearch = reader.string();
                    break;
                case 2:
                    if (!(message.filters && message.filters.length))
                        message.filters = [];
                    message.filters.push($root.inz_zrtve.ZrtveFilter.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZrtveFilters message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.ZrtveFilters
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.ZrtveFilters} ZrtveFilters
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZrtveFilters.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZrtveFilters message.
         * @function verify
         * @memberof inz_zrtve.ZrtveFilters
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZrtveFilters.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                if (!$util.isString(message.quickSearch))
                    return "quickSearch: string expected";
            if (message.filters != null && message.hasOwnProperty("filters")) {
                if (!Array.isArray(message.filters))
                    return "filters: array expected";
                for (let i = 0; i < message.filters.length; ++i) {
                    let error = $root.inz_zrtve.ZrtveFilter.verify(message.filters[i]);
                    if (error)
                        return "filters." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ZrtveFilters message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.ZrtveFilters
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.ZrtveFilters} ZrtveFilters
         */
        ZrtveFilters.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.ZrtveFilters)
                return object;
            let message = new $root.inz_zrtve.ZrtveFilters();
            if (object.quickSearch != null)
                message.quickSearch = String(object.quickSearch);
            if (object.filters) {
                if (!Array.isArray(object.filters))
                    throw TypeError(".inz_zrtve.ZrtveFilters.filters: array expected");
                message.filters = [];
                for (let i = 0; i < object.filters.length; ++i) {
                    if (typeof object.filters[i] !== "object")
                        throw TypeError(".inz_zrtve.ZrtveFilters.filters: object expected");
                    message.filters[i] = $root.inz_zrtve.ZrtveFilter.fromObject(object.filters[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ZrtveFilters message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.ZrtveFilters
         * @static
         * @param {inz_zrtve.ZrtveFilters} message ZrtveFilters
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZrtveFilters.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.filters = [];
            if (options.defaults)
                object.quickSearch = "";
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                object.quickSearch = message.quickSearch;
            if (message.filters && message.filters.length) {
                object.filters = [];
                for (let j = 0; j < message.filters.length; ++j)
                    object.filters[j] = $root.inz_zrtve.ZrtveFilter.toObject(message.filters[j], options);
            }
            return object;
        };

        /**
         * Converts this ZrtveFilters to JSON.
         * @function toJSON
         * @memberof inz_zrtve.ZrtveFilters
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZrtveFilters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ZrtveFilters;
    })();

    inz_zrtve.ZrtveFilter = (function() {

        /**
         * Properties of a ZrtveFilter.
         * @memberof inz_zrtve
         * @interface IZrtveFilter
         * @property {inz_types.IInzInput|null} [input] ZrtveFilter input
         * @property {inz_zrtve.ZrtveFilterRelation|null} [nextRelation] ZrtveFilter nextRelation
         */

        /**
         * Constructs a new ZrtveFilter.
         * @memberof inz_zrtve
         * @classdesc Represents a ZrtveFilter.
         * @implements IZrtveFilter
         * @constructor
         * @param {inz_zrtve.IZrtveFilter=} [properties] Properties to set
         */
        function ZrtveFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZrtveFilter input.
         * @member {inz_types.IInzInput|null|undefined} input
         * @memberof inz_zrtve.ZrtveFilter
         * @instance
         */
        ZrtveFilter.prototype.input = null;

        /**
         * ZrtveFilter nextRelation.
         * @member {inz_zrtve.ZrtveFilterRelation} nextRelation
         * @memberof inz_zrtve.ZrtveFilter
         * @instance
         */
        ZrtveFilter.prototype.nextRelation = 0;

        /**
         * Creates a new ZrtveFilter instance using the specified properties.
         * @function create
         * @memberof inz_zrtve.ZrtveFilter
         * @static
         * @param {inz_zrtve.IZrtveFilter=} [properties] Properties to set
         * @returns {inz_zrtve.ZrtveFilter} ZrtveFilter instance
         */
        ZrtveFilter.create = function create(properties) {
            return new ZrtveFilter(properties);
        };

        /**
         * Encodes the specified ZrtveFilter message. Does not implicitly {@link inz_zrtve.ZrtveFilter.verify|verify} messages.
         * @function encode
         * @memberof inz_zrtve.ZrtveFilter
         * @static
         * @param {inz_zrtve.IZrtveFilter} message ZrtveFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZrtveFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                $root.inz_types.InzInput.encode(message.input, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.nextRelation != null && Object.hasOwnProperty.call(message, "nextRelation"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nextRelation);
            return writer;
        };

        /**
         * Encodes the specified ZrtveFilter message, length delimited. Does not implicitly {@link inz_zrtve.ZrtveFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof inz_zrtve.ZrtveFilter
         * @static
         * @param {inz_zrtve.IZrtveFilter} message ZrtveFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZrtveFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZrtveFilter message from the specified reader or buffer.
         * @function decode
         * @memberof inz_zrtve.ZrtveFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {inz_zrtve.ZrtveFilter} ZrtveFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZrtveFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.inz_zrtve.ZrtveFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.input = $root.inz_types.InzInput.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.nextRelation = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZrtveFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof inz_zrtve.ZrtveFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {inz_zrtve.ZrtveFilter} ZrtveFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZrtveFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZrtveFilter message.
         * @function verify
         * @memberof inz_zrtve.ZrtveFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZrtveFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.input != null && message.hasOwnProperty("input")) {
                let error = $root.inz_types.InzInput.verify(message.input);
                if (error)
                    return "input." + error;
            }
            if (message.nextRelation != null && message.hasOwnProperty("nextRelation"))
                switch (message.nextRelation) {
                default:
                    return "nextRelation: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a ZrtveFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof inz_zrtve.ZrtveFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {inz_zrtve.ZrtveFilter} ZrtveFilter
         */
        ZrtveFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.inz_zrtve.ZrtveFilter)
                return object;
            let message = new $root.inz_zrtve.ZrtveFilter();
            if (object.input != null) {
                if (typeof object.input !== "object")
                    throw TypeError(".inz_zrtve.ZrtveFilter.input: object expected");
                message.input = $root.inz_types.InzInput.fromObject(object.input);
            }
            switch (object.nextRelation) {
            case "relation_and":
            case 0:
                message.nextRelation = 0;
                break;
            case "relation_or":
            case 1:
                message.nextRelation = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ZrtveFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof inz_zrtve.ZrtveFilter
         * @static
         * @param {inz_zrtve.ZrtveFilter} message ZrtveFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZrtveFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.input = null;
                object.nextRelation = options.enums === String ? "relation_and" : 0;
            }
            if (message.input != null && message.hasOwnProperty("input"))
                object.input = $root.inz_types.InzInput.toObject(message.input, options);
            if (message.nextRelation != null && message.hasOwnProperty("nextRelation"))
                object.nextRelation = options.enums === String ? $root.inz_zrtve.ZrtveFilterRelation[message.nextRelation] : message.nextRelation;
            return object;
        };

        /**
         * Converts this ZrtveFilter to JSON.
         * @function toJSON
         * @memberof inz_zrtve.ZrtveFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZrtveFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ZrtveFilter;
    })();

    /**
     * ZrtveFilterRelation enum.
     * @name inz_zrtve.ZrtveFilterRelation
     * @enum {number}
     * @property {number} relation_and=0 relation_and value
     * @property {number} relation_or=1 relation_or value
     */
    inz_zrtve.ZrtveFilterRelation = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "relation_and"] = 0;
        values[valuesById[1] = "relation_or"] = 1;
        return values;
    })();

    return inz_zrtve;
})();

export { $root as default };
