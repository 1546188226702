import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {InzTextInput} from "../../components/text_input";
import {InzInputField} from "../../components/input";
import {MdDelete} from "react-icons/md";
import {IconButton} from "../../components/buttons";
import useWave from "use-wave";
import {useContext, useState} from "react";
import {ZrtveStoreContext} from "../zrtve";
import {StyledProps, Theme} from "../../theme";
import {ZrtveStore} from "../../stores/zrtve_store";
import {inz_zrtve} from "../../proto/compiled";
import {CommonInput} from "../../components/common_input";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterIconButton = styled(IconButton)`
  margin-left: -8px;
  margin-bottom: -1px;
`;

const InAliButton = styled.span<{ active: boolean }>`
  color: ${({theme, active}: StyledProps) => active ? theme.primary : theme.content};
  padding: 0 4px 0 4px;
  margin: 5px 0 5px;
  display: flex;
  height: 20px;
  align-items: center;
  text-shadow: ${({active}: StyledProps) => active ? `-0.06ex 0 currentColor, 0.06ex 0 currentColor` : 'none'};
  cursor: pointer;
  border-radius: 5px;
  transition: all 300ms;

  :hover {
    background-color: ${({theme}: StyledProps) => theme.content5};
  }
`;

const InAliLine = styled.div`
  width: 1px;
  height: 30px;
  margin: 0 4px 0 4px;
  background-color: ${({theme}: StyledProps) => theme.primary};
`;

const InAliContainerOuter = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  overflow: visible;
`;

const InAliContainer = styled.div`
  position: absolute;
  right: 0;
  overflow: visible;
  display: flex;
`;

export const ZrtveFilters = observer(() => {
    const store = useContext(ZrtveStoreContext) as ZrtveStore;
    return <Container>
        {store.filters.filters.map((f, i) =>
            <ZrtveFilter key={i} index={i} filter={f} last={store.filters.filters.length - 1 === i}/>
        )}
        <ZrtveAddFilter/>
    </Container>;
});

const ZrtveFilter = observer(({
                                  filter,
                                  last,
                                  index
                              }: { filter: inz_zrtve.IZrtveFilter, last: boolean, index: number }) => {
    const wave = useWave();
    const store = useContext(ZrtveStoreContext) as ZrtveStore;

    return <>
        <CommonInput
            label={<><FilterIconButton onClick={() => store.removeFilter(index)}
                                       ref={wave}><MdDelete/></FilterIconButton>{filter.input?.label}</>}
            filter={true}
            input={filter.input!}
            onChange={() => store.loadDebounced()}
        />
        {!last && <InAliContainerOuter>
            <InAliContainer>
                <InAliButton ref={wave}
                             active={filter.nextRelation === inz_zrtve.ZrtveFilterRelation.relation_or}
                             onClick={() => {
                                 filter.nextRelation = inz_zrtve.ZrtveFilterRelation.relation_or;
                                 store.loadDebounced();
                             }}>
                    ALI
                </InAliButton>
                <InAliLine/>
                <InAliButton ref={wave}
                             active={filter.nextRelation === inz_zrtve.ZrtveFilterRelation.relation_and}
                             onClick={() => {
                                 filter.nextRelation = inz_zrtve.ZrtveFilterRelation.relation_and;
                                 store.loadDebounced();
                             }}>
                    IN
                </InAliButton>
            </InAliContainer>
        </InAliContainerOuter>}
    </>;
});

const ZrtveAddFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  border: solid 1px ${({theme}: StyledProps) => theme.contentShade};
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 16px;
  margin-bottom: 32px;
`;

const ZrtveAddFilterItemContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 8px;
`;

const ZrtveAddFilterItem = styled.span`
  padding: 4px 8px 4px 8px;
  background-color: white;
  border-radius: 3px;
  border: solid 1px ${({theme}: StyledProps) => theme.shade};
  margin: 4px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  :hover {
    border: solid 1px ${({theme}: StyledProps) => theme.primary};
  }
`;

const ZrtveAddFilter = observer(() => {
    const store = useContext(ZrtveStoreContext) as ZrtveStore;
    const theme = useTheme() as Theme;
    const [filter, setFilter] = useState('');
    const wave = useWave();
    const availableFilters = store.availableFilters
        .filter((f) => f.input?.label?.toLowerCase().includes(filter.toLowerCase()));
    return <ZrtveAddFilterContainer>
        <theme.textTheme.headline6.span style={{paddingBottom: '8px', color: theme.primary}}>
            dodaj nov filter
        </theme.textTheme.headline6.span>
        <InzInputField style={{width: '100%', margin: '0 4px 0 4px'}} input={
            <InzTextInput onKeyPress={(e) => {
                if (e.key === 'Enter' && availableFilters) {
                    setFilter('');
                    store.addFilter(availableFilters[0]);
                }
            }} placeholder={'Iskanje'} value={filter}
                          onChange={(e) => setFilter(e.target.value)}/>
        }/>
        <ZrtveAddFilterItemContainer>
            {availableFilters.map((f, i) => <ZrtveAddFilterItem key={i} ref={wave} onClick={() => {
                store.addFilter(f);
            }}>{f.input?.label}</ZrtveAddFilterItem>)}
        </ZrtveAddFilterItemContainer>
    </ZrtveAddFilterContainer>
});