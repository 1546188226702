import styled from "styled-components";
import {StyledProps, Theme} from "../theme";
import useWave from "use-wave";

const InzButtonElement = styled.button<{ width?: string, height?: string, disabled?: boolean, outline?: boolean }>`
  outline: none;
  border: ${({theme, outline}: StyledProps) => outline ? ('solid 1px ' + theme.primary) : 'none'};
  background-color: ${({theme, outline}: StyledProps) => outline ? 'transparent' : theme.primary};
  padding: 0 30px 0 30px;
  height: ${({height}) => height || '62px'};
  width: ${({width}) => width || '100%'};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
  user-select: none;
  ${({theme}: StyledProps) => theme.textTheme.button.css};
  color: ${({theme, outline}: StyledProps) => outline ? theme.primary : 'white'};
  transition: all 300ms;
  border-radius: 5px;

  :hover {
    background-color: ${({theme, outline}: StyledProps) => outline ? 'transparent' : theme.primaryShade};
  }
`;

export interface InzButtonProps {
    width?: string;
    height?: string;
    disabled?: boolean;
    outline?: boolean

    [x: string]: any;
}

export const InzButton = (props: InzButtonProps) => {
    const wave = useWave();
    return <InzButtonElement
        ref={wave}
        {...props}
    />;
};

export const IconButton = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const InzFAB = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  width: 60px;
  height: 60px;
  background-color: ${({theme}: StyledProps) => theme.primary};
  border-radius: 100%;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  cursor: pointer;
  transition: all 300ms;

  :hover {
    background-color: ${({theme}: StyledProps) => theme.primaryShade};
  }
`;