import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {ZrtveFilters} from "../zrtve/filters";
import {InzCollapsible} from "../../components/collapsible";
import {useContext, useState} from "react";
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/md";
import useWave from "use-wave";
import {StyledProps} from "../../theme";
import {ZrtveStoreContext} from "../zrtve";
import {ZrtveStore} from "../../stores/zrtve_store";

export const GuestFilters = observer(() => {
    const store = useContext(ZrtveStoreContext) as ZrtveStore;
    const [expanded, setExpanded] = useState(store.hashState.filtersExpanded);
    const wave = useWave();
    return <Container>
        <Header
            onClick={() => {
                store.hashState.filtersExpanded = !expanded;
                store.updateHash();
                setExpanded(!expanded);
            }}
            ref={wave}>
            <HeaderLabel>NAPREDNI FILTRI</HeaderLabel>
            <HeaderFiltersContainer>

            </HeaderFiltersContainer>
            <HeaderExpandedIndicator>
                {!expanded && <MdArrowDropDown/>}
                {expanded && <MdArrowDropUp/>}
            </HeaderExpandedIndicator>
        </Header>
        <InzCollapsible expanded={expanded}>
            <div style={{padding: '8px 32px 8px 32px'}}><ZrtveFilters/></div>
        </InzCollapsible>
    </Container>;
});

const Container = styled.div`
  background-color: #F9F9F9;
`;

const HeaderFiltersContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 0;
  overflow: auto;

  :first-child {
    margin-left: auto;
  }

  :last-child {
    margin-right: auto;
  }

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;


const Header = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  background-color: #F5F5F5;
  border: solid 1px rgb(221, 221, 221);
`;

const HeaderFilter = styled.div`
  ${({theme}: StyledProps) => theme.textTheme.headline6.with({weight: 'normal'}).css};

  :first-child {
    ${({theme}: StyledProps) => theme.textTheme.headline6.css};
  }
`;

const HeaderLabel = styled.div`
  ${({theme}: StyledProps) => theme.textTheme.headline6.css};
  display: flex;
  align-items: center;
  margin-right: auto;
  padding: 0 16px 0 16px;
  height: 100%;
  border-right: solid 1px rgb(200, 200, 200);
`;

const HeaderExpandedIndicator = styled.div`
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-left: auto;
`;
