import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";
import {OverlayContainer} from "./overlays";
import {InzButton} from "./buttons";
import {useEffect, useMemo, useState} from "react";
import useWave from "use-wave";

export const DialogFooter = styled.div`
  width: 100%;
  box-shadow: 0 0 1.0em rgba(0, 0, 0, .15);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogContainer = styled.div<{ width?: string, height?: string }>`
  position: relative;
  background-color: ${({theme}: StyledProps) => theme.neutral};
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  max-width: ${({width}) => width};
  max-height: ${({height}) => height};
  width: ${({width}) => width ? 'calc(100% - 32px)' : ''};
  height: ${({height}) => height ? 'calc(100% - 32px)' : ''};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentUnitFrame = styled.div<{ padding?: string }>`
  position: relative;
  padding: ${({padding}) => padding};
  border: solid 1px ${({theme}: StyledProps) => theme.content};
`;

export const YesNoDialog = ({children, result}: { children?: any, result: (result: boolean) => any }) => {
    const [first, setFirst] = useState(true);
    useEffect(() => setFirst(false), []);
    const wave = useWave();
    const theme = useTheme() as Theme;

    return <OverlayContainer hidden={first}>
        <DialogContainer style={{padding: '0', width: '500px'}}>
            <theme.textTheme.headline5.span style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                minHeight: '100px',
                padding: '16px 50px 16px 50px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                {children}
            </theme.textTheme.headline5.span>
            <DialogFooter style={{height: '50px'}}>
                <InzButton
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => result(true), 100);
                    }}
                    height={'30px'}
                    width={'180px'}
                    style={{padding: 0, justifyContent: 'center', marginRight: '16px'}}
                >DA</InzButton>
                <InzButton
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => result(false), 100);
                    }}
                    height={'30px'}
                    width={'180px'}
                    style={{padding: 0, justifyContent: 'center'}}
                >NE</InzButton>
            </DialogFooter>
        </DialogContainer>
    </OverlayContainer>;
};